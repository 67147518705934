.error-500 {
    background-color: $black;
    min-height: 100vh;
    min-width: 100vw;

    &__container {
        position: relative;
        padding: 4rem;
        min-height: 100vh;
        display: flex;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        max-width: 42rem;
        background: none;
    }

    &__title {
        color: $svns-white;
        font-size: 8.4rem;
        line-height: 1;
        text-transform: uppercase;
    }

    &__summary {
        margin-top: 1.8rem;
        color: $svns-white;
        font-size: 1.8rem;
        line-height: 1.5;
        text-align: left;
    }

    @media (max-width: $break-tablet) {
        &__content {
            max-width: 28rem;
        }

        &__title {
            color: $svns-white;
            font-size: 4.8rem;
        }

        &__summary {
            font-size: 1.6rem;
        }
    }
}
