.article {
    $this: &;
    color: $svns-white;

    .articleWidget {
        min-width: 100%;
    }

    .social-share__label {
        color: unset;
    }

    &__wrapper {
        width: calc(100% - 1.6rem);
        position: relative;
    }

    &__header-container {
        display: flex;
        flex-flow: nowrap column;
        padding: 1.6rem;
        @include eventColors(background);

        // stylelint-disable-next-line selector-max-specificity

        &.cpt-svns,
        &.dxb-svns,
        &.hkg-svns,
        &.lax-svns,
        &.mad-svns,
        &.sgp-svns,
        &.per-svns,
        &.van-svns {
            color: $svns-black;
            fill: $svns-black;
            // stylelint-disable-next-line selector-max-specificity

            & .icon,
            & p,
            & a {
                fill: $svns-black;
                color: $svns-black;
            }
        }

        #{$this}__author {
            border-color: $svns-black;
        }

        #{$this}__headshot {
            border-color: $svns-black;
            background: $svns-black;
        }

        #{$this}__headshot-bg {
            background: $svns-white;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
    }

    &__category {
        @include info-text;
        padding: 2.4rem 0 1.4rem;
    }

    &__heading {
        @include font-weight('regular');
        font-size: 8.4rem;
        line-height: 8.4rem;
        text-transform: uppercase;
        max-width: 80%;
    }

    &__container {
        display: flex;
        margin-top: 2.4rem;
    }

    &__lead-media {
        position: relative;

        //max-height: 65rem;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        margin-bottom: -0.6rem;
    }

    &__column {
        &:first-of-type {
            width: calc(((100vw - 68rem) / 2) - 2rem);
            display: flex;
            flex-direction: column;
            margin-left: 0;
            min-width: 23rem;
        }

        &:last-of-type {
            flex: 1;
        }
    }

    &__meta {
        margin-right: 2.4rem;
    }

    &__author {
        display: flex;
        margin: 0 2.4rem 2.4rem 0;
        border-bottom: .1rem solid $svns-white;

        .author-headshot__image {
            height: 100%;
            position: absolute;
            bottom: 0;
            transform: translatex(-50%);
            left: 50%;
        }
    }

    &__bio-link {
        text-decoration: none;
    }

    &__headshot {
        position: relative;
        height: 7.6rem;
        width: 7.6rem;
        min-width: 7.6rem;
        border: .1rem solid $svns-white;
        margin: 0 1.6rem 1.6rem 0;
        overflow: hidden;
    }

    &__headshot-bg {
        stroke: none;
        fill: $svns-white;
        opacity: .15;
        height: 27.5rem;
        width: 27.5rem;
        border: 1px solid $svns-white;
        margin-top: -5rem;
        margin-left: -5rem;
    }

    &__by {
        text-transform: uppercase;
        font-size: 1.8rem;
        line-height: 1.8rem;
        margin-bottom: 2.4rem;
    }

    &__author-first-name {
        text-transform: uppercase;
        font-size: 1.8rem;
        line-height: 1.8rem;
        margin-bottom: 2.4rem;
    }

    &__author-surname {
        display: block;
        text-transform: uppercase;
        font-size: 1.8rem;
        line-height: 1.8rem;
        margin-bottom: 2.4rem;
    }

    &__date,
    &__length,
    &__share {
        display: block;
        @include info-text;
        margin-bottom: 2.4rem;
    }

    &__share {
        button {
            background: none;
            border: none;
            @include info-text;
            padding: 0;
            margin: 0;
        }
    }

    &__description {
        font-size: 2.4rem;
        line-height: 3rem;
        padding-top: 2.4rem;
        max-width: 68rem;
        margin: 0 auto;
    }

    &__body {
        display: flex;
        flex-flow: wrap column;
        justify-content: center;
        padding: 0 1.6rem;

        & * {
            margin: 1rem auto;
        }

        > p {
            @include font-dmsans-regular;
            max-width: 68rem;
            line-height: 1.5;
            color: $svns-white;
        }

        ul:not(.accordion-list) {
            @include font-dmsans-regular;
            max-width: 68rem;
            margin: 5rem auto;
            color: $svns-white;
            padding-left: 2.4rem;

            li {
                margin-bottom: 2rem;
                position: relative;
                list-style-type: none;

                &::before {
                    position: absolute;
                    content: '\2022';
                    color: $svns-white;
                    display: inline-block;
                    width: 4em;
                    top: 0.2rem;
                    left: 1rem;
                    transform: scale(200%);
                }
            }
        }
    }
}

@media (max-width: $break-wide) {
    .article {
        &__heading {
            max-width: 90%;
        }

        &__body {
            flex-direction: row;
        }
    }
}

@media (min-width: $break-tablet) {
    .article__body {
        > p,
        ul:not(.accordion-list) {
            min-width: 68rem;
        }
    }
}

@media (max-width: $break-tablet) {
    .article {
        &__header-container {
            padding: 0;
        }

        &__header,
        &__meta {
            padding: 0 1.6rem;
        }


        &__author {
            margin: 1.6rem;
        }

        &__headshot {
            height: 4rem;
            width: 4rem;
            min-width: 4rem;
            margin: 0 .6rem 1.6rem 0;
        }

        &__headshot-bg {
            height: 14.5rem;
            width: 14.5rem;
            margin-top: -3rem;
            margin-left: -3rem;
        }

        &__by,
        &__author-first-name,
        &__author-surname {
            font-size: 1.4rem;
            line-height: 1;
        }

        &__header {
            padding-top: 1.6rem;
        }

        &__wrapper {
            width: 100%;
            position: relative;
        }

        &__heading {
            font-size: 4.2rem;
            line-height: 4.2rem;
        }

        &__container {
            flex-direction: column;
        }

        &__column {
            &:first-of-type {
                width: 100%;
            }
        }

        &__meta {
            display: flex;
            justify-content: space-between;
        }

        &__date,
        &__length,
        &__share {
            display: flex;
            align-items: end;
        }

        &__description {
            padding: 0 1.6rem;
            font-size: 2rem;
            line-height: 2.4rem;
            margin-top: 1.6rem;
        }

        &__body {
            max-width: 100vw;

            ul:not(.accordion-list) {
                li {
                    &::before {
                        left: 2.4rem;
                        width: 8.3rem;
                    }
                }
            }

            p,
            ul:not(.accordion-list) li {
                font-size: 1.6rem;
                line-height: 2.4rem;
            }
        }
    }
}
