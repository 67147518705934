@use 'sass:map';

@mixin clearfix {
    // Stops element with floated children from collapsing

    &::before {
        content: '';
        display: block;
    }

    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin link-reset {
    // remove default browser styling of a link tag
    color: inherit;
    text-decoration: none;
}

@mixin button-reset {
    // remove default browser styling of a button tag
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;
}

@mixin font-regular {
    font-family: mabryMono, helvetica, arial, sans-serif;
    font-weight: 400;
}

@mixin font-bold {
    font-family: mabryMonoBold, helvetica, arial, sans-serif;
    font-weight: 700;
}

@mixin font-dmsans-regular {
    font-family: dmSans, helvetica, arial, sans-serif;
    font-weight: 400;
}

@mixin font-weight($weight) {
    $font-weights: (
        'thin': 100,
        'light': 300,
        'regular': 400,
        'strong': 500,
        'bold': 700,
        'heavy': 900
    );

    @if map.get($font-weights, $weight) {
        // stylelint-disable-next-line font-weight-notation
        font-weight: map.get($font-weights, $weight);
    } @else {
        font-weight: $weight;
    }
}

@mixin button {
    display: inline-block;
    background-color: $black;
    text-transform: uppercase;
    font-size: 1.6rem;
    line-height: 2.1rem;
    color: $svns-white;
    padding: 1.6rem 2.4rem;
    border: solid 0.2rem $black;
    height: 5.4rem;
    transition: ease 0.3s background-color, ease 0.3s color;
    text-decoration: none;
    margin: 1.6rem 0;
    white-space: nowrap;

    @include hover {
        background-color: transparent;
        color: $black;
    }

    @media screen and (max-width: $break-phablet) {
        font-size: 1.4rem;
        line-height: 1.4rem;
        padding: 1.5rem 2.4rem;
        height: 4.8rem;

        @include hover {
            background-color: $white;
        }
    }
}

@mixin retina-bg-cover($file, $type) {
    // stylelint-disable-next-line function-url-quotes
    background-image: url($file + '.' + $type);
    background-size: cover;
    background-repeat: no-repeat;

    @media (min-device-pixel-ratio: 2), (-moz-min-device-pixel-ratio: 2) {
        & {
            // stylelint-disable-next-line function-url-quotes
            background-image: url($file + '@x2.' + $type);
        }
    }
}

/*
 * Adds hover and focus styles for tablet and up
 * Only keeps focus styles for below tablet
 */

@mixin hover() {
    @media (min-width: $break-tablet) {
        &:hover {
            @content;
        }
    }
}

/*
 * Arrow for main menu
 */

@mixin arrow {
    .arrow {
        display: inline-block;
        width: 1.2rem;
        height: 1.2rem;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0.1rem;
            width: 0.2rem;
            height: 100%;
            background: $svns-primary-black;
            transform: rotate(-45deg);
        }

        &::after {
            left: auto;
            right: 0.1rem;
            transform: rotate(45deg);
        }

        &.right {
            transform: rotate(-90deg);
        }

        &.left {
            transform: rotate(90deg);
        }

        &.up {
            transform: rotate(0);
        }

        &.down {
            transform: rotate(-180);
        }
    }
}

/*
 * Applies an ellipsis to text that overflows the amount of lines given
 *
 * max-height and oveflow hidden workaround for non-webkit browsers
 */

@mixin truncate-multiline($line: 2, $line-height: 1.8rem) {
    $maxHeight: $line * $line-height;
    $buffer: 0.5rem;
    max-height: calc(#{$maxHeight} + #{$buffer});
    overflow: hidden;

    @supports (-webkit-line-clamp: $line) {
        // stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix
        display: -webkit-box;

        /*! autoprefixer: off */
        -webkit-box-orient: vertical;

        /*! autoprefixer: on */
        -webkit-line-clamp: $line;
        // stylelint-enable
    }
}

/*
 * Evenet colors for main menu
 */

@mixin eventColors($key) {
    &.cpt-svns {
        #{$key}: $cape-town;
    }

    &.dxb-svns {
        #{$key}: $dubai;
    }

    &.hkg-svns {
        #{$key}: $hong-kong;
    }

    &.lax-svns {
        #{$key}: $los-angeles;
    }

    &.mad-svns {
        #{$key}: $madrid;
    }

    &.sgp-svns {
        #{$key}: $singapore;
    }

    &.per-svns {
        #{$key}: $perth;
    }

    &.van-svns {
        #{$key}: $vancouver;
    }
}

@mixin eventColors-secondary($key) {
    &.cpt-svns {
        #{$key}: $cape-town-secondary;
    }

    &.dxb-svns {
        #{$key}: $dubai-secondary;
    }

    &.hkg-svns {
        #{$key}: $hong-kong-secondary;
    }

    &.lax-svns {
        #{$key}: $los-angeles-secondary;
    }

    &.mad-svns {
        #{$key}: $madrid-secondary;
    }

    &.sgp-svns {
        #{$key}: $singapore-secondary;
    }

    &.per-svns {
        #{$key}: $perth-secondary;
    }

    &.van-svns {
        #{$key}: $vancouver-secondary;
    }
}

/*
 * Teams colors 
 */
@mixin teamsColors($key) {
    &.arg {
        #{$key}: $argentina;
    }

    &.aus {
        #{$key}: $australia;
    }

    &.bel {
        #{$key}: $belgium;
    }

    &.bra {
        #{$key}: $brazil;
    }

    &.can {
        #{$key}: $canada;
    }

    &.chi {
        #{$key}: $chile;
    }

    &.chn {
        #{$key}: $china;
    }

    &.esp {
        #{$key}: $spain;
    }

    &.fij {
        #{$key}: $fiji;
    }

    &.fra {
        #{$key}: $france;
    }

    &.gbr {
        #{$key}: $great-britain;
    }

    &.ger {
        #{$key}: $germany;
    }

    &.hkg {
        #{$key}: $hong-kong;
    }

    &.ire {
        #{$key}: $ireland;
    }

    &.jpn {
        #{$key}: $japan;
    }

    &.ken {
        #{$key}: $kenya;
    }

    &.nzl {
        #{$key}: $new-zealand;
    }

    &.pol {
        #{$key}: $poland;
    }

    &.sam {
        #{$key}: $samoa;
    }

    &.rsa {
        #{$key}: $south-africa;
    }

    &.uru {
        #{$key}: $uruguay;
    }

    &.usa {
        #{$key}: $usa;
    }
}

/*
 * Evenet colors for main menu
 */

@mixin fixturesColors($key) {
    &.cpt-svns + .fixtures-content {
        #{$key}: $cape-town;
    }

    &.dxb-svns + .fixtures-content {
        #{$key}: $dubai;
    }

    &.hkg-svns + .fixtures-content {
        #{$key}: $hong-kong;
    }

    &.lax-svns + .fixtures-content {
        #{$key}: $los-angeles;
    }

    &.mad-svns + .fixtures-content {
        #{$key}: $madrid;
    }

    &.sgp-svns + .fixtures-content {
        #{$key}: $singapore;
    }

    &.per-svns + .fixtures-content {
        #{$key}: $perth;
    }

    &.van-svns + .fixtures-content {
        #{$key}: $vancouver;
    }
}
