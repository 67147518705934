.footer-bottom-nav {
    $this: &;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    padding: 1rem;

    &--item {
        flex: 0 0 auto;
        display: flex;
        padding: 0.5rem 0;

        &_link {
            text-transform: uppercase;
            text-decoration: none;
            position: relative;
            font-size: 1.4rem;
        }
    }
    @media (min-width: $break-tablet) {
        width: 50%;
        flex-direction: row;

        &--item {
            flex: 0 0 auto;
            display: flex;
            margin-right: 4rem;

            &:last-child {
                margin-right: 0;
            }

            &_link {
                font-size: 1.6rem;

                &::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0.1rem;
                    bottom: -0.1rem;
                    left: 50%;
                    transform: translateX(-50%);
                    opacity: 0.5;
                    background: $svns-white;
                    transition: width 0.3s;
                }

                &:hover::after {
                    width: calc(100% + 1rem);
                    opacity: 0.8;
                }
            }
        }
    }
}
