.standings-top-five {
    $this: &;
    position: relative;
    z-index: 2;

    .widget-header {
        padding: 1.6rem 0;
    }

    &__table-body {
        #{$this}__table-cell {
            text-align: center;

            &.isLast {
                display: revert;
                text-align: right;
            }
        }
    }

    &__table-cell {
        padding: 1.3rem 0.8rem;
    }

    &__container {
        display: flex;
        flex-flow: nowrap column;
        justify-content: flex-start;
        padding: 1.6rem;
    }

    &__team-name {
        transition: opacity 0.3s;
        opacity: 1;
    }

    &__table-container {
        position: relative;
        display: flex;
        margin-top: 2.4rem;
    }

    .pill-shaped-button {
        margin: 4.8rem 0;
    }

    &__gender-title {
        font-size: 2.4rem;
        line-height: 1.2;
        text-transform: uppercase;
        color: $svns-white;
    }

    &__table-inner {
        flex: 1;

        &:first-of-type {
            margin-right: 0.8rem;
        }

        &:last-of-type {
            margin-left: 0.8rem;
        }
    }

    &__table {
        margin-top: 2rem;
        border-collapse: collapse;
        border-spacing: 0;
        background: $svns-black;
        min-width: 100%;
    }

    &__table-cell {
        height: 4.6rem;
        text-align: left;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 400;
        color: $svns-white;
        border-bottom: 0.1rem solid $svns-white;

        #{$this}__event-points {
            position: relative;
            z-index: 1;
        }

        &--points {
            text-align: right;
        }

        &--pos {
            width: 2rem;
        }

        &__points,
        &--total {
            font-size: 2rem;
        }

        &.isLast {
            display: none;
        }
    }

    &__team-flag {
        width: 2rem;
        border: 0.1rem solid $white;
        transition: transform 0.3s;
    }

    &__team-link {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 2rem;
        text-decoration: none;
        line-height: 1;
        transition: padding 0.3s;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 0.1rem;
            background: $svns-white;
            transition: width 0.3s;
        }

        @include hover {
            padding-left: 0.3rem;

            &::after {
                width: 100%;
            }
        }
    }

    &__team-position {
        font-size: 2rem;
        line-height: 1;
    }

    @media (min-width: $break-tablet) {
        margin: $layout-margin;

        .widget-header {
            padding: 1.6rem 0;
        }

        &__container {
            padding: 2.4rem;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            padding: 0 2rem;
        }

        &__table {
            width: 100%;

            th {
                font-size: 2.2rem;
            }
        }

        &__table-cell {
            padding: 1.6rem;
            font-size: 3.2rem;
            overflow: hidden;
            position: relative;

            &--pos {
                width: 12rem;
                text-align: center;
            }

            &--team {
                width: 28rem;
            }

            &--points {
                display: none;
            }

            &--total {
                font-size: 4rem;
            }

            &.isLast {
                display: revert;
                text-align: right;
            }
        }

        &__table-inner {
            margin-left: 0;
            overflow-x: auto;

            &:first-of-type {
                margin-right: 2.4rem;
            }

            &:last-of-type {
                margin-left: 2.4rem;
            }
        }

        &__gender-title {
            font-size: 4.8rem;
        }

        &__team-flag {
            width: 2.8rem;
        }

        &__team-link {
            flex-flow: row wrap;
            align-items: baseline;
            gap: 1rem;
            font-size: 4rem;
        }

        &__team-position {
            font-size: 4rem;
            line-height: 1;
        }
    }

    @media (min-width: $break-wide) {
        &__table-cell {
            padding: 2.4rem;
        }

        &__team-flag {
            width: 4.6rem;
        }

        &__team-position,
        &__team-link,
        &__table-cell--total {
            font-size: 6.5rem;
        }
    }
}
