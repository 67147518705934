.landing-page-gallery {
    $this: &;
    background-color: $svns-yellow;

    &__container {
        position: relative;
        padding: 4rem;
        display: flex;
    }

    &__info {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-right: 5.5rem;
    }

    &__photo-section {
        flex: 1;
        padding-left: 5.5rem;
    }

    &__header {
        margin-bottom: 3.2rem;
    }

    &__header-title {
        color: $black;
        font-size: 1.8rem;
        line-height: 2.3rem;
        text-transform: uppercase;
        transition: transform 0.5s;
    }

    &__image-container {
        overflow: hidden;
        position: relative;
    }

    &__items-wrapper {
        display: flex;
        flex-wrap: nowrap;
        max-width: 100%;
        transition: ease-in-out 0.6s margin;
    }

    &__image {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &__item {
        overflow: hidden;
        flex-direction: column;
        min-width: 100%;
    }

    &__picture-wrapper {
        position: relative;
        padding-top: 100%;
    }

    &__picture-image {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__controls-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: -6rem;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $black;
        transition: 0.8s ease-in-out margin;
    }

    &__nav-button {
        width: 4.8rem;
        height: 4.8rem;
        border: none;
        background-color: transparent;
        transition: 0.3s ease opacity, 0.3s ease background-color;
        cursor: pointer;

        svg {
            fill: $svns-white;
        }

        &:not(.no-hover-state) {
            @include hover {
                background-color: $svns-white;

                svg {
                    fill: $black;
                }
            }
        }

        &--previous {
            opacity: 0.4;

            svg {
                margin: 0.4rem 0.4rem 0 0;
            }
        }

        &--next {
            svg {
                margin: 0.4rem -0.4rem 0 0;
            }
        }
    }

    &__pagination {
        font-size: 1.8rem;
        line-height: 2.3rem;
        display: flex;
        align-items: center;
        padding: 1.2rem 1.8rem;
        color: $svns-white;
    }

    &__pagination-length,
    &__pagination-index {
        width: 1rem;
    }

    @media (max-width: $break-desktop) {
        &__container {
            flex-direction: column;
            position: relative;
        }

        &__info {
            padding-right: 0;
        }

        &__photo-section {
            padding-left: 0;
        }
    }

    @media (max-width: $break-phablet) {
        &__container {
            padding: 3.2rem 1.6rem;
        }

        &__header {
            margin-bottom: 2.4rem;
        }

        &__header-title {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }
}
