@mixin info-text {
    @include font-weight('regular');
    text-transform: uppercase;
    font-size: 1.8rem;
    line-height: 1.8rem;

    @media (max-width: $break-tablet) {
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
}

@mixin content-text {
    @include font-dmsans-regular;
    font-size: 1.6rem;
    line-height: 2.4rem;
}
