$arrowBlack: '../i/svg-files/elements/icons/arrow-right-black.svg';
$arrowWhite: '../i/svg-files/elements/icons/arrow-right-white.svg';

.author-bios {
    $this: &;
    position: relative;
    top: 0;
    margin: $layout-margin;

    &__container {
        position: relative;
        z-index: 1;
        overflow: visible;
        padding-bottom: 1.6rem;
    }

    &__title { 
        font-size: 4.8rem;
        line-height: 4.8rem;    
        padding: 1.6rem;
        color: $svns-white;
        text-transform: uppercase;
        white-space: nowrap;
    }

    .pill-shaped-button {
        margin: 0 1.6rem;
        width: calc( 100% - 3.2rem );
    }

    @media (min-width: $break-desktop) {
        top: 2rem;
        margin: $layout-margin;

        &__container {
            padding-bottom: 2.4rem;
        }

        &__title {
            font-size: 16rem;
            line-height: 14.4rem;   
            padding: 2.4rem;
        }
    }
}
