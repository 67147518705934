.video-modal {
    $this: &;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3000;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.4s ease-in-out, opacity 0.4s 1s;
    background: $svns-white;

    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 8.5rem 0;
        overflow: visible;

        &.wrapper {
            background: none;
            max-width: 150vh;
        }
        @media screen and (min-width: $wrapper-width) {
            padding: 8.5rem 0;
        }

        @media screen and (min-height: 700px) {
            width: 90%;
        }
    }

    &__player {
        width: 5rem;
        min-width: 5rem;
        min-height: 6rem;
        margin-left: -1.5rem;
        overflow: hidden;
        background: $svns-black;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bc-video-player {
        width: 100%;
        opacity: 0;
    }

    &__close {
        display: flex;
        position: absolute;
        top: 6rem;
        right: 0;
        align-items: center;
        cursor: pointer;
        margin-left: -100vw;
        max-height: 0;
        overflow: hidden;
    }

    &__close-text {
        text-transform: uppercase;
        font-size: 1.6rem;
    }

    &__close-icn {
        fill: $svns-black;
        margin-left: 1.2rem;
        width: 1.4rem;
        height: 1.4rem;
        stroke: $svns-black;
    }

    &__meta {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        width: 100%;
    }

    &__match-info {
        margin-bottom: 0.8rem;
    }

    &__title {
        text-align: center;
    }

    @media (max-width: $break-tablet) {
        &__player {
            min-height: 4.4rem;
            height: 4.4rem;
            min-width: 3rem;
            width: 3rem;
            margin-left: -0.2rem;
        }

        &__container {
            width: 90%;
        }

        &__title {
            font-size: 1.6rem;
        }

        &--match-info {
            margin-bottom: 1rem;
        }

        .potd {
            &__team-name {
                font-size: 2rem;
            }

            &__team-flag {
                transform: scale(0.9);
                margin: 0 0.6rem;
            }

            &__vs {
                font-size: 1.2rem;
            }
        }
    }
}

.video-modal-is-active {
    .video-modal {
        transform: translateY(0);
        animation: 0.6s opacityFadeIn forwards 1.2s;
    }

    .video-modal__player {
        opacity: 1;
        animation: 0.6s videoExpand forwards 1.4s ease-in-out;
    }

    .video-modal__container {
        opacity: 1;
    }

    .bc-video-player {
        animation: 0.6s opacityFadeIn forwards 2s;

        .vjs-big-play-button {
            width: 0;
            height: 0;
            animation: 0.4s btnExpand forwards 2s ease-in-out;
        }
    }

    .vjs-big-play-button .vjs-icon-placeholder::before {
        background-size: 0 auto;
        animation: 0.4s playExpand forwards 2s ease-in-out;
    }

    .video-modal__close {
        animation: 0.6s displayCloseBtn forwards 2s;
    }
}
