$arrowBlack: '../i/svg-files/elements/icons/arrow-right-black.svg';
$arrowWhite: '../i/svg-files/elements/icons/arrow-right-white.svg';

.narrow-promo {
    $this: &;
    position: relative;
    margin: $layout-margin-mob;

    &--cta {
        width: 100%;
        max-width: 190rem;
        margin: 0 auto;
        padding: 3rem 1.6rem 5rem;
        padding-right: 6rem;
        background: $svns-black;
        color: $svns-white;
        display: flex;
        font-size: 3.2rem;
        line-height: 1;
        text-decoration: none;
        transition: background 0.3s, color 0.3s, padding 0.3s;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 2.4rem;
            right: 2.4rem;
            width: 1.8rem;
            height: 1.8rem;
            background: url($arrowWhite) center/cover no-repeat;
            transition: 0.8s ease-in-out background-position, right 0.3s;
        }
        @include hover {
            background: $svns-white;
            color: $svns-black;
            padding-left: 3rem;

            &::after {
                right: 5rem;
                background: url($arrowBlack) center/cover no-repeat;
                animation: scrollArrow 0.8s infinite linear;
            }
        }
    }

    @media (min-width: $break-tablet) {
        margin: $layout-margin;

        &--cta {
            padding: 5rem 2.4rem;
            padding-right: 9rem;
            font-size: 4.8rem;

            &::after {
                bottom: auto;
                top: 50%;
                transform: translateY(-50%);
                right: 2.4rem;
                width: 4.8rem;
                height: 4.8rem;
            }
        }
    }
}
