.featured-nav {
    display: flex;
    min-height: 8rem;
    max-width: calc(var(--num-nav-items) * 14rem);
    width: 100%;
    
    &__item {
        border-right: .1rem solid $white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
    }
    
    &__item_link {
        min-height: 8rem;
        padding: .4rem;
        @include link-reset;
        color: $white;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        text-align: center;
        width: 100%;
        justify-content: center;

        @include hover {
            background: $svns-white;
            color: $svns-primary-black;
        }
    }


    @media (max-width: $break-tablet) {
        display: none;
    }
}