.landing-page-gallery-copy {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    &__title {
        font-size: 6.5rem;
        line-height: 6.4rem;
        color: $black;
    }

    &__body {
        font-size: 1.8rem;
        line-height: 2.3rem;
        color: $black;
    }

    @media (max-width: $break-wide) {
        &__title {
            font-size: 4rem;
            line-height: 4.1rem;
        }

        &__body {
            font-size: 1.6rem;
            line-height: 2rem;
        }
    }

    @media (max-width: $break-desktop) {
        &__title {
            margin-bottom: 12rem;
        }

        &__body {
            margin-bottom: 2.4rem;
        }
    }
}
