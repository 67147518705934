@mixin wrapper {
    width: 100%;
    max-width: $wrapper-width;
    background: $svns-black;
    padding: 0;
    margin: 0 auto;
    @include clearfix;

    &.isTransparent {
        background: none;
    }

    @media (max-width: $wrapper-width) {
        overflow: hidden;
    }
}

.wrapper {
    @include wrapper;
}
