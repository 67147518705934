.registration-modal {
    $this: &;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3000;
    opacity: 0;
    transform: translate(100%, 100%);
    transition: transform 0.4s, opacity 0.4s;
    background: $svns-white;

    &__container {
        position: relative;
        padding-top: 8.5rem;
        overflow: auto;
        height: 100%;

        &.wrapper {
            background: none;
        }

        @media screen and (min-width: $wrapper-width) {
            padding: 8.5rem 0;
        }
    }

    &__close,
    &__title {
        display: flex;
        position: absolute;
        top: 4rem;
        align-items: center;
        cursor: pointer;
        max-height: 0;
        overflow: hidden;
    }

    &__title {
        left: 0;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__close {
        right: 0;
        margin-left: -100vw;
    }

    &__close-text,
    &__title-text {
        text-transform: uppercase;
        font-size: 1.6rem;
    }

    &__close-icn {
        fill: $svns-white;
        margin-left: 1.2rem;
        width: 1.4rem;
        height: 1.4rem;
        stroke: $svns-white;
    }

    &__summary {
        font-size: 6.5rem;
        line-height: 6.4rem;
        width: 50rem;
        max-width: calc(100% - 3.2rem);
        text-transform: uppercase;
        color: $svns-black;
        margin: 0 1.6rem 8rem -100vw;
    }

    &__mandatory {
        color: $svns-white;
        font-size: 1.2rem;
        line-height: 1.6rem;
        text-align: right;
        text-transform: uppercase;
    }

    &__iframe {
        width: 67rem;
        max-width: calc(100% - 3.2rem);
        padding: 0 1.6rem;
        opacity: 0;
    }

    @media (min-width: $break-tablet) {
        padding: 0 2.4rem;
    }

    @media (max-width: $break-wide) {
        &__summary {
            font-size: 4rem;
            line-height: 4.1rem;
        }
    }

    @media (max-width: $break-tablet) {
        &__iframe {
            min-width: 100%;
        }
    }
}

.modal-is-active {
    .registration-modal {
        transform: translate(0, 0);
        opacity: 1;
    }

    .registration-modal__iframe {
        animation: 2s opacityFadeIn forwards 1s;
    }

    .registration-modal__close {
        animation: 0.6s displayCloseBtn forwards 0.6s;
    }

    .registration-modal__title {
        animation: 0.6s displayTitle forwards 0.6s;
    }

    .registration-modal__summary {
        animation: 0.6s displaySummary forwards 0.6s;
    }
}
