.sidebar-nav {
    $this: &;
    position: fixed;
    width: 100%;
    top: var(--header-height);
    height: calc(100dvh - var(--header-height));
    left: 0;
    z-index: 10;
    filter: blur(0.5rem);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transform: translateY(-100%);
    transform-style: preserve-3d;
    transform-origin: top;
    transition: all 0.5s ease-out;
    transition-delay: 0.1s;
    background: $svns-primary-black;

    &_list {
        flex-wrap: wrap;
        border-bottom: 0.1rem solid $svns-white;
        display: flex;
        width: 100%;

        &--item {
            flex: 0 0 100%;
            color: $svns-white;
            border-bottom: 1px solid $svns-white;
            transition: background 0.5s;
            overflow: hidden;
            display: flex;
            // stylelint-disable-next-line declaration-last-child

            &:nth-last-child(-n + 1) {
                border-bottom: none;
            }

            &_link {
                display: flex;
                flex-direction: row;
                text-decoration: none;
                padding: 1rem 0 1.5rem 1rem;
                flex: 0 0 100%;
                align-items: center;
                text-transform: uppercase;
                font-size: 3.2em;
                line-height: 1;
                transition: background 0.3s, color 0.3s, letter-spacing 0.3s,
                    padding 0.3s;
            }

            &-bottom {
                flex: 0 0 100%;

                &:nth-child(odd),
                &:nth-child(even) {
                    border: none;
                }

                #{$this}_list--item_link {
                    font-size: 2rem;
                    height: auto;
                    max-height: 4rem;
                }
            }
        }
    }

    &-lang {
        display: flex;
        gap: 0;
        position: relative;
        padding: 1rem 0 0;
        width: 100%;

        &--item {
            flex: 0 0 25%;
            position: relative;
            text-align: center;

            &::after {
                content: '/';
                position: absolute;
                top: 0;
                right: -0.4rem;
                color: $svns-white;
            }

            &_link {
                text-transform: uppercase;
                text-decoration: none;
                color: rgb($svns-white, 0.6);
                padding: 0;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0.1rem;
                    bottom: -0.1rem;
                    left: 50%;
                    transform: translateX(-50%);
                    opacity: 0.5;
                    background: $svns-white;
                    transition: width 0.3s;
                }

                &:hover::after {
                    width: calc(100% + 1rem);
                    opacity: 0.8;
                }

                &.is-active {
                    color: $svns-white;

                    &::after {
                        width: calc(100% + 1rem);
                        opacity: 0.8;
                    }
                }
            }
            // stylelint-disable-next-line selector-max-specificity

            &:nth-last-child(-n + 1) {
                // stylelint-disable-next-line selector-max-specificity

                &::after {
                    display: none;
                }
            }
        }
    }

    @media (min-width: $break-tablet) {

        &_list {
            border-bottom: 0.1rem solid $svns-white;
            padding-bottom: 0;

            &--item {
                flex: 1 0 50%;

                &:nth-child(even) {
                    border-left: 0.1rem solid $svns-white;
                }

                &_link {
                    height: 22vh;
                    min-height: 15rem;
                    font-size: 6.4rem;

                    &:hover {
                        background: $svns-white;
                        color: $svns-primary-black;
                        letter-spacing: 0.1rem;
                        padding-left: 1.6rem;
                    }
                }

                &-bottom {
                    flex: 0 0 25%;
                    border: none;

                    &:nth-child(odd),
                    &:nth-child(even) {
                        border: none;
                    }

                    #{$this}_list--item_link {
                        padding: 2rem 0 2rem 2rem;
                        font-size: 2.4rem;
                        max-height: 6rem;
                        height: auto;
                        min-height: auto;

                        &:hover {
                            color: $svns-primary-black;
                            letter-spacing: 0.05rem;
                        }
                    }
                }
            }
        }

        &_list.col-4 {
            // stylelint-disable-next-line selector-max-specificity
            #{$this}_list--item:nth-last-child(-n + 2) {
                border-bottom: none;
            }
        }

        &_list.col-5 {
            // stylelint-disable-next-line selector-max-specificity
            #{$this}_list--item:nth-last-child(-n + 1) {
                border-bottom: none;
            }
            #{$this}_list--item-bottom {
                flex: 0 0 20%;
            }
        }

        &_list.col-6 {
            // stylelint-disable-next-line selector-max-specificity
            #{$this}_list--item:nth-last-child(-n + 2) {
                border-bottom: none;
            }
            #{$this}_list--item-bottom {
                flex: 0 0 16.6666%;
            }
        }

        &_list.col-7 {
            // stylelint-disable-next-line selector-max-specificity
            #{$this}_list--item:nth-last-child(-n + 1) {
                border-bottom: none;
            }
            #{$this}_list--item-bottom {
                flex: 0 0 14.28%;
            }
        }

        &_list.col-8 {
            // stylelint-disable-next-line selector-max-specificity
            #{$this}_list--item:nth-last-child(-n + 2) {
                border-bottom: none;
            }
            #{$this}_list--item-bottom {
                flex: 0 0 12.5%;
            }
        }

        &-lang {
            position: absolute;
            bottom: 1rem;
            left: 0;

            &--item {
                padding: 0 2rem;
                flex: 0 0 auto;
            }
        }
    }
}
