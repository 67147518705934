.error-404 {
    background-color: $svns-black;
    min-height: 100vh;
    min-width: 100%;
    display: flex;

    &__container {
        position: relative;
        padding: 11rem 4rem;
        display: flex;
        z-index: 1;
        background: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__header {
        position: relative;
    }

    &__logo {
        width: 32.4rem;
        height: 8.2rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../i/svg-files/elements/backgrounds/svns-logo-404.svg');
    }

    &__info {
        flex: 1;
        display: flex;
        align-items: center;

        .pill-shaped-button {
            background-color: $black;

            &__link {
                width: 100%;
                color: $svns-white;
            }
        }
    }

    &__ticker-container {
        position: absolute;
        height: calc(100vh - 12.2rem);
        top: 12.2rem;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }

    &__ticker {
        display: flex;
        position: absolute;
        width: calc(100% + 8%);
        left: -4%;

        &--magenta {
            background-color: $svns-magenta;
            transform: rotate(-13.78deg);
            top: 34%;
        }

        &--green {
            background-color: $svns-green;
            transform: rotate(15deg);
            top: 43%;
        }

        &--cyan {
            background-color: $svns-cyan;
            transform: rotate(7deg);
            bottom: 10%;
        }
    }

    &__ticker-content {
        display: flex;
        align-items: center;
        padding: 1rem 0;
        animation: scrollText 8s infinite linear reverse;
    }

    &__error-message {
        font-size: 8.4rem;
        line-height: 8.4rem;
        color: $black;
        text-transform: uppercase;
        white-space: nowrap;
    }

    &__stacked-logo {
        content: '';
        background-image: url('../i/svg-files/elements/logos/stacked-logo.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 6.6rem;
        width: 6.6rem;
        margin: 0 3rem;
    }

    @media (max-width: $break-tablet) {
        &__logo {
            width: 22rem;
            height: 5.6rem;
        }

        &__error-message {
            font-size: 4.8rem;
            line-height: 4.8rem;
        }

        &__stacked-logo {
            height: 5rem;
            width: 5rem;
        }

        &__ticker {
            &--magenta {
                background-color: $svns-magenta;
                transform: rotate(-13.78deg);
                top: 25%;
            }

            &__ticker {
                padding: 0.4rem 0;
            }

            &--green {
                background-color: $svns-green;
                transform: rotate(15deg);
                top: 49%;
            }

            &--cyan {
                bottom: 12%;
            }
        }
    }
}
