.knockout {
    $this: &;
    position: relative;

    & section {
        padding-bottom: 2.4rem;
    }

    .temporarily-hidden {
        display: none;
    }

    .isLoading {
        position: absolute;
        top: 21rem;
        background: $svns-white;
        width: 0;
        max-width: calc( 95% - 2rem);
        height: .5rem;
        transform: translateX(-50%);
        left: 50%;
        z-index: 1;
        opacity: .5;
        animation: loaderExpand 1.5s infinite linear;
    }

    &-info {
        color: $svns-white;
        padding: 1.6rem;
        font-size: 1.2rem;
        text-transform: uppercase;

        &--play-offs {
            padding: .4rem 1.6rem;
        }

        &:first-of-type {
            padding-top: 1.2rem;
        }
    }

    &-container {
        margin-top: 2rem;
        position: relative;
    }

    &-title {
        font-size: 2.4rem;
        text-transform: uppercase;
        text-align: right;
        color: $svns-white;
        padding: 1.6rem;
        flex: 1;
    }

    &-list {
        color: $svns-white;
        text-transform: uppercase;
        position: relative;
        animation: opacityFadeIn 1s forwards;
        display: flex;
        flex-flow: row wrap;

        &__play-offs-header {
            display: flex;
        }

        &__title {
            font-size: 2.4rem;
            line-height: 2.1rem;
            padding: 1.6rem;
            text-transform: uppercase;
            color: $svns-white;
            flex: 1;
        }

        &__item {
            border-bottom: 1px solid $svns-white;
            border-top: 1px solid $svns-white;
            background: $svns-black;
            padding: 1.6rem;
            animation: opacityFadeIn .5s forwards;
            transition: padding .5s;
            margin-top: -0.1rem;
            flex: 1;
            cursor: pointer;
        }

        &__info {
            display: flex;
            justify-content: space-between;
        }

        &__phase,
        &__clock {
            flex: 1;
        }

        &__phase {
            text-align: left;
        }

        &__clock {
            text-align: right;
        }

        &__teams {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 3.8rem;
            margin-top: .4rem;
            min-height: 8rem;
        }

        &__team {
            align-self: flex-start;
            line-height: 1;
            align-items: center;
            display: flex;

            &:nth-child(3) {
                align-self: flex-end;
            }
        }

        &__score {
            &.isLoser {
                opacity: .5;
            }
        }

        &__abbrev {
            margin: 0 .3rem;
        }

        &__flag-container {
            position: relative;
            width: 2.8rem;
            height: 2.8rem;
            border: .1rem solid $svns-white;
            overflow: hidden;
        }

        &__flag {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        &__flag-fallback {
            height: 100%;
            width: 100%;
            fill: $svns-white;
        }

        &__sun {
            width: 2.8rem;
            height: 2.8rem;
            fill: $svns-white;
        }

        &__score-container {
            display: flex;
            align-self: flex-start;
            transition: transform .5s;
            line-height: 1;
            flex-flow: column;

            &.liveMatch {
                @include eventColors-secondary(color);
                font-size: 3.8rem;
            }

            & span {
                display: none;
            }
        }

        &__trophy {
            width: 4rem;
            height: 6rem;
        }
        
        &__challenger-label {
            float: right;
            font-size: 1.2rem;
            line-height: 1;
            margin-top: .8rem;
        }

        &.isFinal {

            #{$this}-list__item {
                min-width: 100%;
            }
        }
    }

    @media (min-width: $break-wide) {

        &-container {
            margin-top: 4rem;
        }

        &-title {
            font-size: 4.8rem;
        }

        &-info { 
            font-size: 1.4rem;
                padding: 1.6rem 2.4rem;

            &--play-offs  {
                padding: .2rem 2.4rem;
            }
        }

        &-list {
            padding: 0 2.4rem;

            &__play-offs-header {
                padding: .8rem;
            }

            &__title {
                font-size: 4.8rem;
            }

            &__item {
                padding: 2.4rem 0;
                animation: opacityFadeIn .5s forwards;
                transition: padding .5s;
                flex: 0 0 50%;

                &:nth-child(odd) {
                    border-right: .1rem solid $svns-white;
                    padding-right: 2.4rem;
                }

                &:nth-child(even) {
                    padding-left: 2.4rem;
                }
            }

            &.isFinal {

                #{$this}-list__item {
                    flex: 0 0 100%;
                    padding: 2.4rem 0;
                    min-width: 100%;

                    &:nth-child(odd) {
                        border-right:  none;
                    }
                }
            }

            &__teams {
                font-size: 6.5rem;
                line-height: 1.2;
                min-height: none;

                &:nth-child(3) {
                    align-self: flex-end;
                }
            }

            &__team {
                line-height: 1;
                align-items: center;
                display: flex;

                &:nth-child(3) {
                    align-self: flex-start;
                }
            }

            &__abbrev {
                margin: 1.6rem;
            }

            &__score {
                min-width: 8rem;
                text-align: center;
            }

            &__score-container {
                line-height: 1;
                flex-flow: row;
                align-self: center;

                & span {
                    display: inline-block;
                }

                &.liveMatch {
                    font-size: 10rem;
                }
            }

            &__flag-container {
                width: 4.8rem;
                height: 4.8rem;
            }

            &__sun {
                width: 4.8rem;
                height: 4.8rem;
                fill: $svns-white;
            }
        
            &__challenger-label {
                font-size: 1.4rem;
                margin-top: 0;
            }
        }

    }

    @media (max-width: $break-wide) {

        &-list {

            &__item {
                min-width: 50%;
            }

        }
    }

    @media (max-width: $break-phablet) {

        &-list {

            &__item {
                min-width: 100%;
            }

        }
    }
}