.standings-tabs {
    $this: &;
    position: relative;
    z-index: 4;
    margin: $layout-margin-mob;

    &--container {
        margin-top: 0;
        display: flex;
        flex-flow: column nowrap;
        overflow: visible;
        gap: 1rem;
    }

    .standings-title {
        font-size: 4.2rem;
        text-transform: uppercase;
        color: $svns-white;
        padding: 1rem;
        line-height: 1;
    }

    &--list {
        display: flex;
        flex-flow: row nowrap;
        position: relative;
        border: 0.1rem solid $svns-white;
        background: $svns-black;
        margin: 0 1rem 2rem;
        padding: 0.5rem;
        border-radius: 4rem;
        transition: none;

        &.isTabsSticky {
            position: fixed;
            top: 0;
            left: 1rem;
            width: calc(100% - 2rem);
            margin: 0;
            transition: transform 0.5s;
            z-index: 25;

            &::before {
                content: '';
                position: absolute;
                top: -2rem;
                left: -0.2rem;
                width: calc(100% + 0.5rem);
                height: calc(100% + 2.5rem);
                z-index: -1;
                background: rgb($svns-black, 0.5);
            }
        }

        &.top-8 {
            transform: translateY(1rem);
        }

        &.top-16 {
            transform: translateY(7rem);
        }

        &-item {
            color: $svns-white;
            text-transform: uppercase;
            font-size: 1.4rem;
            border-radius: 4rem;
            text-align: center;
            padding: 1rem 0;
            flex: 1;
            position: relative;
            cursor: pointer;
            transition: color 0.3s, background 0.3s, letter-spacing 0.3s;

            &.isActive {
                color: $svns-black;
            }

            @include hover {
                background: rgb($svns-white, 0.05);
                letter-spacing: 0.08rem;
            }
        }

        #{$this}--bg {
            position: absolute;
            top: 0.5rem;
            left: 0;
            transform: translateX(0.5rem);
            width: calc(50% - 0.5rem);
            height: calc(100% - 1rem);
            background: $svns-white;
            border-radius: 4rem;
            z-index: 0;
            transition: transform 0.3s;
        }
    }

    @media (min-width: $break-tablet) {
        margin: $layout-margin;

        &--container {
            margin-top: 2rem;
            gap: 2rem;
        }

        .standings-title {
            font-size: 8.4rem;
        }

        &--list {
            &-item {
                padding: 2rem 0;
                font-size: 2.4rem;
            }

            &.isTabsSticky {
                position: fixed;
                width: calc(100% - 4rem);
                left: 2rem;
            }

            &.top-8 {
                transform: translateY(1rem);
            }

            &.top-16 {
                transform: translateY(9rem);
            }
        }
    }
}
