// stylelint-disable rule-empty-line-before, selector-max-universal, selector-max-type, selector-no-qualifying-type, no-descending-specificity

* {
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    margin-bottom: 0;
}

figure {
    margin: 0;
}

ol,
ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;

    li {
        list-style-type: none;
    }
}

html {
    font-size: 62.5%;
}

body {
    margin: 0 auto;
    @include font-regular;
    background-color: $svns-black;
}

.icon {
    width: 2rem;
    height: 2rem;
    display: block;
    stroke: $white;
}

.skip-to {
    opacity: 0;
    padding: 0 2rem;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    text-decoration: none;
    transition: 0.2s;
    background: $brand-primary;
    line-height: 5rem;
    transform: translateX(-100%);
    z-index: 1000;

    /* stylelint-disable-next-line selector-max-attribute */
    [dir='rtl'] & {
        right: 0;
        left: auto;
        transform: translateX(100%);
    }

    &:focus {
        transform: translateX(0);
        opacity: 1;
    }
}

img {
    max-width: 100%;
}

a {
    color: $brand-primary;
    cursor: pointer;
}
