.authors-articles {

    &.content-listing {
        margin: 0;
    }

    &__no-news-msg {
        color: $svns-white;
        font-size: 2.4rem;
        margin: 0 auto 2.4rem;
        min-width: 100%;
        text-align: center;
    }

    .widget-header {
        width: calc( 100% + 1.2rem );

        &__header-title {
            font-size: 5vw;
        }
    }

    .content-listing {

        &__container {
            padding: 0;
        }

        &__content {
            margin: 0 -1.2rem;
            padding: 0;
        }
    
        &__list {
            transition: 1s max-height ease-in-out;

            &.hide {
                opacity: 0;
            }

            &.fade-in {
                animation: 0.4s ease-in-out opacityFadeIn forwards;
            }
        }
    }

    .content-listing__list .content-listing {

        &__link {
            padding: 0 1.2rem 3.2rem;

            &:nth-child( even ) {
                padding: 0 1.2rem 3.2rem;
            }

            &:first-of-type {
                width: 100%;

                .content-listing { 

                    &__meta-title {
                        font-size: 3.2rem;
                        margin-bottom: .8rem;
                    }

                    &__picture-wrapper {
                        padding-top: 75%;
                    }
                }
            }
        }
    }

    @media ( max-width: $break-tablet ) {

        &__no-news-msg {
            font-size: 1.6rem;
        }

        .widget-header {
            width: 100%;
    
            &__header-title {
                line-height: 4.2rem;
                font-size: 4.2rem;
            }
        }

        .content-listing {

            &__container {
                padding: 0;
            }

            &__content {
                margin: 0 -.4rem;
            }
        }

        .content-listing__list .content-listing {

            &__container {
                padding: 0 1.6rem 1.6rem;
            }

            &__link {
                width: 50%;
                padding: 0 .8rem 3.2rem;

                &:nth-child( odd ) {
                    padding: 0 0 1.6rem .8rem;
                }

                &:nth-child( even ) {
                    padding: 0 .8rem 1.6rem 0;
                }

                &:first-of-type {
                    width: 100%;
                    padding: 0 0 1.6rem;
                
                    .content-listing { 
    
                        &__meta-title {
                            font-size: 2.4rem;
                            line-height: 2.8rem;
                        }
                    }
                }
            }
        }

        .pill-shaped-button {
            margin-top: 1.6rem;
        }
    }
}