.fixtures-matches {

    html {
        scroll-behavior: smooth;
    }

    &__toggle-container {
        display: flex;
        width: 100%;
        // flex-flow: column nowrap;
        flex-wrap: wrap;
        position: sticky;
        top: 5rem;
        z-index: 2;
    }

    .isLoading {
        position: absolute;
        top: 21rem;
        background: $svns-white;
        width: 0;
        max-width: calc( 95% - 2rem);
        height: .5rem;
        transform: translateX(-50%);
        left: 50%;
        z-index: 1;
        opacity: .5;
        animation: loaderExpand 1.5s infinite linear;
    }

    .no-fixtures {
        padding-top: 0;
    }

    @media (min-width: $break-desktop) {
        min-height: 30rem;

        &__toggle-container {
            flex-flow: row nowrap;
            top: 5.5rem;
        }

        .isLoading {
            top: 18rem;
        }
    }
}

.fixture-list {
    $this: &;
    color: $svns-white;
    text-transform: uppercase;
    padding: 1.6rem;
    position: relative;
    animation: opacityFadeIn 1s forwards;
    isolation: isolate;

    &__fixture {
        border-bottom: 1px solid $svns-white;
        background: $svns-black;
        padding: 1.6rem 0;
        animation: opacityFadeIn 0.5s forwards;
        cursor: pointer;
        transition: padding 0.5s;

        /*
        &.is-challenger-play-off-upcoming {
            min-height: 11.4rem;
        }

        &.is-challenger-play-off {
            min-height: 15.2rem;
        }*/
    }

    &[data-gender-filter="Men"] {

        .fixture-list__fixture[data-gender="Women"] {
            display: none;
        }
        .fixture-list__fixture[data-gender="Men"] {
            display: block;
        }
    }

    &[data-gender-filter="Women"] {

        .fixture-list__fixture[data-gender="Men"] {
            display: none;
        }
        .fixture-list__fixture[data-gender="Women"] {
            display: block;
        }
    }

    &__info {
        display: flex;
        justify-content: space-between;
    }

    &__gender,
    &__status,
    &__phase {
        flex: 0 0 33.333%;
    }

    &__status {
        text-align: center;
    }

    &__gender,
    &__status,
    &__phase,
    &__score,
    &__divider {
        &.liveMatch {
            @include eventColors-secondary(color);
        }
    }

    &__phase {
        text-align: right;
    }

    &__teams {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 3.8rem;
        margin-top: 0.4rem;
    }

    &__team {
        align-self: flex-start;
        line-height: 1;
        align-items: center;
        display: flex;
        min-width: 7rem;

        &:nth-child( 3 ) {
            align-self: flex-end;
        }
    }

    &__abbrev {
        margin: 0 0.3rem;
    }

    &__flag-container {
        position: relative;
        width: 2.8rem;
        height: 2.8rem;
        border: .1rem solid $svns-white;
        overflow: hidden;
    }

    &__flag {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    &__flag-fallback {
        height: 100%;
        width: 100%;
        fill: $svns-white;
    }

    &__sun {
        width: 2.8rem;
        height: 2.8rem;
        fill: $svns-white;
    }

    &__score-container {
        display: flex;
        align-items: center;
        transition: transform 0.5s;
        line-height: 1;
        flex-flow: column;

        &.liveMatch {
            @include eventColors-secondary(color);
            font-size: 3.8rem;
        }

        & span {
            display: none;
        }
    }

    &__standings-cta.pill-shaped-button {
        margin: 1.4rem;
        width: auto;
        border: .1rem solid $svns-white;
    }

    &__kick-off-info {
        color: $svns-white;
        padding: 1.6rem;
        font-size: 1.2rem;
        text-transform: uppercase;
    }
        
    &__challenger-label {
        float: right;
        font-size: 1.2rem;
        line-height: 1;
        margin-top: .8rem;
    }

    @media (min-width: $break-desktop) {
        padding: 2.4rem;

        &__fixture {
            padding: 2.4rem 0 0;
            animation: opacityFadeIn .5s forwards;
            cursor: pointer;
            transition: padding .5s;
            scroll-margin-top: 10rem;

            /*
            &.is-challenger-play-off-upcoming,
            &.is-challenger-play-off {
                min-height: 18.2rem;
            }*/

            @include hover {
                padding: 2.4rem 0.6rem 0;

                #{$this}__score-container {
                    transform: translateY(-0.3rem);
                }
            }
        }

        &__teams {
            font-size: 6.5rem;
            line-height: 1.2;
        }

        &__team {
            align-self: center;
            line-height: 1;
            align-items: center;
            display: flex;
            min-width: 11rem;

            &:nth-child( 3 ) {
                align-self: center;
            }
        }

        &__abbrev {
            margin: 1.6rem;
        }

        &__score-container {
            line-height: 1;
            flex-flow: row;

            & span {
                display: inline-block;
            }
        }

        &__flag-container {
            width: 4.8rem;
            height: 4.8rem;
        }

        &__sun {
            width: 4.8rem;
            height: 4.8rem;
        }

        &__standings-cta {
            margin: 3rem 1.6rem 1.6rem;
        }

        &__score-container {

            &.liveMatch {
                font-size: 10rem;
            }
        }

        &__kick-off-info {
            padding: 1.6rem 2.4rem;
            font-size: 1.4rem;
        }
        
        &__challenger-label {
            font-size: 1.4rem;
            margin-top: 0;
        }
    }
}
