.single-promo {
    $this: &;
    position: relative;
    margin: $layout-margin;

    &.img-left {
        #{$this}__info-col {
            order: 2;
        }
    }

    &.isEventPage {
        #{$this} {
            &__logo {
                fill: $svns-white;
            }

            &__container {
                background-color: $svns-black;
            }

            &__category,
            &__title,
            &__summary,
            &__cta {
                color: $svns-white;
            }

            &__cta::after {
                background-image: url('../i/svg-files/elements/icons/arrow-right.svg');
            }
        }
    }

    &__container {
        display: flex;
        padding: 0;
        min-height: 47rem;
        background-color: $svns-white;

        @include eventColors(background-color);
    }

    &__info-col {
        padding: 2.4rem;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__info {
        flex: 1;
    }

    &__category {
        font-size: 1.8rem;
        text-transform: uppercase;
        margin-bottom: 2.4rem;
    }

    &__logo {
        height: 8rem;
        width: 8rem;
        margin-bottom: 2.4rem;
        fill: $svns-black;
        stroke: none;
    }

    &__title {
        font-size: 6.5rem;
        line-height: 1;
        color: $svns-black;
        text-transform: uppercase;
    }

    &__summary {
        margin-top: 2.4rem;
        font-size: 1.8rem;
        line-height: 1.5;
        @include font-dmsans-regular;
    }

    &__cta {
        text-transform: uppercase;
        text-decoration: none;
        text-align: right;
        font-size: 3.2rem;
        line-height: 1.2;
        margin-right: 3.5rem;
        color: $svns-black;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            width: 3.2rem;
            height: 3.8rem;
            margin-left: 0.4rem;
            background-image: url('../i/svg-files/elements/icons/arrow-right-black.svg');
            background-size: 65% 100%;
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: 0.8rem;
            transition: 0.8s ease-in-out background-position;
        }

        @include hover {
            &::after {
                animation: scrollArrow 0.8s infinite linear;
            }
        }
    }

    &__img-col {
        min-width: 47rem;
        overflow: hidden;
        min-height: 100%;
        order: 1;

        img {
            min-height: 100%;
            max-width: none;
        }
    }

    &__picture-image {
        display: flex;
        height: 100%;
        justify-content: center;
    }

    &__image {
        aspect-ratio: 1 / 1;
    }

    @media (max-width: $break-desktop) {
        &__container {
            min-height: none;
        }

        &__logo {
            height: 4rem;
            width: 4rem;
            margin-bottom: 1.6rem;
        }

        &__category {
            margin-bottom: 1.6rem;
        }

        &__title {
            font-size: 3.2rem;
            line-height: 1.2;
        }

        &__summary {
            font-size: 1.6rem;
            line-height: 2.4rem;
        }

        &__cta {
            font-size: 2.4rem;
            line-height: 1.2;
            margin-right: 2.8rem;
            margin-top: 1.6rem;

            &::after {
                width: 2.6rem;
                height: 2.8rem;
                background-size: 60% 100%;
            }
        }
    }

    @media (max-width: $break-tablet) {
        margin: $layout-margin-mob;

        &__container {
            flex-direction: column;
            max-height: none;
            margin-bottom: 0;
        }

        &__info-col {
            order: 2;
        }

        &__img-col {
            min-width: 0;
            min-height: 0;
        }

        &__image {
            aspect-ratio: 16/9;
        }
    }
}
