.animated-title {
    height: 14.5rem;
    overflow: hidden;

    &__list {
        animation: titleChange 6s linear infinite;
        animation-timing-function: steps(1);
    }

    &__event {
        font-size: 16rem;
        line-height: 0.9;
        text-transform: uppercase;
        color: $svns-white;
        white-space: nowrap;
    }

    @media (max-width: $break-wide) {
        height: 10.8rem;

        &__event {
            font-size: 12rem;
            height: 14.5rem;
        }
    }

    @media (max-width: $break-tablet) {
        height: 4.7rem;

        &__event {
            font-size: 5.2rem;
        }
    }
}
