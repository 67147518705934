.fixtures-hero {

    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &--title {
        flex: 1;
        margin-right: 1.6rem;
    }

    .pill-shaped-button {
        height: 8.6rem;

        &__text {
            font-size: 2.4rem;
            white-space: nowrap;
        }

        &__arrow-right {
            width: 1.6rem;
            height: 2rem;
            margin-left: .4rem;
            transform: rotate(320deg);
        }

        @include hover {

            .pill-shaped-button__arrow-right {
                animation: scrollArrow 0.8s infinite linear;
            }
        }
    }

    @media (max-width: $break-desktop) {

        .pill-shaped-button {
            height: 4rem;
        }
    }

    @media (max-width: $break-tablet) {

        .pill-shaped-button {
            padding: 1.6rem;
            margin: 1.6rem 0;
        }

    }

    @media (max-width: $break-phablet) {

        .pill-shaped-button__link {
            width: 100%;
        }
    }
}