.fixtures-tabs {
    $this: &;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    border: 0.1rem solid $svns-white;
    background: $svns-black;
    margin: 0 0.8rem 1.6rem;
    padding: 0.5rem;
    top: 2rem;
    border-radius: 4rem;
    z-index: 1;
    transition: top 0.5s 0.1s;
    opacity: 0;
    animation: opacityFadeIn 1s forwards 0.5s;
    gap: 0.5rem;
    // flex: 1;
    flex-grow: initial;

    &--cup-final,
    &--live {
        opacity: 0;
    }

    &.isFullWidth {
        margin-bottom: 1.5rem;
    }

    &-item {
        color: $svns-white;
        text-transform: uppercase;
        font-size: 1.4rem;
        border-radius: 4rem;
        text-align: center;
        padding: 1rem 0;
        flex: 1;
        position: relative;
        cursor: pointer;
        transition: color 0.3s, background 0.3s, letter-spacing 0.3s;

        &.isActive {
            color: $svns-black;
            background: $svns-white;
            @include hover {
                background: $svns-white;
                letter-spacing: 0.08rem;
            }
        }

        &--mobile {
            display: block;
            z-index: 5;
            pointer-events: none;
        }

        &--desktop {
            display: none;
            z-index: 5;
            pointer-events: none;
        }

        @include hover {
            background: rgb($svns-white, 0.2);
            letter-spacing: 0.08rem;
        }
    }

    &--days,
    &--gender {
       width: 100%;
    }

    &--filter {
        height: auto;
        width: auto;
        flex: 0;
        padding: 2.4rem;
        cursor: pointer;
        align-items: center;
        justify-content: center;

        &:has(.fixtures-tabs__num-filters-selected) {
            min-width: 12rem;
        }

        @include hover {
            background: #47333b; // This is a hack to simulate the transparency...
        }
    }

    &__open-sidebar-icon {
        fill: $svns-white;
        width: 2.4rem;
        height: 2.4rem;
        flex-shrink: 0;
    }

    &__num-filters-selected {
        color: $svns-white;
        flex-shrink: 0;
        font-size: 2.4rem;
    }

    &.pill-shaped-button {
        height: auto;
        width: auto;
        border: .1rem solid $svns-white; 
        cursor: pointer;

        &#{$this}--live {
            @include eventColors-secondary(border-color);

            @include hover {

                .pill-shaped-button__arrow-down-transparent .icon {
                    animation: svgScrollTop .7s infinite linear;
                }
            }

            .pill-shaped-button {    
                padding-left: 6%;

                &__text {
                    @include eventColors-secondary(color);
                    white-space: nowrap;
                }

                &__arrow-down:after {
                    background-image: url('../i/svg-files/elements/icons/arrow-right-transparent.svg');
                }

                &__arrow-down-transparent {       
                    overflow: hidden;
                    height: 2.4rem;
                    width: 2.4rem;
                    display: none;
                    align-items: center;

                    .icon {
                        transform: rotate(90deg);      
                        height: 1.2rem;
                        width: 1.2rem;
                        @include eventColors-secondary(stroke);
                        @include eventColors-secondary(fill);
                    }
                }
            }

            .fixtures-nav_list {

                &--pulse {
                    @include eventColors-secondary(background);
                    top: auto;
                    top: .28rem;
                    left: .32rem;
                }

                &--pulse-border {
                    @include eventColors-secondary(border-color);
                    top: 1.5rem;
                    left: -1.8rem;
                }
            }
        }

        @include hover {
            .pill-shaped-button__arrow-down:after {
                animation: scrollArrow 0.8s infinite linear;
            }
        } 
    }

    .pill-shaped-button {

        &__container {
            position: relative;    
            justify-content: center;
        }

        &__text {
            font-size: 1.4rem;
            line-height: 1.4rem;
            padding: 2rem 0;
            margin: auto;
            white-space: nowrap;
        }

        &__arrow-down {
            display: none;

            &::after {
                content: '';
                position: absolute;
                width: 1rem;
                height: 1rem;
                margin-left: 0.4rem;
                background-image: url('../i/svg-files/elements/icons/arrow-right.svg');
                background-size: 60% 100%;
                background-repeat: no-repeat;
                background-position-y: center;
                background-position-x: 0.8rem;
                transition: 0.8s ease-in-out background-position;
                transform: rotate(90deg);
            }
        }
    }


    @media (max-width: $break-desktop) {

        &--days {
            order: 2;
        }

        &--gender {
            width: auto;
            flex-grow: 1;
        }

        &--filter {
            order: 1;
            padding: 1.6rem;
        }

        &__open-sidebar-icon {
            width: 1.8rem;
            height: 1.8rem;
        }

        &__num-filters-selected {
            font-size: 1.4rem;
        }

        &--filter {
    
            &:has(.fixtures-tabs__num-filters-selected) {
                min-width: 7.6rem;
            }
        }

        &.pill-shaped-button {
            position: fixed;
            z-index: 20;
            bottom: 0;
            height: 4.8rem;
            width: 16rem;
            transform: translateX(-50%);
            left: 50%;
            top: unset;

            &#{$this}--live {
                padding: 0 0 0 1.5rem;
            }
        }

    }

    @media (min-width: $break-desktop) {
        margin: 0 1.8rem 2rem;

        &.isFullWidth {
            margin-bottom: 2.5rem;
        }

        &-item {
            font-size: 2.4rem;
            line-height: 2.4rem;
            padding: 2rem 0;
        }

        &.pill-shaped-button {
            height: auto;
            max-width: 30rem;

            &#{$this}--live {
                padding-left: 9rem;
    
                .fixtures-nav_list {
    
                    &--pulse {
                        top: .3rem;
                        left: .32rem;
                    }
    
                    &--pulse-border {
                        top: 1.9rem;
                        left: -4rem;
                    }
                }            
                
                .pill-shaped-button__arrow-down-transparent {
                    display: flex;
                    margin: 0 1rem;
                    min-width: 2.4rem;
                
                    .icon {
                        height: 1.6rem;
                        width: 1.6rem;   
                        display: flex;            
                    }
                }
            }
        }
    
        .pill-shaped-button {
            &__text {
                font-size: 2.4rem;
                line-height: 2.4rem;
                padding: 2rem 0;    
            }

            &__arrow-down {
                display: block;

                &:after {
                    margin-top: .6rem;
                    width: 2.8rem;
                    height: 1.8rem;
                }
            }
        }
    }
}
