.embeddable-instagram {
    max-width: $article-width;
    margin: 0 auto;
    border-radius: 1.6rem;

    iframe {
        // stylelint-disable-next-line declaration-no-important
        width: 100% !important;
        // stylelint-disable-next-line declaration-no-important
        max-width: 100% !important;
    }

    @media (max-width: $break-tablet) {
        padding: 0 1.6rem;
    }
}
