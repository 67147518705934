// Styles used to reveal the language switcher dropdown

@mixin reveal-lang-switcher {
    .language-switcher__options {
        opacity: 1;
        visibility: visible;
    }

    .language-switcher__current .icon {
        opacity: 0;
        visibility: hidden;
    }
}

.language-switcher {
    $this: &;
    background: $black;
    position: absolute;
    top: -100vh;
    right: 0;
    display: flex;
    min-width: 16.4rem;
    height: 3.7rem;
    font-size: 1.2rem;
    align-items: center;
    transition: background-color 0.2s ease, color 0.2s ease;
    z-index: 2;
    padding: 1rem 1.6rem;
    cursor: pointer;
    animation: slideInFromTopAbsolute 0.7s forwards 0.4s;
    animation-timing-function: ease-out;

    &__cta-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &__current {
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
        height: 100%;

        .icon {
            width: 1.6rem;
            height: 0.8rem;
            margin: 0.3rem 0 0 0.2rem;
            transition: opacity 0.4s ease, visibility 0.4s ease;
        }

        @include hover {
            outline: 0;
        }
    }

    &__current-lang {
        text-transform: uppercase;
        color: $svns-white;
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin-top: 0.2rem;
    }

    &__options {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0;
        overflow: hidden;
        z-index: 1;
        transform: translateY(100%);
        background-color: $black;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease, visibility 0.2s ease;
    }

    &__option {
        font-size: 1.6rem;
        line-height: 2.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1rem;
        position: relative;
        user-select: none;
        color: $svns-white;
        text-transform: uppercase;
        transition: background-color 0.2s ease, box-shadow 0.2s ease,
            color 0.2s ease;

        &--hidden {
            display: none;
        }

        @include hover {
            color: $black;
            background-color: $svns-white;
        }
    }

    @include hover {
        // Desktop: Reveal language switcher dropdown on hover
        @include reveal-lang-switcher;
    }

    @media (max-width: $break-tablet) {
        min-width: 4.8rem;
        height: 4.8rem;
        padding: 1rem;

        &.is-open {
            // Tablet/mobile: Reveal language switcher dropdown with is-open class
            @include reveal-lang-switcher;
        }

        &__current-lang,
        &__arrow-down {
            display: none;
        }

        &__location {
            height: 3rem;
            width: 3rem;
        }

        &__options {
            min-width: 16.4rem;
            left: unset;
            right: 0;
        }
    }

    @media (max-width: $break-phablet) {
        min-width: 3.2rem;
        max-width: 3.2rem;
        height: 3.2rem;
        padding: 1rem 0.4rem;
    }
}
