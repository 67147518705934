.widget-header {
    margin-bottom: 2.4rem;

    &__header-title {
        color: $svns-white;
        font-size: 10.5vw;
        line-height: 1.1;
        text-transform: uppercase;
    }

    &__header-title-split {
        display: block;
        white-space: nowrap;
        text-align: left;

        &:nth-child(even) {
            text-align: right;
        }
    }
    @media (min-width: $break-wide) {
        &__header-title {
            font-size: 16rem;
        }
    }
}
