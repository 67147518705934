.live-music {
    $this: &;

    &-tabs {
        display: flex;
        flex-flow: row nowrap;
        position: relative;
        border: 0.1rem solid $svns-white;
        background: $svns-black;
        margin: 0 1rem 2rem;
        padding: 0.5rem;
        top: 2rem;
        border-radius: 4rem;
        z-index: 1;
        transition: top 0.5s 0.1s;

        &.isSticky {
            position: fixed;
            width: calc(100% - 2rem);
            margin: 0;
            left: 50%;
            transform: translateX(-50%);
            top: auto;
            z-index: 25;

            &::before {
                content: '';
                position: absolute;
                top: -2rem;
                left: -0.2rem;
                width: calc(100% + 0.5rem);
                height: calc(100% + 2.5rem);
                z-index: -1;
                background: rgb($svns-black, 0.5);
            }
        }

        &.top-8 {
            top: 6.5rem;
        }

        &.top-16 {
            top: 12.5rem;
        }

        &-item {
            color: $svns-white;
            text-transform: uppercase;
            font-size: 1.4rem;
            border-radius: 4rem;
            text-align: center;
            padding: 1rem 0;
            flex: 1;
            position: relative;
            cursor: pointer;
            transition: color 0.3s, background 0.3s, letter-spacing 0.3s;

            &.isActive {
                color: $svns-black;
            }

            &--mobile {
                display: block;
                z-index: 5;
                pointer-events: none;
            }

            &--desktop {
                display: none;
                z-index: 5;
                pointer-events: none;
            }
            @include hover {
                background: rgb($svns-white, 0.05);
                letter-spacing: 0.08rem;
            }
        }

        #{$this}-tabs--bg {
            position: absolute;
            top: 0.5rem;
            left: 0;
            transform: translateX(0.5rem);
            width: calc(25% - 0.3rem);
            height: calc(100% - 1rem);
            background: $svns-white;
            border-radius: 4rem;
            z-index: 0;
            transition: transform 0.3s;
        }

        &.col-3 {
            #{$this}-tabs--bg {
                width: calc(33.3333% - 0.3rem);
            }
        }

        &.col-4 {
            #{$this}-tabs--bg {
                width: calc(25% - 0.3rem);
            }
        }

        &.col-5 {
            #{$this}-tabs--bg {
                width: calc(20% - 0.3rem);
            }
        }

        &.col-6 {
            #{$this}-tabs--bg {
                width: calc(16.6666% - 0.3rem);
            }
        }
    }

    @media (min-width: $break-desktop) {
        &-tabs {
            margin: 0 2rem 4rem;

            &-item {
                padding: 2rem 0;
                font-size: 2.4rem;

                &--desktop {
                    display: block;
                }

                &--mobile {
                    display: none;
                }
            }

            &.isSticky {
                position: fixed;
                width: calc(100% - 6rem);
            }

            &.top-8 {
                top: 8rem;
            }

            &.top-16 {
                top: 16rem;
            }
        }
    }
}
