.wsc {
    $this: &;
    position: relative;
    overflow: hidden;
    margin: 0 2.4rem 2.4rem;
    height: 20rem;

    &.is-black-background {

        &:before {
            content: '';
            position: absolute;
            width: 10rem;
            left: 0;
            top: 0;
            height: 100%;
            background: linear-gradient(to left, transparent, $svns-black);
            z-index: 1;
            pointer-events: none;
        }
    
        &:after {
            content: '';
            position: absolute;
            width: 10rem;
            right: 0;
            top: 0;
            height: 100%;
            background: linear-gradient(to right, transparent, $svns-black);
            z-index: 1;
            pointer-events: none;
        }
    }

    &__elem {
        height: 100%;
        transition: margin ease .6s;
    }

    &__button {
        position: absolute;
        border: none;
        font-size: 4rem;
        text-indent: -999rem;
        transition: 0.3s ease opacity, 0.3s ease background-color;
        color: $svns-black;
        cursor: pointer;
        border-radius: 50%;
        background-color: $svns-white;
        height: 4rem;
        width: 4rem;
        overflow: hidden;
        transform: translateY(-50%);
        top: 50%;
        z-index: 2;
        opacity: .4;

        &::after {
            content: '';
            position: absolute;
            right: 20%;
            top: 50%;
            transform: translateY(-50%);
            width: 2.4rem;
            height: 2.4rem;
            background: url($arrowBlack) center/cover no-repeat;
            transition: 0.8s ease-in-out background-position;
        }

        &--previous {
            padding-left: 3rem;

            &::before {
                content: '';
                position: absolute;
                left: 20%;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
                width: 2.4rem;
                height: 2.4rem;
                margin-left: 0;
                background: url($arrowBlack) center/cover no-repeat;
                transition: 0.8s ease-in-out background-position;
            }

            &::after {
                display: none;
            }
        }

        &--next {
            padding-right: 3rem;
            right: 0;
        }

        @include hover {
            opacity: 1;

            &::before {
                animation: scrollArrow 0.8s infinite linear;
            }

            &::after {
                animation: scrollArrow 0.8s infinite linear;
            }
        }
    }

    @media (min-width: $break-tablet) {
        margin: 2.4rem 0 2.4rem 2.4rem;

        #{$this}__button--next {
            right: 3rem;
        }

        &.noScrollLeft {

            &:before {
                display: none;
            }

            #{$this}__button--previous {
                display: none;
            }
        }

        &.noScrollRight {

            &:after {
                display: none;
            }

            #{$this}__button--next {
                display: none;
            }
        }
    }

    @media (max-width: $break-tablet) {
        height: 12rem;
        width: 100%;
        margin: 1.4rem 0;

        &__elem {
            overflow-x: auto;

            #widget-layout-wsc-video {
                padding: 0 1.4rem;
            }
        }

        &:before,
        &:after {
            display: none;
        }

        #{$this}__button {

            &--previous,
            &--next {
                display: none;
            }
        }
    }
}