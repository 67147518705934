.accordion {
    $this: &;
    position: relative;
    margin: $layout-margin-mob;

    &-container {
        position: relative;
        padding: 1.6rem;
    }

    .widget-header {
        padding-bottom: 2rem;
    }

    &-title {
        padding: 0 4rem 0 1rem;
        font-size: 1.5rem;
        line-height: 1;
        text-transform: uppercase;
        background: $svns-black;
        color: $svns-white;
        position: relative;
        cursor: pointer;
        border-bottom: 0.1rem solid $svns-white;
        min-height: 10rem;
        display: flex;
        align-items: center;

        &::before,
        &::after {
            content: '';
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
            width: 1.2rem;
            height: 0.2rem;
            background: $svns-white;
            transition: background 0.3s, opacity 0.3s;
        }

        &::after {
            transform: translateY(-50%) rotate(0);
        }
    }

    &-description {
        padding: 0;
        font-size: 1.4rem;
        background: $svns-white;
        color: $svns-black;
        line-height: 1.4;
        @include font-dmsans-regular;

        & p,
        & a {
            color: $svns-black;
            padding: 1rem 0;
        }

        &--fade {
            opacity: 0;
            position: absolute;
            height: 0;
            overflow: hidden;
            transition: none;
        }
    }

    &-list {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;

        &--item {
            display: flex;
            flex: 0 0 100%;
            flex-direction: column;

            &.isActive {
                #{$this}-title {
                    background: $svns-white;
                    color: $svns-black;
                    border-color: $grey;

                    &::before,
                    &::after {
                        background: $svns-black;
                    }

                    &::before {
                        opacity: 0;
                    }
                }
                #{$this}-description {
                    padding: 2rem;
                    flex: 1;

                    &--fade {
                        position: relative;
                        opacity: 1;
                        overflow: visible;
                        height: auto;
                        transition: opacity 0.3s;
                    }
                }
            }
        }
    }

    @media (min-width: $break-tablet) {
        margin: $layout-margin;

        &-container {
            position: relative;
            padding: 2.4rem;
        }

        &-title {
            padding: 0 5rem 0 2rem;
            font-size: 2.4rem;
            transition: background 0.3s, color 0.3s, padding 0.3s;

            &::before,
            &::after {
                transition: background 0.3s, right 0.3s, opacity 0.3s;
                width: 1.8rem;
                height: 0.2rem;
            }

            &:hover {
                background: $svns-white;
                color: $svns-black;
                padding-left: 2.4rem;

                &::before,
                &::after {
                    background: $svns-black;
                    right: 2.4rem;
                }
            }
        }

        &-description {
            font-size: 1.8rem;
        }

        &-list {
            &.col-2 {
                #{$this}-list--item {
                    flex: 0 0 calc(50% - 1rem);
                }
            }

            &.col-3 {
                #{$this}-list--item {
                    flex: 0 0 calc(33.3333% - 1rem);
                }
            }
        }
    }
}
