.articleWidget {
    &.center {
        // stylelint-disable-next-line selector-max-specificity

        .accordion {
            max-width: 110rem;
            margin: 5rem auto;
            // stylelint-disable-next-line selector-max-specificity

            &-container {
                padding: 0;
            }
        }
    }

    @media (max-width: $break-tablet) {
        .accordion-container {
            padding: 0;
        }
    }
}
