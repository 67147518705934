.pill-shaped-button {
    $this: &;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 9.6rem;
    width: 100%;
    border-radius: 5rem;
    border: 0.2rem solid $svns-white;
    padding: 0 5rem;

    &.active {
        background-color: $svns-white;

        .pill-shaped-button__text {
            color: $svns-black;
        }

        .pill-shaped-button__carat {
            background-image: url('../i/svg-files/elements/icons/carat-black.svg');
            transform: rotate(180deg);
            margin-top: 0.2rem;
        }
    }

    &__link {
        text-decoration: none;

        @include hover {
            #{$this}__container {
                min-width: 100%;
            }
        }
    }

    &.modal-trigger {
        cursor: pointer;

        @include hover {
            #{$this}__container {
                min-width: 100%;
            }
        }
    }

    &.load-more {
        cursor: pointer;

        @include hover {
            #{$this}__arrow-down::after {
                animation: scrollArrow 0.8s infinite linear;
            }
        }
    }

    &__container {
        display: flex;
        align-items: center;
        min-width: 0;
        justify-content: space-between;
        transition: 0.5s min-width ease-out;
    }

    &__arrow-right {
        background-image: url('../i/svg-files/elements/icons/arrow-right.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 3.3rem;
        height: 4rem;
        margin-left: 1.2rem;
    }

    &__arrow-down {
        margin-top: 0.4rem;
        text-transform: uppercase;
        color: $svns-white;
        font-size: 2rem;
        line-height: 1.2;
        height: 3.9rem;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            width: 3.2rem;
            height: 3.2rem;
            margin-left: 0.4rem;
            background-image: url('../i/svg-files/elements/icons/arrow-right.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position-y: center;
            transition: 0.8s ease-in-out background-position;
            transform: rotate(90deg) scale(1);
        }
    }

    &__carat {
        background-image: url('../i/svg-files/elements/icons/carat.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 2rem;
        height: 2rem;
        margin-left: 1.2rem;
    }

    &__text {
        text-transform: uppercase;
        font-size: 4rem;
        line-height: 4rem;
        margin-right: 1.2rem;
        text-align: left;
        width: auto;
        color: $svns-white;
    }

    @media (max-width: $break-tablet) {
        height: 4.8rem;
        padding: 0;

        &__text {
            font-size: 1.4rem;
            line-height: 1.4rem;
            margin-left: 0.35rem;
        }

        &__arrow-right {
            height: 1.1rem;
            width: 1.1rem;
            margin-right: 0.35rem;
        }

        &.load-more {
            .pill-shaped-button__text {
                margin-right: 0.4rem;
            }

            #{$this}__arrow-down {
                margin-top: 0.1rem;
                height: 1.2rem;

                &::after {
                    height: 1.2rem;
                    width: 1.2rem;
                }
            }
        }
    }
}
