.news-listing {
    margin: 0;

    &__no-news-msg {
        color: $svns-white;
        font-size: 2.4rem;
        margin: 0 auto 2.4rem;
        min-width: 100%;
        text-align: center;
    }

    .content-listing {
        &__list {
            transition: 1s max-height ease-in-out;

            &.hide {
                opacity: 0;
            }

            &.fade-in {
                animation: 0.4s ease-in-out opacityFadeIn forwards;
            }
        }

        &__content {
            margin: 0 -1.2rem;
        }

        &__link {
            width: 25%;
            padding: 0 1.2rem 3.2rem;

            &:nth-child(even) {
                padding: 0 1.2rem 3.2rem;
            }

            &:first-of-type,
            &:nth-child(12n) {
                width: 50%;

                .content-listing__picture-wrapper {
                    padding-top: 75%;
                }
            }
        }
    }

    @media (max-width: $break-tablet) {
        &__no-news-msg {
            font-size: 1.6rem;
        }

        .content-listing {
            &__content {
                margin: 0 -0.8rem;
            }

            &__link {
                width: 50%;
                padding: 0 0.8rem 3.2rem;

                &:nth-child(odd) {
                    padding: 0 0.8rem 3.2rem;
                }

                &:nth-child(even) {
                    padding: 0 0.8rem 3.2rem;
                }

                &:first-of-type,
                &:nth-child(12n) {
                    width: 100%;
                }
            }
        }
    }
}
