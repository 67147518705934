.fixtures-nav {
    $this: &;
    display: flex;
    position: relative;
    width: 100%;
    left: 0;
    z-index: 11;
    background: $svns-primary-black;
    @include fixturesColors(background);

    &_list {
        max-height: 100%;
        display: flex;
        flex-flow: row nowrap;        
        scroll-behavior: smooth;
        overscroll-behavior: contain;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        &--item {
            color: $svns-white;
            overflow: hidden;
            display: flex;
            flex: 1 0 auto;
        }

        &--status {
            white-space: nowrap;
            text-transform: uppercase;
            font-size: 1rem;
            margin-bottom: .2rem;
            position: relative;
            display: flex;
            align-self: center;
            justify-content: center;
            gap: .4rem;
        }
        
        &--pulse-border {
            position: absolute;
            background: transparent;
            border: .1rem solid $svns-black;
            margin: .6rem 2rem 0 0;
            border-radius: 50%;
            height: 1.3rem;
            width: 1.3rem;
            left: -1.6rem;
            top: -.2rem;
            transform: scale(.75);
        }
    
        &--pulse {
            position: absolute;
            background: $svns-black;
            animation: fixturePulse .7s infinite linear;
            animation-timing-function: ease;
            border-radius: 50%;
            height: .5rem;
            width: .5rem;    
            left: -1.15rem;
            top: .8rem;
            transform: scale(.75);
        }

        &--svg-container {
            display: flex;
            justify-content: center;
        }

        &--eventlogo {
            width: 3.7rem;
            height: 1.2rem;
            fill: $svns-white;
            stroke: none;
            transition: fill 0.5s;
        }

        &--sunlogo {
            width: 1.2rem;
            height: 1.2rem;
            fill: $svns-white;
            stroke: none;
            transition: fill 0.5s;
        }

        &__completed-tick {
            width: 1.4rem;
            height: 1.4rem;
            margin-top: .2rem;
        }
    
        &--link {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            padding: 2rem;
            flex: 1;
            transition: color 0.5s, background 0.5s;
            gap: 0 0.1rem;
    
            @include hover {
                color: $svns-primary-black;

                #{$this}_list--eventlogo,
                #{$this}_list--sunlogo {
                    fill: $svns-primary-black;
                }

                @include eventColors(background);
            }

            &.isActive {
                color: $svns-primary-black;

                #{$this}_list--eventlogo,
                #{$this}_list--sunlogo {
                    fill: $svns-primary-black;
                }

                @include eventColors(background);
            }

            &.is-live {

                &:not( .isActive ):not( :hover ) {

                    #{$this}_list--pulse {
                        @include eventColors(background);
                    }

                    #{$this}_list--pulse-border {
                        @include eventColors(border-color);
                    }

                    #{$this}_list--status {
                        @include eventColors(color);
                    }

                    #{$this}_list--eventlogo,
                    #{$this}_list--sunlogo {
                        @include eventColors(fill);
                    }
                }
            }
        }
    }

    @media (min-width: $break-tablet) {

        &_list {
            display: flex;    
            width: 100%;

            &--event-container {
                margin-left: 2rem;
                width: calc(100% - 12rem);
                font-size: 2rem;
                padding: 0;
                justify-content: space-between;
            }

            &--eventlogo {
                height: 1.8rem;
                min-width: 5rem;
            }

            &--sunlogo {
                height: 1.8rem;
                min-width: 1.8rem;
            }

            &--pulse {
                left: -1.2rem;
            }

            &--item {
                flex: 1;

                &:hover {
                    #{$this}_list--link {
                        color: $svns-primary-black;
                    }
                    #{$this}_list--logo {
                        fill: $svns-primary-black;
                    }
                    @include eventColors(background);
                }
            }
        }
    }

    @media (min-width: $break-desktop) {        
        
        &_list {

            &--sunlogo {
                height: 2.4rem;
                min-width: 2.4rem;
            }

            &--eventlogo {
                height: 2.4rem;
                min-width: 7.5rem;
            }

            &--status {
                font-size: 1.4rem;
                margin-bottom: .4rem;
            } 

            &--pulse {
                transform: scale(1);
                left: -1.15rem;
                top: .9rem;
            }

            &--pulse-border {
                transform: scale(1);
                top: -.1rem;
            }
        }
    }
}
