.teams {
    $this: &;
    position: relative;
    z-index: 4;
    margin: 0 $layout-margin-mob;

    &-container {
        display: flex;
        flex-flow: nowrap column;
        justify-content: flex-start;
        position: relative;
        z-index: 3;
        padding: 0 1.6rem;

        &.is-challenger {
            padding-top: 7.5rem;
        }
    }

    .no-squads {
        color: $svns-white;
        font-size: 2rem;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        padding: 2rem 0;
    }

    &-list {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        gap: 0 1.6rem;
        min-height: 50vh;

        &--player {
            width: 100%;
            position: relative;
            padding: 1.6rem;
            margin-bottom: 1.6rem;
            color: $svns-white;
            text-transform: uppercase;
            border-bottom: .1rem solid $svns-white;
            transition: background .3s, color .3s;
            cursor: pointer;

            &.isCaptain {

                &::before {
                    content: 'C';
                    position: absolute;
                    font-size: 1.4rem;
                    line-height: 1.5;
                    top: 1.6rem;
                    right: 1.6rem;
                    text-align: center;
                    color: $svns-white;
                    width: 2.2rem;
                    height: 2.2rem;
                    border: .1rem solid $svns-white;
                }

                #{$this}-list--player__name {
                    padding-right: 3rem;
                }
            }

            &__content {
                position: relative;
                display: flex;
                flex-flow: row nowrap;
                gap: 1.6em;
                flex: 1;
            }

            &__info {
                display: flex;
                flex: 1;
                flex-flow: column nowrap;
                justify-content: space-between;
            }

            &__squad-number {
                font-size: 2rem;
            }

            &__image {
                width: 7.5rem;
                height: 7.5rem;
                display: flex;
                position: relative;
                overflow: hidden;
                border: .1rem solid $svns-white;

                .player__picture-image {
                    position: relative;
                    z-index: 2;
                    width: 100%;
                    height: 100%;

                    &.no-image {
                        background: url(../i/svg-files/themes/player-placeholder.svg) bottom/cover no-repeat;
                        background-size: 7rem 7rem;
                        opacity: .6;
                        filter: drop-shadow(0 0 2rem rgba($svns-black, .2));
                    }
                }

                .player__image {
                    width: 100%;
                    height: auto;
                    transform: scale(1);
                    transform-origin: top;
                    filter: drop-shadow(0 0 .5rem rgba(25, 0, 10, .2));
                    transition: transform .3s;
                }
            }

            &__sun {
                position: absolute;
                top: -6.5rem;
                left: -7.5rem;
                z-index: 1;
                width: 27rem;
                height: 27rem;
                stroke: none;
                fill: rgb($svns-black, .09);
                @include teamsColors(background);
            }

            &__name {
                font-size: 2rem;
                line-height: 1.1;
            }

            &__position {
                font-size: 1.4rem;
            }

            @include hover {
                background: $svns-white;
                color: $svns-black;

                &.isCaptain {

                    &::before {
                        color: $svns-black;
                        border-color: $svns-black;
                    }
                }

                #{$this}-open--panel {

                    &::before,
                    &::after {
                        background: $svns-black;
                    }
                }

                #{$this}--extras-list {
                    border-right: .1rem solid $svns-black;

                    &:last-of-type {
                        border-right: none;
                    }
                }

                &.isActive {
                    #{$this}--extras {
                        border-color: $svns-black;
                    }
                }

                &.isLoading {

                    &::after {
                        background: $svns-black;
                    }

                    #{$this}--extras {
                        border-top: none;
                        margin-top: 0;
                        padding-top: 0;
                        opacity: 0;
                    }
                }

                .player__image {
                    transform: scale(1.05);
                }
            }

            &.isActive {
                background: $svns-white;
                color: $svns-black;

                #{$this}--extras {
                    height: auto;
                    overflow: visible;
                    margin-top: 1.6rem;
                    padding-top: 1.6rem;
                    opacity: 1;
                    border-top: .1rem solid $svns-black;
                }

                #{$this}-open--panel {

                    &::before {
                        opacity: 0;
                    }

                    &::after {
                        background: $svns-black;
                    }

                    &__more {
                        display: none;
                    }

                    &__less {
                        display: block;
                    }
                }
            }

            &.isLoading {

                &::after {
                    content: '';
                    position: absolute;
                    bottom: .4rem;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 10rem;
                    height: .1rem;
                    background: $svns-black;
                    animation: loader 1.5s infinite;
                    opacity: 1;
                }
                #{$this}--extras {
                    border-top: none;
                    margin-top: 0;
                    padding-top: 0;
                    opacity: 0;
                }
            }
        }
    }

    &--extras {
        height: 0;
        overflow: hidden;
        margin-top: 0;
        padding-top: 0;
        opacity: 0;
        transition: opacity .6s;
        border-top: none;
        font-size: 2.4rem;

        &-inner {
            display: flex;
            flex-flow: row;
        }

        .extras-title {
            font-size: 1.4rem;
            margin-bottom: 1.6rem;
        }

        .extras-label {
            font-size: 1.2rem;
            margin-bottom: .6rem;
            display: block;
        }

        &-list {
            flex: 1;
            border-right: .1rem solid $svns-black;
            margin-right: 4%;
            padding-right: 1rem;

            &:last-of-type {
                border-right: none;
                margin-right: 0;
            }

            &__item {
                flex: 0 0 33.333%;
                padding-bottom: 1.6rem;
            }
        }

        .isVisible {
            display: flex;
        }
    }

    &-open--panel {
        position: absolute;
        bottom: 0;
        right: 0;
        padding-right: 3rem;
        font-size: 1.4rem;

        &__less {
            display: none;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
            width: 1.2rem;
            height: 0.2rem;
            background: $svns-white;
            transition: background 0.3s, opacity 0.3s;
        }

        &::after {
            transform: translateY(-50%) rotate(0);
        }
    }

    &__tape {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        display: flex;
        width: 100%;
        max-width: fit-content;
        overflow: hidden;
        flex-flow: row wrap;
    }

    &__tape-container {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        margin-top: 2.4rem;
        padding: 2.4rem 0;
        display: flex;
        background: $svns-white;
        @include teamsColors(background);
    }

    &__tape-list {
        display: flex;
        animation: scroll 100s linear infinite;
        min-width: 200rem;
        width: 100%;
    }

    &__tape-item {
        flex: 0 0 auto;
        text-transform: uppercase;
        margin: 0 2rem;
        color: $svns-black;
        font-size: 1.4rem;
    }

    @media (min-width: $break-desktop) {
        margin: 0 $layout-margin;

        &-container {
            padding: 2.4rem;
        }

        #{$this}--extras-list {
            border-right: .1rem solid $svns-white;

            &:last-of-type {
                border-right: none;
            }
        }

        &-list {
            margin-top: 1rem;
            gap: 0 2.4rem;

            &--player {
                width: calc(50% - 1.2rem);
                padding: 2.4rem;

                &.isCaptain {

                    &::before {
                        font-size: 2.4rem;
                        line-height: 1.5;
                        top: 2.4rem;
                        right: 2.4rem;
                        width: 3.4rem;
                        height: 3.4rem;
                    }
                }

                &__content {
                    gap: 2.4rem;
                }

                &__image {
                    width: 11rem;
                    height: 11rem;

                    .player__picture-image {
                        &.no-image {
                            background-size: 10rem 10rem;
                        }
                    }
                }

                &__sun {
                    width: 48rem;
                    height: 48rem;
                    top: -13rem;
                    left: -15rem;
                }

                &__name {
                    font-size: 3.2rem;
                }

                &__squad-number {
                    font-size: 3.2rem;
                }

                &__position {
                    font-size: 2.4rem;
                }

                &.isActive {
                    background: $svns-black;
                    color: $svns-white;

                    #{$this}--extras {
                        height: auto;
                        overflow: visible;
                        margin-top: 2.4rem;
                        padding-top: 2.4rem;
                        opacity: 1;
                        border-top: .1rem solid $svns-white;
                    }

                    #{$this}-open--panel {

                        &::before,
                        &::after {
                            background: $svns-white;
                        }
                    }
                }

                &.isLoading {

                    &::after {
                        background: $svns-white;
                        height: .2rem;
                    }

                    #{$this}--extras {
                        border-top: none;
                        margin-top: 0;
                        padding-top: 0;
                        opacity: 0;
                    }
                }

                @include hover {
                    background: $svns-white;
                    color: $svns-black;
                    #{$this}-open--panel {
                        &::before,
                        &::after {
                            background: $svns-black;
                        }
                    }

                    &.isActive {
                        color: $svns-black;

                        #{$this}--extras {
                            border-color: $svns-black;
                        }

                        #{$this}-open--panel {

                            &::before,
                            &::after {
                                background: $svns-black;
                            }
                        }
                    }
                }
            }
        }

        &-open--panel {
            padding-right: 4rem;
            font-size: 2.4rem;

            &::before,
            &::after {
                width: 1.8rem;
                height: .2rem;
            }
        }

        &--extras {
            font-size: 2.4rem;

            &-list {
                &__item {
                    flex: 0 0 33.333%;
                    padding-bottom: 3rem;
                }
            }
        }
    }

    @media (min-width: $break-phablet) {

        &-container .is-challenger {
            padding-top: 9rem;
        }

        #{$this}--extras {
            font-size: 3.2rem;

            &-list__item {
                padding-bottom: 2.4rem;
            }
        }

        .no-squads {
            font-size: 1.6rem;
        }

        .extras-title {
            font-size: 2rem;
            margin-bottom: 2.4rem;
        }

        .extras-label {
            font-size: 1.6rem;
            margin-bottom: 1rem;
        }
    }
}