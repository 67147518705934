$arrowBlack: '../i/svg-files/elements/icons/arrow-right-black.svg';
$arrowWhite: '../i/svg-files/elements/icons/arrow-right-white.svg';
$darkBgs: '.aus', '.chi', '.ger', '.fij', '.fra', '.nzl', '.sam', '.rsa';

.team-listing {
    $this: &;
    position: relative;
    margin: $layout-margin;
    z-index: 4;

    &__container {
        padding: 2.8rem 1.6rem;
        overflow: visible;
        min-height: 100vh;
    }

    &-title {
        color: $svns-white;
        line-height: 1;
        font-size: 4.8rem;
        text-transform: uppercase;
        padding-bottom: 2rem;
    }

    &__loader.loader {
        top: 50vh;
        transform: translate(-50%, -50%);
    }

    &-list {
        position: relative;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        flex-flow: column nowrap;
        min-height: 70vh;

        &.show-teams {

            @for $i from 1 through 22 {

                #{$this}-list--item:nth-child( #{ $i } ) {
                    animation: slideInFromLeft .6s forwards calc( ( $i - 1 ) * .2s ) ease-out;
                }
            }
        }

        &--item {
            background: $svns-black;
            flex: 1;
            margin-left: -100%;
            position: relative;
            overflow: hidden;
            position: relative;
            border-bottom: .1rem solid $svns-white;

            @include hover {
                #{$this}-list--container {
                    opacity: 1;
                }
            }
        }

        &--container {
            opacity: 0;
            transition: opacity 0.2s;
            position: absolute;
            top: 0;
            display: flex;
            height: 100%;
            background: $svns-white;
            @include teamsColors(background);
        }

        &--link {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            gap: 2rem;
            padding: 1rem 0;
            font-size: 4.2rem;
            line-height: 1;
            text-transform: uppercase;
            text-decoration: none;
        }

        &--left {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        &--teamname {
            display: flex;
            justify-content: space-between;
            flex-flow: column nowrap;
        }

        &--name {
            display: block;
            font-size: 1.4rem;
        }

        &__flag-container {
            width: 5rem;
            height: 5rem;
            border: 0.1rem solid $svns-white;
            position: relative;
            overflow: hidden;
        }

        &--flag {
            position: absolute;
            height: 100%;
            width: 100%;
        }

        &__sun {
            position: absolute;
            height: calc( 100% + .2rem );
            width: calc( 100% + .2rem );
            fill: $svns-white;
        }

        &--right {
            font-size: 4.5rem;
            text-indent: -999rem;

            &::after {
                content: '';
                position: absolute;
                display: inline-block;
                bottom: 1rem;
                right: 0.5rem;
                width: 1.8rem;
                height: 1.4rem;
                margin-left: 2.5rem;
                background: url($arrowWhite) center/cover no-repeat;
                transition: 0.8s ease-in-out background-position;
            }
        }

        &--tape {
            position: relative;
            animation: scroll 20s linear infinite;
            padding: 1rem;
            max-width: 200rem;
            flex: 1;
            display: none;

            &__item {
                flex: 0 0 auto;
                display: flex;
                align-items: center;
                color: $svns-black;
                gap: 2rem;
                font-size: 6.5rem;
                text-transform: uppercase;
                margin-right: 10rem;

                &::after {
                    content: '';
                    width: 3.2rem;
                    height: 2.6rem;
                    background: url($arrowBlack) center/cover no-repeat;
                    transition: 0.8s ease-in-out background-position;
                }
            }
        }

        @each $color in $darkBgs {

            &--container#{$color} {

                #{$this}-list--tape__item {
                    color: $svns-white;

                    &::after {
                        background: url($arrowWhite) center/cover no-repeat;
                    }
                }
            }
        }
    }

    @media (min-width: $break-tablet) {
        margin: $layout-margin-mob;

        &-title {
            font-size: 8.4rem;
        }

        &-container {
            padding: 4.8rem 2.4rem;
        }

        &-list {
            &--right {
                text-indent: 0;

                &::after {
                    content: '';
                    position: relative;
                    bottom: 0;
                    right: 0.5rem;
                    width: 3.2rem;
                    height: 2.6rem;
                }
            }

            &--link {
                font-size: 8.4rem;
                padding: 1.5rem 0;
            }

            &--left {
                align-items: center;
                gap: 2rem;
            }

            &--name {
                display: none;
            }

            &__flag-container {
                width: 6rem;
                height: 6rem;
                border: 0.1rem solid $svns-white;
            }

            &--tape {
                display: flex;
            }
        }
    }
}
