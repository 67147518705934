$sidebarNav: '.sidebar-nav';
$mainNav: '.main-nav';
$burgerNav: '.burger-nav';

.main-header {
    $this: &;
    --header-height: 6.1rem;
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 0;
    transition: transform 0.5s 0.1s;

    &.isSticky {
        transform: translate(0, -6.2rem);
    }

    &.isHomePage {

        .main-nav--small {
            display: none;
        }
    }

    &__logo {
        padding: 0 1rem;
        height: 6rem;
        border-right: 1px solid $svns-white;
        display: flex;
        align-items: center;

        & svg {
            width: 10rem;
            height: 4.6rem;
            stroke: none;
        }
    }

    &-container {
        background: $svns-primary-black;
        display: inline-flex;
        position: relative;
        z-index: 101;
        padding: 0;
        border-bottom: 1px solid $svns-white;
        min-height: 100%;
        align-items: center;
        width: 100%;
    }

    &.isTransparent {
        #{$this}-container {
            background: none;
        }
        #{$burgerNav} {
            background: none;
        }
    }

    &.isScrolling {
        #{$this}-container {
            background: $svns-black;
        }
        #{$burgerNav} {
            background: $svns-black;
        }
    }

    // mobile normal
    @include burgerNav(mobileNormal);

    &.isSidebarVisible {
        #{$sidebarNav} {
            pointer-events: all;
            transform: translateY(0);
            visibility: visible;
            opacity: 1;
            filter: blur(0);
        }
        // mobile open
        @include burgerNav(mobileOpen);
    }

    &.isMainVisible {
        // stylelint-disable-next-line
        #{$mainNav} {
            pointer-events: all;
            transform: translateY(0);
            visibility: visible;
            opacity: 1;
            filter: blur(0);
            // stylelint-disable-next-line
            &-btn:not(.isEvent) {
                background: $svns-white;
                color: $svns-primary-black;
            }

            &-btn {
                .arrow {
                    transform-origin: center;
                    transform: translate(0, -50%) rotate3d(1, 0, 0, 180deg);

                    &::after,
                    &::before {
                        background: $svns-primary-black;
                    }
                }
            }
        }
    }

    @media (min-width: $break-tablet) {
        --header-height: 8.1rem;
        // desktop normal
        @include burgerNav(desktopNormal);

        &.isSidebarVisible {
            // desktop open
            @include burgerNav(desktopOpen);
        }

        &.isSticky {
            transform: translate(0, -8.2rem);
        }

        &__logo {
            padding: 0 1.5rem;
            height: 8rem;

            & svg {
                width: 13rem;
                height: 5.5rem;
            }
        }
    }
}
