.splash-page {
    background-color: rgba( $black, .75 );
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    z-index: 1200;

    &__content {
        height: 60rem;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate( -50%, -50% );
        width: 128rem;
        z-index: 1400;
    }

    &__close {
        background: transparent;
        border: 0;
        outline: 0;
        padding: 0;
        position: absolute;
        top: -4.8rem;
        right: -4.8rem;
        height: 4.8rem;
        width: 4.8rem;
        background: transparent;
    }

    &__close-icon {
        height: 4.8rem;
        width: 4.8rem;
        color: $blue;
        cursor: pointer;
    }

    @media ( max-width: 1376px ) {

        &__close {
            right: 0;
            top: -3rem;
        }
    }

    @media ( max-width: 1279px ) {

        &__content {
            height: 35rem;
            width: 35rem;
        }
    }
}
