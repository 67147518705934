$arrowBlack: '../i/svg-files/elements/icons/arrow-right-black.svg';
$arrowWhite: '../i/svg-files/elements/icons/arrow-right-white.svg';

.activities {
    $this: &;
    position: relative;
    top: 0;
    margin: $layout-margin;

    &-modal-data {
        position: absolute;
        top: -999rem;
        opacity: 0;
    }

    &-container {
        position: relative;
        z-index: 1;
        overflow: visible;
    }

    &-bg {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 200rem;
        height: 200rem;
        stroke: none;
        @include eventColors-secondary(fill);
        z-index: 0;
    }

    .pill-shaped-button__link {
        margin: 2rem;
        display: flex;
    }

    &-content {
        display: flex;
        flex-flow: nowrap column;
        padding: 2rem;
        gap: 2rem;
        color: $svns-white;
    }

    &-image-container {
        flex: 1;
    }

    &__picture-image {
        flex: 1;
        display: flex;
    }

    &-image-list {
        display: flex;
        position: relative;
        overflow: hidden;

        &--item {
            opacity: 0.5;
            visibility: hidden;
            height: 100%;
            width: 100%;
            position: absolute;
            transition: opacity 0.5s, transform 0.5s;
            transform: scale(1.1);

            &.isVisible {
                position: relative;
                visibility: visible;
                transform: scale(1);
                opacity: 1;
                display: flex;
                flex: 1;
            }
        }
    }

    &__image {
        width: 100%;
    }

    &-list {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;

        &__item {
            padding: 2rem 1rem;
            padding-left: 10rem;
            flex: 1;
            min-height: 12rem;
            border-bottom: 0.1rem solid $svns-white;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            transition: background 0.5s, color 0.5s;

            &::after {
                content: '';
                position: absolute;
                width: 1.6rem;
                height: 1.4rem;
                bottom: 1.5rem;
                right: 0;
                margin-left: 0.4rem;
                background: url($arrowWhite) center/cover no-repeat;
                transition: 0.8s ease-in-out background-position;
            }

            @include hover {
                background: $svns-white;

                #{$this}-list--subtitle,
                #{$this}-list--title {
                    color: $svns-black;
                }

                &::after {
                    animation: scrollArrow 0.8s infinite 0.3s linear;
                    background: url($arrowBlack) center/cover no-repeat;
                }
            }

            #{$this}-more {
                text-transform: uppercase;
                position: absolute;
                bottom: 1rem;
                right: 2.3rem;
                font-size: 2rem;
            }

            &:nth-child(1) {
                padding: 1rem 0;

                #{$this}-list__image {
                    display: none;
                }

                #{$this}-list--title {
                    font-size: 4.2rem;
                }

                #{$this}-list--subtitle {
                    font-size: 2rem;
                }
            }
        }

        &__picture-image {
            display: flex;
        }

        &__image {
            max-width: 8.5rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
        }

        &--title {
            font-size: 2.4rem;
            text-transform: uppercase;
            line-height: 1;
            color: $svns-white;
        }

        &--subtitle {
            font-size: 1.4rem;
            text-transform: uppercase;
        }
    }

    @media (min-width: $break-desktop) {
        top: 2rem;
        margin: $layout-margin;

        &-content {
            flex-flow: nowrap row;
        }

        &-image-container {
            max-width: 65rem;
            position: sticky;
            align-self: flex-start;
            top: 8rem;
        }

        &-list {
            &__item {
                padding: 2rem;
                min-height: 16rem;

                &::after {
                    right: 3rem;
                    width: 2.4rem;
                    height: 2.2rem;
                    bottom: 2rem;
                }

                #{$this}-more {
                    display: none;
                }

                &:nth-child(1) {
                    padding: 2rem;

                    #{$this}-list--title {
                        font-size: 4.8rem;
                    }
                }
            }

            &--title {
                font-size: 4.8rem;
            }

            &__picture-image {
                display: none;
            }

            &--subtitle {
                font-size: 2.4rem;
            }
        }
    }
}
