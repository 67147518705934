$footer-background: #19000a;

.landing-page-footer {
    background: $footer-background;

    &__container {
        padding: 4rem;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__svg {
        height: 28rem;
        width: 27rem;
    }

    &__links {
        display: flex;
        align-items: flex-end;
    }

    &__list {
        display: flex;
    }

    &__item:not(:first-of-type) {
        margin-left: 4rem;
    }

    &__link {
        text-transform: uppercase;
        text-decoration: none;
        font-size: 1.6rem;
        line-height: 2.1rem;
        transition: 0.3s color ease;

        @include hover {
            color: $white;
        }
    }

    @media (max-width: $break-tablet) {
        &__logo {
            order: 1;
            margin-top: 0.8rem;
        }

        &__content {
            flex-direction: column;
        }

        &__svg {
            height: 18rem;
            width: 17rem;
        }

        &__list {
            flex-direction: column;
        }

        &__item {
            margin-bottom: 2.4rem;

            &:not(:first-of-type) {
                margin-left: 0;
            }
        }
    }

    @media (max-width: $break-phablet) {
        &__container {
            padding: 3.2rem 1.6rem 1.8rem;
        }
    }
}
