.embeddable-quote {
    display: flex;
    flex-flow: row wrap;
    margin: 0 auto;
    max-width: 92rem;

    blockquote {
        flex-basis: 100%;
        margin: 0;
        // stylelint-disable-next-line selector-max-type

        p {
            color: $svns-white;
            font-size: 8.4rem;
            line-height: 8.4rem;
            text-transform: uppercase;
        }
    }

    figcaption {
        font-size: 1.8rem;
        line-height: 1.8rem;
        text-transform: uppercase;
        flex-basis: 100%;
        margin-top: 2.4rem;
    }

    @media (max-width: $break-tablet) {
        blockquote {
            // stylelint-disable-next-line selector-max-type

            p {
                font-size: 4.8rem;
                line-height: 4.8rem;
            }
        }

        figcaption {
            font-size: 1.4rem;
            line-height: 1.4rem;
            padding: 0 1.6rem;
        }
    }
}
