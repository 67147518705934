.fixtures-sidebar {
    $this: &;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    height: 100dvh;
    background-color: $svns-black;
    color: $svns-white;
    width: 100%;
    max-width: 50rem;
    border-left: .1rem solid $svns-white;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.5s, opacity 0.5s;

    --header-height: 26.4rem;
    --footer-height: 11rem;

    header {
        height: var(--header-height);
    }

    footer {
        height: var(--footer-height);
        background: linear-gradient(0deg, rgba($svns-black, 0.96) 0%, rgba($svns-black, 0) 100%);
    }

    &.u-active {
        transform: translateX(0);
        opacity: 1;
    }

    &__close-btn {
        padding: 2.4rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: .8rem;
        border-bottom: .1rem solid $svns-white;
        cursor: pointer;
        transition: gap ease-out .2s;

        @include hover {
            gap: 1.2rem;
        }
    }

    &__cta-icon {
        width: 2.4rem;
        height: 2.4rem;
    }

    &__close-text {
        text-transform: uppercase;
        font-size: 2.4rem;
    }

    &__close-icon {
        width: 2.4rem;
        height: 2.4rem;
        fill: $svns-white;
    }

    .widget-header {
        padding: 2.4rem;
        margin-bottom: 0;

        &__header-title {
            font-size: 6.5rem;
        }
    }

    &__teams {
        overflow: scroll;
        max-height: calc(100dvh - var(--header-height));
        padding-bottom: var(--footer-height);
    }

    &__team-list-item {
        padding: 2.4rem;
        font-size: 2.4rem;
        text-transform: uppercase;
        position: relative;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        gap: .8rem;
        transition: all ease-out .2s;

        &--all-teams {
            justify-content: flex-start;

            > span {
                font-size: 1.6rem;
                transform: translateY(-0.8rem);
            }
        }

        .icon {
            display: none;
        }

        &.isActive {
            background-color: rgba($svns-white, 0.1);   

            .icon {
                display: block;
            }
        }
    }

    &__cta-wrapper {
        display: flex;
        justify-content: center;
        padding: 2.4rem;
        gap: 2.4rem;
        position: sticky;
        bottom: 0;
    }

    &__cta {
        @include button-reset;
        flex: 1;
        display: flex;
        justify-content: center;
        gap: .8rem;
        font-size: 2.4rem;
        text-transform: uppercase;
        background: $svns-black;
        color: $svns-white;
        padding: 1.6rem 2.4rem;
        border-radius: 5rem;
        border: .1rem solid $svns-white;
        cursor: pointer;

        &--apply {
            background: $svns-white;
            color: $svns-black;
        }

        transition: gap ease-out .2s;

        @include hover {
            gap: 1.2rem;
        }
    }

    @media( width < $break-desktop) {
        --header-height: 21.1rem;
        --footer-height: 9.8rem;

        .widget-header {
            padding: 1.6rem;

            &__header-title {
                font-size: 4.8rem;
            }
        }

        &__close-text {
            font-size: 2rem;
        }

        &__close-icon {
            width: 2rem;
            height: 2rem;
        }

        &__team-list-item {
            padding: 1.6rem;
            font-size: 1.4rem;
        }

        &__cta-wrapper {
            padding: 2.4rem 1.6rem;
        }

        &__cta {
            font-size: 1.4rem;
            gap: .4rem;
        }

        &__cta-icon {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
}