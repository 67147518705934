$link: #00f !important;

.cookie-list {
    background-color: $svns-blue;
    padding: 4rem 4rem 1.2rem;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../i/svg-files/elements/backgrounds/landing-page-sun.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        animation: opacityFadeIn 1.2s forwards 0.1s;
        animation-timing-function: ease-in-out;
    }

    &__content {
        position: relative;
        z-index: 1;
        background: $svns-white;
        padding: 2rem;
        opacity: 0;
        margin: 3rem auto -3rem;
        animation: opacityFadeIn 1s forwards 0.6s, slideUp 0.6s forwards 1s;
        animation-timing-function: ease-in-out;
    }

    #ot-sdk-cookie-policy {
        opacity: 0;
        animation: opacityFadeIn 0.6s forwards 1s;
        animation-timing-function: ease-in-out;

        .ot-sdk-container {
            width: 100% !important;
        }

        h3 {
            font-size: 1.8rem !important;
            line-height: 2.4rem !important;
            text-transform: uppercase !important;
            color: $black !important;
        }

        .ot-sdk-cookie-policy-group {
            font-size: 1.6rem !important;
            line-height: 2rem !important;
            text-transform: uppercase !important;
            color: $black !important;
        }

        .ot-table-header {
            background: $black;
            color: $svns-white !important;
            text-transform: uppercase !important;
            font-size: 1.4rem !important;
            line-height: 1.8rem !important;
        }

        #cookie-policy-description,
        .ot-cookies-td span,
        p,
        td,
        span {
            font-size: 1.4rem !important;
            line-height: 1.8rem !important;
            color: $black !important;
        }

        #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a {
            color: $link;
        }
    }

    @media (max-width: $break-desktop) {
        padding: 3rem 3rem 1.2rem;
    }

    @media (max-width: $break-tablet) {
        &::after {
            background-image: url('../i/svg-files/elements/backgrounds/landing-page-sun-mob.svg');
        }
    }

    @media (max-width: $break-phablet) {
        padding: 3rem 1.6rem 1.2rem;

        &__content {
            padding: 1.5rem;
        }
    }
}
