.author-bio {
    $this: &;

    &__container {
        padding: 3.2rem;
        overflow: visible;
    }

    &__return-link {
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        margin-left: 3.2rem;

        &::before {
            content: '';
            position: absolute;
            width: 2rem;
            height: 2.2rem;
            left: -2rem;
            background-image: url('../i/svg-files/elements/icons/arrow-right-white.svg');
            background-size: 65% 100%;
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: .8rem;
            transition: .8s ease-in-out background-position;
            transform: rotate( 180deg );
        }

        @include hover {

            &::before {
                animation: scrollArrow 0.8s infinite linear;
            }
        }
    }

    &__subtitle {
        color: $svns-white;
        font-size: 4.8rem;
        line-height: 1;
        text-transform: uppercase;
        float: right;
        text-align: right;
    }

    &__columns {
        position: relative;
        display: flex;
        width: 100%;
        padding-top: 1.2rem;
    }

    &__col {
        flex: 1;
        min-width: 50%;

        &:first-of-type {
            padding-right: 1.2rem;
        }

        &:last-of-type {
            padding-left: 1.2rem;
        }
    }        

    &__summary {
        position: relative;
        color: $svns-white;
        font-size: 2rem;
        padding-bottom: 3.2rem;

        p {
            color: $svns-white;
            font-size: 2rem;
            line-height: 2.4rem;
        }            
            
        ol {

            li {
                list-style-type: decimal;
                list-style-position: outside;
                margin: 1.6rem 0 1.6rem 5rem;
            }
        }    
            
        ul {

            li {
                list-style-type: disc;
                list-style-position: outside;
                margin: 1.6rem 0 1.6rem 5rem;
            }
        }

        &::after {
            content: '';
            position: absolute;
            height: .1rem;
            width: 100%;
            background-color: #E5F9F7;
            bottom: 0;
        }
    }

    &__social {
        margin-top: 2.4rem;
        display: flex;
    }

    &__social-link {
        @include hover {
            transform: scale(1.2);
            transition: transform .3s ease;
        }
    }

    &__social-icon {
        fill: $svns-faded-white;
        margin-right: 1rem;
        height: 2.4rem;
        width: 2.4rem;
    }

    &__fallback-image {
        width: 100%;
        height: 100%;
        padding-top: 83%;
        background: url(../i/svg-files/themes/player-placeholder.svg) bottom / cover no-repeat;
        background-size: 80%;
        opacity: .6;
        filter: drop-shadow(0 0 2rem rgba( $svns-black, .2));
    }

    &__sun-logo {
        fill: $svns-white;
        width: 100%;
        height: unset;
        aspect-ratio: 1;
    }

    &__name {
        color: $svns-white;
        font-size: 4.8rem;
        line-height: 5.2rem;
        text-transform: uppercase;    
        float: right;
        text-align: right;
        margin-top: 1.6rem;
    }

    .pill-shaped-button {
        margin-top: 4.8rem;
    }

    &__cta.pill-shaped-button {
        margin: 4.8rem 3.2rem 0;
        width: calc(100% - 6.4rem);
    }

    .loader {
        top: 0;
    }
    
    @media ( min-width: $break-tablet ) {

        @supports ( position: sticky ) {

            &__col {

                &:last-of-type {
                    position: sticky;
                    align-self: flex-start;
                    top: 1.2rem;
                }
            }
        }

        &__name {
            opacity: 0;
        }
    }
    
    @media ( max-width: $break-tablet ) {

        &__container {
            padding: 1.6rem;
        }

        &__columns {
            flex-direction: column;
            padding: 0;
            margin-top: 1.6rem;
        }

        &__col {
            padding: 0; 

            &:first-of-type {
                order: 2;
                padding: 0;
            }
    
            &:last-of-type {
                padding: 0;
            }
        } 

        &__name {
            display: none;
        }

        &__summary {
            margin-top: 1.6rem;
            font-size: 1.6rem;
            line-height: 1.9rem;

            p {
                font-size: 1.6rem;
                line-height: 1.9rem;
            }            
            
            ol, ul {
                li {
                    margin-left: 3.2rem;
                }
            }  
        }

        .widget-header {
            margin-top: 1.6rem;

            &__header-title {
                line-height: 4.2rem;
                font-size: 4.2rem; 
            }
        }

        &__subtitle {
            font-size: 2.4rem;
            line-height: 2.4rem;
        }

        &__cta.pill-shaped-button {
            margin: 1.6rem 1.6rem 0;
            width: calc(100% - 3.2rem);
        } 

        .loader {
            top: -1rem;
        }
    }
}