.embeddable-photo {
    max-width: 92rem;
    margin: 0 auto;

    img {
        width: 101%;
    }

    figcaption {
        @include font-dmsans-regular;
        font-size: 1.4rem;
        line-height: 2.1rem;
        flex-basis: 100%;
        margin-top: 0.8rem;
    }

    @media (max-width: $break-tablet) {
        img {
            max-width: none;
            width: calc(100% + 2.4rem);
            margin-left: -1.2rem;
        }

        figcaption {
            font-size: 1.2rem;
            line-height: 1.8rem;
            margin-top: 0.8rem;
            padding: 0 1.6rem;
        }
    }
}
