.embeddable-facebook-post {
    max-width: 54rem;
    margin: 0 auto;
    padding: 2rem;
    border-radius: 1rem;
    background: $svns-white;

    iframe {
        // stylelint-disable-next-line declaration-no-important
        width: 100% !important;
    }

    @media (max-width: $break-tablet) {
        margin: 0 auto;
    }

    @media (max-width: 590px) {
        margin: 1.6rem;
    }
}
