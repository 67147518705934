/* KEYFRAMES */

$animatedTitle: -14.5rem;
$char: 9.6rem;
$charMob: 2.2rem;
$charTransition: 1s;
$charDelay: .2s;

@keyframes slideInFromLeft {
    0% {
        margin-left: -100vw;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes slideInFromRight {
    0% {
        margin-left: 100vw;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes slideInFromMarginRight {

    0% {
        margin-right: -100vw;
    }

    100% {
        margin-right: 0;
    }
}

@keyframes slideInFromTopAbsolute {
    0% {
        top: -100vh;
    }

    100% {
        top: 0;
    }
}

@keyframes opacityFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes opacityFadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes slideUp {
    0% {
        margin: 3rem auto -3rem;
    }

    100% {
        margin: 0 auto;
    }
}

@keyframes scrollText {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-33.34%);
    }
}

@keyframes scrollArrow {
    0% {
        background-position-x: 0.8rem;
    }

    49% {
        transition: none;
    }

    50% {
        background-position-x: 3rem;
    }

    51% {
        background-position-x: -3rem;
    }

    52% {
        transition: 1s ease background-position;
    }

    100% {
        background-position-x: 0.8rem;
    }
}

@keyframes rotate100 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes titleChange {
    0% {
        margin-top: 0;
    }

    12.5% {
        margin-top: $animatedTitle;
    }

    25% {
        margin-top: calc(2 * $animatedTitle);
    }

    37.5% {
        margin-top: calc(3 * $animatedTitle);
    }

    50% {
        margin-top: calc(4 * $animatedTitle);
    }

    62.5% {
        margin-top: calc(5 * $animatedTitle);
    }

    75% {
        margin-top: calc(6 * $animatedTitle);
    }

    87.5% {
        margin-top: calc(7 * $animatedTitle);
    }

    100% {
        margin-top: 0;
    }
}

@keyframes buttonExpand {
    0% {
        height: 21.8rem;
        width: 21.8rem;
        background: $svns-white;
        border-radius: 0;
        right: 0;
        bottom: 0;
        position: fixed;
    }

    100% {
        height: 100vh;
        width: 100vw;
        border-radius: 0;
        background: $svns-white;
        right: 0;
        bottom: 0;
        position: fixed;
    }
}

@keyframes modalExpand {
    0% {
        height: 0;
        width: 0;
        background: $svns-white;
        right: 0;
        bottom: 0;
        position: fixed;
    }

    100% {
        height: 100vh;
        width: 100vw;
        background: $svns-white;
        right: 0;
        bottom: 0;
        position: fixed;
    }
}

@keyframes socialShareOpen {
    0% {
        height: 21.8rem;
        width: 21.8rem;
        background: $svns-white;
        border-radius: 0;
        position: fixed;
    }

    100% {
        height: 100vh;
        width: 100vw;
        border-radius: 0;
        background: $svns-white;
        position: fixed;
    }
}

/*
@keyframes buttonExpandMobile {

    0% {
        width: 15.4rem;
        height: 15.4rem;
        border-radius: 50%;
        background: $svns-white;
    }

    100% {
        height: 100vh;
        width: 100vw;
        border-radius: 0;
        background: $svns-white;
    }
} */

@keyframes watchFilmRotate {
    0% {
        transform: rotate(0deg);
    }

    55% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: rotate(180deg);
    }
}

@keyframes videoExpand {
    0% {
        width: 3rem;
        height: auto;
    }

    100% {
        width: 100%;
        height: auto;
    }
}

@keyframes btnExpand {
    0% {
        width: 0;
        height: 0;
    }

    100% {
        width: 10rem;
        height: 10rem;
    }
}

@keyframes displaySummary {
    0% {
        margin-left: -100vw;
    }

    100% {
        margin-left: calc(0vw + 1.6rem);
    }
}

@keyframes playExpand {
    0% {
        background-size: 0 auto;
    }

    100% {
        background-size: 10rem auto;
    }
}

@keyframes arrowTransformTop {
    0% {
        transform: rotate(15deg);
        border-left: 10rem solid $svns-black;
        width: 10rem;
        right: -10rem;
    }

    100% {
        transform: rotate(60deg);
        border-left: 5.1rem solid $svns-black;
        width: 5rem;
        right: -5rem;
    }
}

@keyframes arrowTransformBottom {
    0% {
        transform: rotate(345deg);
        border-left: 10rem solid $svns-black;
        width: 10rem;
        right: -10rem;
    }

    100% {
        transform: rotate(298deg);
        border-left: 5.1rem solid $svns-black;
        width: 5rem;
        right: -5rem;
    }
}

@keyframes displayTitle {
    0% {
        max-height: none;
        left: -100vw;
    }

    1% {
        left: -100vw;
    }

    100% {
        left: 1.6rem;
        max-height: none;
    }
}

@keyframes displayCloseBtn {
    0% {
        max-height: none;
        right: -100vw;
    }

    1% {
        right: -100vw;
    }

    100% {
        right: 1.6rem;
        max-height: none;
    }
}

@keyframes svnsLogo {
    0% {
        stroke-dashoffset: 900;
    }

    50% {
        stroke-dashoffset: 450;
    }

    100% {
        stroke-dashoffset: 0;
        stroke-width: 0.05rem;
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes pulse {

    0% {
        height: 1rem;
        width: 1rem;
        left: .5rem;
        top: .5rem;
    }

    100% {
        height: 2.4rem;
        width: 2.4rem;
        left: -.2rem;
        top: -.2rem;
    }
}

@keyframes pulseMob {

    0% {
        height: .6rem;
        width: .6rem;
        top: .2rem;
        left: .2rem;
    }

    100% {
        height: 1.4rem;
        width: 1.4rem;
        left: -.05rem;
        top: -.25rem;
    }
}

@keyframes scrollY {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-70%);
    }
}

@keyframes loaderExpand {
    0% {
        width: 0;
        opacity: 0;
    }

    5% {
        width: 0;
        opacity: 1;
    }

    90% {
        width: 100%;
        opacity: 1;
    }

    100% {
        width: 100%;
        opacity: 0;
    }
}

@keyframes loader {

    0% {
        width: 10rem;
    }

    50% {
        width: calc(100% - 0.8rem);
    }

    100% {
        width: 10rem;
    }
}

@keyframes slideUp {

    0% {
        margin-bottom: -12rem
    }

    100% {
        margin-bottom: 0;
    }
}

@keyframes fixturePulse {

    0% {
        height: .5rem;
        width: .5rem;
    }

    100% {
        height: 1.1rem;
        width: 1.1rem;
        margin-left: -.33rem;
        margin-top: -.3rem;
    }    
}

@keyframes titleTransition {

    0% {
        width: 0;
        opacity: 0;
    }
    100% {
        width: 100%;
        opacity: 0;
    }
}

@for $i from 0 through 15 {

    @keyframes transitionTitle#{$i} {
    
        0% {
            left: calc(-#{$char} - ((#{$i} * #{$char}) - #{$char} ));
            opacity: 0;
        }

        #{$i * 10}% {
            opacity: 0;
        }

        #{$i * 25}% {
            opacity: 1;
        }

        80% {
            left: calc((#{$i} - 1) * #{$char});
        }

        100% {
            left: calc((#{$i} * #{$char}) - #{$char});
            opacity: 1;
        }
    }

    @keyframes transitionTitleFromRight#{$i} {
    
        0% {
            right: calc(-#{$char} - ((#{$i} * #{$char}) - #{$char} ));
            opacity: 0;
        }

        #{$i * 10}% {
            opacity: 0;
        }

        #{$i * 25}% {
            opacity: 1;
        }

        80% {
            right: calc((#{$i} - 1) * #{$char});
        }

        100% {
            right: calc((#{$i} * #{$char}) - #{$char});
            opacity: 1;
        }
    }

    @keyframes transitionTitleMob#{$i} {

        0% {
            left: calc(-#{$charMob} - ((#{$i} * #{$charMob}) - #{$charMob} ));
            opacity: 0;
        }

        #{$i * 10}% {
            opacity: 0;
        }

        #{$i * 25}% {
            opacity: 1;
        }

        80% {
            left: calc((#{$i} - 1) * #{$charMob});
        }

        100% {
            left: calc((#{$i} * #{$charMob}) - $charMob);
            opacity: 1;
        }
    }

    @keyframes transitionTitleFromRightMob#{$i} {

        0% {
            right: calc(-#{$charMob} - ((#{$i} * #{$charMob}) - #{$charMob} ));
            opacity: 0;
        }

        #{$i * 10}% {
            opacity: 0;
        }

        #{$i * 25}% {
            opacity: 1;
        }

        80% {
            right: calc((#{$i} - 1) * #{$charMob});
        }

        100% {
            right: calc((#{$i} * #{$charMob}) - $charMob);
            opacity: 1;
        }
    }
}

@keyframes svgScrollTop {

    0% {
        margin-top: 0;
    }

    49% {
        opacity: 1;
    }
    
    50% {
        margin-top: 4rem;
        opacity: 0;
    }

    51% {
        margin-top: -4rem;
        opacity: 1;
    }

    100% {
        margin-top: 0;
    }
}

@keyframes eventFromLeft {

    0% {
        max-height: 0;
        left: -100vw;
    }

    50% {
        left: -100vw;
        max-height: 20rem;
    }

    100% {
        left: 0;
        max-height: 20rem;
    }
}

@keyframes eventFromRight {

    0% {
        max-height: 0;
        right: -100%;
    }

    50% {
        right: -100%;
        max-height: 20rem;
    }

    100% {
        right: 0;
        max-height: 20rem;
    }
}
