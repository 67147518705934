.event-hero {
    $this: &;
    padding: calc(2rem + #{$layout-margin}) 0 0;
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    .isLoading {
        position: absolute;
        top: 16rem;
        background: $svns-black;
        width: 0;
        max-width: calc( 100% - 2rem);
        height: .5rem;
        transform: translateX(-50%);
        left: 50%;
        z-index: 1;
        opacity: .5;
        animation: loaderExpand 1.5s infinite linear;
    }

    &__bg {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 200rem;
        height: 200rem;    
        min-height: 100vw;
        min-width: 100vw;
        stroke: none;
        @include eventColors-secondary(fill);
        opacity: 0;
        animation: opacityFadeIn 0.8s forwards 0.1s;
        animation-timing-function: ease-in-out;
        z-index: -1;
    }

    &__title {
        font-size: 8.2rem;
        text-transform: uppercase;
        padding-top: 3rem;
        line-height: 1;
    }

    &__container {
        display: flex;
        padding: 0 0 1.6rem;
        background: transparent;
        overflow: visible;
    }

    &__information {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 0 2.4rem;
        column-gap: 2.4rem;
        row-gap: 2.4rem;
        z-index: 1;
        width: 100%;
        max-height: 0;
        transition: max-height .8s ease-in-out, opacity .4s ease;

        &.is-visible {
            max-height: 100rem;
        }

        &.fade-out {
            opacity: 0;
        }
    }

    &__dynamic-content {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        column-gap: 2.4rem;
        row-gap: 2.4rem;
        width: 100%;
    }

    &__top-row-container {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &.has-ticker-tape {
            margin-top: 4.4rem;
        }

        &.complete {        
            
            #{$this}__top-row-text {  
                max-width: 100%;
            }
        }
    }

    &__top-row-text {
        font-size: 3.2rem;
        line-height: 1.2;
        text-transform: uppercase;
        color: $svns-black;
        display: flex;
        align-items: flex-start;
        max-width: 33%;
        @include truncate-multiline(1, 2.8rem);

        &:first-of-type {
            text-align: left;
            flex: 1;
        }

        &:last-of-type {
            display: flex;
            justify-content: flex-end;
            flex: 1;
            text-align: right;
        }

        &.upcoming,
        &.live,
        &.result {
            display: none;
        }
    }

    &__live-status {
        display: flex;
        align-items: flex-start;

        #{$this}__top-row-text {
            text-align: center;
            white-space: nowrap;    
            max-width: 100%;
        }

        &.is-upcoming {
            
            .upcoming {
                display: block;
            }
        }
            
        &.is-in-progress {

            .live {
                display: block;
            }

            #{$this}__pulse {
                display: block;
            }
        }
            
        &.is-result {

            .result {
                display: block;
            }
        }
    }

    &__pulse {
        position: relative;
        height: 2.4rem;
        width: 2.4rem;
        background: transparent;
        border: .2rem solid $svns-black;
        margin: .6rem 2rem 0 0;
        display: none;

        &::after {
            content:'';
            position: absolute;
            height: 1rem;
            width: 1rem;
            left: .5rem;
            top: .5rem;
            background: $svns-black;
            animation: pulse 1s infinite linear;
        }

        &.live {
            display: none;
        }
    }

    &__stadium {
        font-size: 3.2rem;
        line-height: 1.2;
        text-transform: uppercase;
        color: $svns-black;
        order: 1;
        flex: 1 50%;
    }

    &__winners {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &.is-live {
            flex-wrap: wrap;

            #{$this}__winning-team {
                width: 100%;

                &:first-of-type {
                    margin-bottom: 2.4rem;
                }
            }

            #{$this}__abbrev {
                min-width: 60vw;
            }
        }
    }

    &__winners-title {
        font-size: 4.8rem;
        line-height: 1.2;
        text-transform: uppercase;
        margin-bottom: 2.4rem;
        white-space: nowrap;
    }

    &__winning-team {
        display: flex;
        flex-direction: column;
        position: relative;

        &:not( :last-of-type ) {

            #{$this}__flag {
                margin-right: 2.4rem;
            }

            #{$this}__team {
                margin-left: -100vw;
                animation: slideInFromLeft 1.2s forwards .4s ease;
            }
        }

        &:last-of-type {
            align-items: flex-end;

            #{$this}__abbrev {
                margin-right: 2.4rem;
            }

            #{$this}__flag {
                order: 2;
            }

            #{$this}__team {    
                margin-right: -100vw;
                animation: slideInFromMarginRight 1.2s forwards .4s ease;
            }
        }
    }

    &__flag {
        width: 11.2rem;
        height: 11.2rem;
        border: .3rem solid $svns-black;    
        z-index: 10;
    }

    &__score-container {
        position: absolute;
        height: 100%;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        max-height: 100%;
        display: flex;
        align-items: center;
        width: 19rem;
        overflow: hidden;
    }

    &__abbrev,
    &__score {
        font-size: 16rem;
        text-transform: uppercase;
    }

    &__score {
        min-width: auto;
        transition: left .6s ease;

        &--vs {
            opacity: 0;
            animation: opacityFadeIn 1.2s forwards .8s ease;
        }
    }

    &__team {
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    &__countdown {
        order: 1;
        flex: 1 auto;
        justify-content: right;
    }

    &__location {
        font-size: 16rem;
        line-height: 0.9;
        text-transform: uppercase;
        color: $svns-black;
        flex: 1 50%;
        order: 2;
    }

    &__sun-logo {
        flex: 1 auto;
        order: 2;
        line-height: 0.9;
        text-align: right;

        .logo-icon {
            display: inline-block;
            width: 20%;
            height: 13rem;
            min-width: 15rem;
            fill: $svns-black;
            stroke: none;
        }

        .logo-icon--comp {
            display: inline-block;
            width: 60%;
            height: 13rem;
            min-width: 15rem;
            stroke: none;
        }
    }

    &__date {
        font-size: 16rem;
        line-height: 0.9;
        text-transform: uppercase;
        color: $svns-black;
        flex-basis: 100%;
        order: 3;
        text-align: right;
    }

    &__button {
        flex: 1 0 auto;
        order: 4;

        .pill-shaped-button {
            background: $svns-black;
            border: none;
        }
    }

    &.full-width {
        max-width: 100%;
    }

    &-countdown {
        &__countdown {
            text-transform: uppercase;
        }

        &__clock {
            display: block;
            text-align: right;
            margin-right: -1rem;
        }

        &__clock-item {
            display: inline-flex;
            padding-right: 1rem;

            &.hide-unit {
                display: none;
                padding: 0;
            }
        }

        &__clock-digits {
            font-size: 3.2rem;
            line-height: 1;
        }

        &__clock-label {
            font-size: 3.2rem;
            line-height: 1;
        }

        &__hidden {
            display: none;
        }
    }

    .fixture-tape {

        &__container {
            margin-top: -2.8rem;
            position: absolute;
            border-bottom: 1px solid $svns-black;
        }

        &__list--item {
             display: flex;
             align-items: center;
             margin: 0 1.6rem;
        }

        #{$this}__sun {   
            height: 1.2rem;
            width: 1.2rem;
            margin-right: 3.2rem;
        }
    }

    &__ticker-container {
        position: absolute;
        height: 100%;
        top: 0rem;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }

    &__ticker-postponed {
        display: flex;
        position: absolute;
        width: 170%;
        background-color: #19000a;
        transform: rotate(26deg);
        top: 30%;
        z-index: 2;
    }

    &__ticker-content {
        display: flex;
        align-items: center;
        padding: 1.6rem 0;
        animation: scrollText 8s infinite linear reverse;
    }

    &__error-message {
        font-size: 4.8rem;
        line-height: 4.8rem;
        color: #fff;
        text-transform: uppercase;
        white-space: nowrap;
        margin-right: 7.2rem;
    }

    @media (max-width: 1150px) {
        padding: 0;

        .isLoading {
            top: 13rem;
        }

        &__container {
            padding: 0 0 .8rem;
        }

        &__title {
            font-size: 4rem;
        }

        &__bg {
            width: 120rem;
            height: 120rem;
        }

        &__stadium {
            font-size: 2rem;
            line-height: 1.2;
            order: 2;
        }

        &__top-row-text {
            font-size: 2rem;
            line-height: 1.2;
            @include truncate-multiline(1, 2rem);
        }    
        
        &__pulse {
            margin: .5rem .8rem 0 0;
            height: 1.4rem;
            width: 1.4rem;

            &:after {
                height: .6rem;
                width: .6rem;
                top: .2rem;
                left: .2rem;
                animation: pulseMob 1s infinite linear;
            }
        }

        &__winners {
            display: table-column;

            &.is-live {    
                margin-bottom: -.8rem;
    
                #{$this}__winning-team {
    
                    &:first-of-type {
                        margin-bottom: 1.6rem;
                    }
                }
            }
        }

        &__winners-title {
            font-size: 2rem;
            line-height: 1;
            margin-bottom: .8rem;
        }

        &__winning-team {
            margin-top: 1.6rem;
        
            &:not( :last-of-type ) {
    
                #{$this}__flag {
                    margin-right: .8rem;
                }
            }       
            
            &:last-of-type {
    
                #{$this}__abbrev {
                    margin-right: .8rem;
                }
            }
        }

        &__flag {
            height: 2.8rem;
            width: 2.8rem;
            border: .1rem solid $svns-black;  
        }

        &__score-container {
            width: 4.5rem;
        }

        &__top-row-container {
    
            &.has-ticker-tape {
                margin-top: 4.8rem;
            }
        }    
        
        &__live-status {
    
            #{$this}__top-row-text {   
                max-width: 100%;
            }
        }

        &__abbrev,
        &__score {
            font-size: 3.8rem;
        }

        &__countdown {
            order: 1;
        }

        &-countdown {
            &__clock-digits {
                font-size: 2.4rem;
                line-height: 1.2;
            }

            &__clock-label {
                font-size: 2.4rem;
                line-height: 1.2;
            }
        }    
        
        .fixture-tape {

            &__container {
                padding: 2rem 0;
                margin-top: 0;
            }

            &__list--item {
                padding-top: .4rem;
                margin: 0 .8rem;
            }

            #{$this}__sun {   
                margin-right: 1.6rem;
            }
        }

        &__location {
            font-size: 4.2rem;
            line-height: 1;
        }

        &__sun-logo {
            display: none;
        }

        &__date {
            font-size: 4.2rem;
            line-height: 1;
            text-align: left;
        }

        &__button {
            width: 100%;

            .pill-shaped-button {
                height: 4.8rem;
                padding: 0;

                &__text {
                    font-size: 1.6rem;
                    line-height: 1.2;
                }

                &__arrow {
                    width: 1.4rem;
                    height: 2.4rem;
                    margin-left: 0.4rem;
                }

                &__arrow-right {
                    height: 1.1rem;
                    width: 1.1rem;
                    margin-right: .35rem;
                }
            }
        }

        &__information.have-info {
            padding: 0 1.6rem;
            row-gap: .8rem;

            #{$this}__dynamic-content {
                column-gap: 1.6rem;
                row-gap: 0;
                padding-bottom: 1.6rem;
            }
        }
    
        &__ticker-postponed {
            top: 20%;
        }
    
        &__ticker-content {
            padding: .6rem 0;
        }
    
        &__error-message {
            font-size: 1.6rem;
            line-height: 1.6rem;
            margin-right: 2.5rem;
        }
    }

    
    @media ( max-width: $break-desktop ) {

        &__bg {
            width: 100rem;
            height: 100rem;
        }
    }
}
