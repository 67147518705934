$charTransition: 1.2s;
$charTransitionMob: .6s;
$charDelay: .2s;
$additionalSecs: calc(#{$charTransition} * .2);
$initialDelay: 1.2s;
$scoreDelay: 2s;

.transition-title {
    $this: &;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 12rem;
    min-width: 30rem;

    &.reverse {

        #{$this}__char {
            left: auto;
            right: 0;

            @for $i from 0 through 15 {            
                
                &:nth-child( #{$i} ) {
                    left: auto;
                    right: -15rem;

                }
            }
        }
    }

    @media (max-width: 1150px) {
        min-height: 3.8rem;
        min-width: 20rem;
    }

    &__char {
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        opacity: 0;

        &.is-reverse {
            right: 0;
        }

        &:not( .is-reverse ) {
            left: 0;
        }

        @for $i from 0 through 15 {

            &:nth-child( #{$i} ) {
                z-index: calc(10 - #{$i});
                animation-timing-function: ease;
                animation-duration: calc(#{$charTransition} + (#{$i} * #{$additionalSecs}));
                animation-fill-mode: forwards;

                &.is-score {
                    animation-delay: calc((#{$i} * #{$charDelay}) + (#{$initialDelay} + #{$scoreDelay}) );
                }

                &:not( .is-score ) {
                    animation-delay: calc((#{$i} * #{$charDelay}) + #{$initialDelay});
                }

                &.no-delay {
                    animation-delay: calc((#{$i} * #{$charDelay}) );
                }

                &.is-reverse {
                    right: -15rem;
                    animation-name: transitionTitleFromRight#{$i};
                }

                &:not( .is-reverse ) {
                    left: -15rem;
                    animation-name: transitionTitle#{$i};
                }

                @media (max-width: 1150px) {
                    animation-duration: calc(#{$charTransitionMob} + (#{$i} * #{$additionalSecs}));

                    &.is-reverse {
                        right: -15rem;
                        animation-name: transitionTitleFromRightMob#{$i};
                    }
    
                    &:not( .is-reverse ) {
                        left: -15rem;
                        animation-name: transitionTitleMob#{$i};
                    }
                }
            }
        }
    }
}