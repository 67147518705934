$arrowBlack: '../i/svg-files/elements/icons/arrow-right-black.svg';
$arrowWhite: '../i/svg-files/elements/icons/arrow-right-white.svg';

.video__rail {
    $this: &;
    display: flex;
    position: relative;
    margin: .4rem;
    padding-bottom: 2.4rem;
    border-bottom: .1rem solid $svns-white;
    flex-flow: column wrap;

    &--title {
        color: $svns-white;
        padding: 0 0 2.4rem 0;
        font-size: 8.5vw;
        line-height: 1;
        text-transform: uppercase;
        display: block;
        width: 100%;
    }

    &--controls {
        display: none;
        width: 100%;
        align-items: flex-end;
        padding-right: 2.4rem;
    }

    &--nav,
    &--counter {
        color: $svns-white;
        flex: 1;
    }

    &--nav {
        display: flex;
        justify-content: flex-end;
        gap: 0 4rem;
    }

    &--counter {
        font-size: 6.5rem;
        line-height: 1;
        display: flex;
        gap: 1rem;
    }

    &--button {
        border: none;
        background-color: transparent;
        position: relative;
        cursor: pointer;
        width: 6.2rem;
        height: 6.2rem;

        &.no-hover-state{
            pointer-events: none;
        }
        
        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 100%;
            background: url($arrowWhite) center/cover no-repeat;
            transition: .8s ease-in-out background-position;
        }

        &--previous {
            opacity: .4;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
                width: 100%;
                height: 100%;
                margin-left: 0;
                background: url($arrowWhite) center/cover no-repeat;
                transition: .8s ease-in-out background-position;
            }

            &::after {
                display: none;
            }
        }
    }

    &--title-split {
        display: block;
        white-space: nowrap;
        text-align: left;
        &:nth-child(even) {
            text-align: right;
        }
    }

    &--carousel {
        flex: 1;
        width: 100%;
        scroll-behavior: smooth;
        overflow: auto;
        background: $svns-black;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &--info, 
    &--gallery {
        display: flex;
        background: $svns-black;
        flex-flow: row wrap;
        gap: 2rem;
        flex: 0 0 50%;
        padding: 2.4rem;
    }

    &--gallery {
        flex-flow: row nowrap;
        width: 100%;
        gap: 1rem;
        transform: none;
        transition: transform 1s;
    }

    &--info {
        justify-content: space-between;
    }
    &--item {
        display: flex;
        flex: 0 0 90%;
        max-width: 85vw;
        transition: opacity .3s, transform .3s;
        cursor: pointer;
        @include hover {

            #{$this}__image {
                transform: scale(1.02);
                filter: brightness(120%);
            }

            #{$this}--duration {
                transform: scale(1.02);
            }
        }
    }

    &--card {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: box-shadow 1s;
        width: 100%;
        position: relative;
    }

    &__picture-image {
        display: flex;
        width: 100%;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        transition: all .5s;
    }

    &--gallery-title {
        color: $svns-white;
        font-size: 2.4rem;
        line-height: 1;
        text-transform: uppercase;
        margin: -2.4rem 0 0;
    }

    &--tag {
        color: $svns-white;
        text-transform: uppercase;
        margin-right: 1rem;
        font-size: 1.2rem;
        flex: 0 0 auto;    
        padding: .5rem 1.5rem .5rem 0;
        border-radius: 3rem;
    }

    &--exclusive {
        margin-top: 2rem;
        display: flex;
        width: 100%;
        scroll-behavior: smooth;
        overscroll-behavior: contain;
        overflow: auto;
        max-width: 83vw;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &--duration {
        position: relative;
        top: -5rem;
        left: 0;
        width: 14rem;
        height: 5rem;
        padding: .5rem .8rem .5rem 6rem;
        color: $white;
        background-color: $svns-white;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        transition: transform .3s;
        transform: scale(0.6);
        transform-origin: bottom left;

        &::after {
            content: '';
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 1.5rem;
            transform: translate(0, -50%);
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 20px solid black;
        }

        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 5rem;
            height: 100%;
            background: #F5F505;
        }

        &-time {
            display: inline-block;
            vertical-align: middle;
            color: $svns-black;
            font-size: 2.4rem;
        }
    }

    @media (min-width: $break-desktop) {
        flex-flow: row wrap;
        margin: 2.4rem;
        margin: .8rem;

        &--title {
            font-size: 4.5vw;
            padding: 0 2.4rem 0 0;
        }

        &--controls {
            display: flex;
        }

        &--carousel {
            scroll-behavior: unset;
            overflow: hidden;
        }

        &--item {
            opacity: .5;
            transform: scale(.95);
            flex: 0 0 100%;
            max-width: 50vw;
            &.isActive {
                opacity: 1;
                transform: scale(1);
            }
        }

        &--exclusive {
            max-width: 45vw;
        }

        &--tag {
            font-size: 1.4rem;
        }
        
        &--gallery-title {
            font-size: 3.2rem;
        }

        &--duration {
            transform: scale(1);
        }
    }


    @media (min-width: $break-tablet) {
        margin: .8rem;
    }
}
