@use 'sass:math';
// stylelint-disable at-rule-empty-line-before

/* SETTINGS
========================================================================== */
@import 'includes/settings/normalize';
@import 'includes/settings/variables';
@import 'includes/global/mixins';

/* LAYOUT
========================================================================== */
@import 'includes/layout/media-queries';
@import 'includes/layout/main-content';
@import 'includes/layout/fixtures-content';
@import 'includes/layout/wrapper';

/* GLOBAL
========================================================================== */
@import 'includes/global/fonts';
@import 'includes/global/global';
@import 'includes/global/typography';
@import 'includes/global/webview';

/* ANIMATION
========================================================================== */
@import 'includes/animation/keyframes';

/* COMPONENTS
========================================================================== */
@import "includes/components/_social-share.scss";
@import "includes/components/_transition-title.scss";
@import "includes/components/_video-player.scss";
@import "includes/components/_widget.scss";

/* WIDGETS
========================================================================== */


@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/accordion-enhanced/styles/_accordion-enhanced.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/accordion/styles/_accordion.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/activities/styles/_activities.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/activities/styles/_modal.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/article/styles/article.mixins.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/article/styles/article.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/author-bios/styles/_author-bio.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/author-bios/styles/_authors-articles.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/author-listing/styles/_author-bios-list.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/author-listing/styles/_author-listing.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/content-hero/styles/_content-hero.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/content-listing/styles/_content-listing.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/content-promo-rail/styles/_content-promo-rail.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/cookie-consent/styles/_cookie-article.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/cookie-consent/styles/_cookie-policy.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/cookie-consent/styles/_one-trust.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/embeddable/accordion/styles/_embeddable-accordion.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/embeddable/facebook-post/styles/_facebook-post.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/embeddable/instagram/styles/_instagram.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/embeddable/photo/styles/_embeddable-photo.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/embeddable/quote/styles/_embeddable-quote.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/embeddable/single-promo/styles/_embeddable-single-promo.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/embeddable/twitter/styles/_twitter.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/embeddable/video/styles/_embeddable-video.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/error-pages/styles/_error-404.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/error-pages/styles/_error-500.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/event-hero/styles/_event-hero.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_filter-sidebar.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_fixture-cta.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_fixtures-hero.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_knockouts.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_lineup.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_main-nav.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_matches.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_modal.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_no-fixtures.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_pools.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_secondary-nav.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_tabs.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_tape.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_timeline.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/fixtures/styles/_title.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/global-footer/styles/_footer-app-nav.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/global-footer/styles/_footer-bottom-nav.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/global-footer/styles/_footer-logo.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/global-footer/styles/_footer-social-nav.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/global-footer/styles/_footer.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/google-ads/styles/_google-ad.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/hospitality/styles/_hospitality-cards.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/hospitality/styles/_hospitality-gallery.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/hospitality/styles/_hospitality-header.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/hospitality/styles/_hospitality-info.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/hospitality/styles/_tape.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/landing-page/styles/_footer.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/landing-page/styles/_gallery-copy.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/landing-page/styles/_gallery.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/landing-page/styles/_hero.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/landing-page/styles/_locations-carousel.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/landing-page/styles/_registration.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/language-switcher/styles/_language-switcher.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/live-music/styles/_live-music.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/live-music/styles/_modal.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/live-music/styles/_tabs.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/live-music/styles/_tape.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/logo-carousel/styles/logo-carousel.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/main-header/styles/_burger-nav.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/main-header/styles/_featured-nav.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/main-header/styles/_main-header.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/main-header/styles/_main-nav.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/main-header/styles/_menu-btn.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/main-header/styles/_sidebar-nav.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/narrow-promo/styles/_narrow-promo.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/news-listing/styles/_filter.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/news-listing/styles/_news-listing.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/promo-grid-enhanced/styles/_main.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/promo-grid-enhanced/styles/_teams.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/promo-grid/styles/_promo-grid.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/registration/styles/_registration-modal.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/registration/styles/_registration-page.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/single-promo/styles/_single-promo.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/splash-page/styles/_splash-page.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/standings-top-five/styles/_standings-top-five.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/standings/styles/_modal.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/standings/styles/_standings.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/standings/styles/_tabs.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/standings/styles/_tape.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/team-listing/styles/_team-listing.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/teams/styles/_event-nav.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/teams/styles/_hero.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/teams/styles/_tabs.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/teams/styles/_teams.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/tickets/styles/_tape.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/tickets/styles/_tickets-cards.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/tickets/styles/_tickets-gallery.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/tickets/styles/_tickets-header.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/tickets/styles/_tickets-info.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/video-hero/styles/_animated-title.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/video-hero/styles/_video-hero.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/video-modal/styles/_video-modal.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/video-rail/styles/_modal.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/video-rail/styles/_video-rail-related.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/video-rail/styles/_video-rail.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/svns/wsc/styles/_wsc.scss";

/* UTILITIES
========================================================================== */
@import "includes/utilities/_embeddable-widget.scss";
@import "includes/utilities/_trumps.scss";

/* WIDGET HEADER
========================================================================== */
@import "includes/widget-header/_widget-header.scss";

/* MORE BUTTON
========================================================================== */
@import "includes/pill-shaped-button/_pill-shaped-button.scss";

/* LOADER
========================================================================== */
@import "includes/loader/_loader.scss";

// stylelint-enable
