.footer-logo {
    $this: &;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: center;

    &--link {
        width: 80%;
    }

    &--svns {
        width: 100%;
        height: auto;
        max-height: 12rem;
        fill: $svns-white;
        stroke: $svns-white;
        transition: fill 0.5s, stroke 0.5s, stroke-width 0.5s;
        stroke-width: 0;
    }

    @media (min-width: $break-tablet) {
        padding-top: 0;
        margin-bottom: 1rem;
        justify-content: flex-start;

        &--link {
            width: 72%;
            :hover#{$this}--svns {
                fill: $svns-primary-black;
                stroke: $svns-white;
                stroke-width: 0.1rem;
                animation: svnsLogo 2s forwards linear;
            }
        }

        &--svns {
            min-width: 50rem;
            min-height: 22rem;
            max-height: none;
            fill: $svns-white;
            stroke: $svns-white;
            transition: fill 1s, stroke 1s;
            stroke-width: 0;
            stroke-dashoffset: -900;
            stroke-dasharray: 900;
        }
    }
}
