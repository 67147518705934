$arrowBlack: '../i/svg-files/elements/icons/arrow-right-black.svg';
$arrowWhite: '../i/svg-files/elements/icons/arrow-right-white.svg';

.promo-grid-enhanced.is-teams {

    .promo-grid-enhanced {

        &--pagination {
            color: $svns-white;
        }

        &--cards__card {
            position: relative;
            @include teamsColors(background);

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0.16;
                background-image: url('../i/svg-files/elements/backgrounds/team-card-sun.svg');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }

            &.nzl {

                &::before {    
                    background-color: rgba( 250, 250, 250, .6);
                }

            }
        }

        &--nav__button {
            color: $svns-white;
        }

        &--nav__button::after,
        &--nav__button--previous::before {
            background: url($arrowWhite) center/cover no-repeat;
        }

        &__cta-container {
            padding: 3.2rem 1.6rem;
        }

        &__cta:not(:hover) {
            background: transparent;
        }

        &--title {
            display: flex;
            flex-flow: column nowrap;
            font-size: 3.2rem;
            line-height: 1;

            &__text-field {
                display: block;
                margin-bottom: 1.6rem;
                font-size: 1.4rem;
            }

            &__flag {
                height: 2.4rem;
                width: 2.4rem;
                margin-right: 0.4rem;
                border: 0.1rem solid $white;
            }

            &__name {
                text-align: right;
            }
        }

        &--flag-abbrv {
            display: flex;
            align-items: center;
        }

        &__stats-container {
            flex: 1;
            margin-top: 6.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__stat:last-of-type {
            text-align: right;
        }

        &__value {
            font-size: 3.2rem;
            line-height: 1;
        }

        &__key {
            font-size: 1.4rem;
            line-height: 1.2;
        }

        &--ctas {
            margin-top: 2.4rem;
        }

        @media (min-width: $break-desktop) {

            &__cta-container {
                padding: 4.8rem 2.4rem;
            }

            &--title {
                font-size: 6.5rem;

                &__text-field {
                    margin-bottom: 2.4rem;
                    font-size: 3.2rem;
                }

                &__name {
                    margin-top: 1.2rem;
                }

                &__flag {
                    height: 4.6rem;
                    width: 4.6rem;
                    margin-right: 0.8rem;
                }
            }

            &__value {
                font-size: 6.5rem;
                line-height: 1;
            }

            &__key {
                font-size: 2.4rem;
                line-height: 1.2;
            }
        }

        @media (max-width: $break-tablet) {

            &__cta {
                background: $svns-white;
                color: $svns-black;
                border: 0.1rem solid $svns-black;

                &:not(:hover) {
                    background: $svns-white;
                }

                &::after {
                    background: url(../i/svg-files/elements/icons/arrow-right-black.svg)
                        center/cover no-repeat;
                }
            }
        }
    }

    @media (max-width: $break-desktop) {

        .u-show-desktop {
            width: 100%;
        }
    }
}
