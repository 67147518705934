.author-bios-list {
    $this: &;
    display: flex;
    flex-flow: nowrap column;
    padding: 0 1.6rem;
    gap: 2rem;
    color: $svns-white;

    &__link {
        text-decoration: none;

        &:not(a) {
            #{$this}__item {

                &::after {
                    content: none;
                }
            }
        }
    }

    &__image-container {
        flex: 1;
        display: none;
    }
    
    &__picture-image {
        flex: 1;
        display: flex;
    }

    &__fallback-image {
        width: 100%;
        height: 100%;
        padding-top: 100%;
        background: url(../i/svg-files/themes/player-placeholder.svg) bottom / cover no-repeat;
        background-size: 90%;
        opacity: .6;
        filter: drop-shadow(0 0 2rem rgba( $svns-black, .2 ));
    }

    &__sun-logo {
        fill: $svns-white;
        width: 100%;
        height: unset;
        aspect-ratio: 1;
    }

    &__image-list {
        display: flex;
        position: relative;
        overflow: hidden;
    }

    &__image-list-item {
        opacity: 0.5;
        visibility: hidden;
        height: 100%;
        width: 100%;
        position: absolute;
        transition: opacity 0.5s, transform 0.5s;
        transform: scale(1.1);

        &.isVisible {
            position: relative;
            visibility: visible;
            transform: scale(1);
            opacity: 1;
            display: flex;
            flex: 1;
        }
    }

    &__list {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        order: 2;
    }

    &__item {
        padding: 1.5rem 1rem;
        padding-left: 10rem;
        min-height: 12rem;
        border-bottom: 0.1rem solid $svns-faded-white;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        transition: background .5s;

        &.isHidden {
            display: none;
        }

        &::after {
            content: '';
            position: absolute;
            width: 1.6rem;
            height: 1.4rem;
            bottom: 1.5rem;
            right: 0;
            margin-left: 0.4rem;
            background: url($arrowWhite) center/cover no-repeat;
            transition: 0.8s ease-in-out background-position;
        }

        @include hover {
            background: $svns-white;

            #{$this}__subtitle,
            #{$this}__title {
                color: $svns-black;
            }

            &::after {
                animation: scrollArrow 0.8s infinite 0.3s linear;
                background: url($arrowBlack) center/cover no-repeat;
            }
        }
    }

    &__title {
        font-size: 2.4rem;
        text-transform: uppercase;
        line-height: 1;
        color: $svns-white;
    }

    &__name {    
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: inline-block;    
        padding-top: .2rem;
        height: 2.2rem;
    }

    &__subtitle {
        font-size: 1.4rem;
        text-transform: uppercase;
        @include truncate-multiline(2, 2.2rem);
    }

    @media (max-width: $break-desktop) {
        margin-bottom: 2.4rem;

        &__title {
            min-height: 4.8rem;
        }

        &__image,
        &__fallback-image,
        &__sun-logo {
            max-width: 8.6rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            max-height: 9.4rem;
            padding-top: 0;
        }
    }

    @media (min-width: $break-desktop) {
        flex-flow: nowrap row;
        padding: 2.4rem;

        &__image-container {
            max-width: 50rem;
            position: sticky;
            align-self: flex-start;
            top: 16rem;
            display: block;
        }

        &__image {
            width: 100%;
        }

        &__list {
            order: 0;

            #{$this} {

                &__picture-image,
                &__sun-logo {
                    display: none;
                }
            }
        }

        &__item {
            padding: 2rem;
            min-height: 16rem;

            &::after {
                right: 3rem;
                width: 2.4rem;
                height: 2.2rem;
                bottom: 2rem;
            }
        }

        &__title {
            font-size: 4.8rem;
        }

        &__name {    
            padding-top: .8rem;
            height: 4.8rem;
        }

        &__picture-image {
            flex: 1;
            display: flex;
        }

        &__subtitle {
            font-size: 2.4rem;
            @include truncate-multiline(2, 2.4rem);

            &--desktop {
                display: inline-block;
            }

            &--mobile {
                display: none;
            }
        }
    }
}