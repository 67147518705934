.landing-page-locations {
    $this: &;
    background-color: $black;
    overflow: hidden;

    &__container {
        position: relative;
        padding: 4rem 4rem 2.4rem;
    }

    &__header {
        margin-bottom: 3.2rem;
    }

    &__header-title {
        color: $svns-white;
        font-size: 1.8rem;
        line-height: 2.3rem;
        text-transform: uppercase;
    }

    &__carousel {
        position: relative;
    }

    &__scroll-list {
        display: flex;
        flex-wrap: nowrap;
    }

    &__picture-wrapper {
        position: relative;
        padding-top: 100%;
    }

    &__slide-container {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: visible;
        transition: left 0.5s ease-in-out;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__picture-image {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        transition: transform 0.5s;
    }

    &__image {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: 0.6s transform ease-in-out, 0.6s filter ease-in-out;
    }

    &__item {
        overflow: hidden;
        flex-direction: column;
        color: $svns-white;
        min-width: 27.5%;

        &:not(:last-of-type) {
            margin-right: 1.6rem;
        }
    }

    &__item-meta {
        display: flex;
        flex-direction: column;
        padding-top: 2.4rem;
    }

    &__meta-date {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    &__meta-city {
        font-size: 6.4rem;
        line-height: 6.9rem;
        margin-top: 0.8rem;
        text-transform: uppercase;
        white-space: nowrap;
    }

    &__meta-country {
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-transform: uppercase;
    }

    &__controls-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 2.4rem;
    }

    &__nav-button {
        width: 4.8rem;
        height: 4.8rem;
        border: none;
        background-color: transparent;
        transition: 0.3s ease opacity, 0.3s ease background-color;
        cursor: pointer;

        svg {
            fill: $svns-white;
        }

        &:not(.no-hover-state) {
            @include hover {
                background-color: $svns-white;

                svg {
                    fill: $black;
                }
            }
        }

        &--previous {
            opacity: 0.4;

            svg {
                margin: 0.4rem 0.4rem 0 0;
            }
        }

        &--next {
            svg {
                margin: 0.4rem -0.4rem 0 0;
            }
        }
    }

    &__pagination {
        font-size: 1.8rem;
        line-height: 2.3rem;
        display: flex;
        align-items: center;
        padding: 1.2rem 1.8rem;
        color: $svns-white;
    }

    &__pagination-length,
    &__pagination-index {
        width: 1rem;
    }

    @media (max-width: $break-tablet) {
        &__item {
            min-width: 43%;

            &:last-of-type {
                padding-right: 4rem;
                min-width: calc(43% + 4rem);
            }
        }
    }

    @media (max-width: $break-phablet) {
        &__container {
            position: relative;
            padding: 3.2rem 0 3.2rem 1.6rem;
        }

        &__header {
            margin-bottom: 2.4rem;
        }

        &__header-title {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }

        &__slide-container {
            overflow-x: auto;
        }

        &__item {
            min-width: 83%;

            &:last-of-type {
                padding-right: 1.6rem;
                min-width: calc(83% + 1.6rem);
            }
        }

        &__item-meta {
            padding-top: 1.6rem;
        }

        &__meta-city {
            font-size: 4.8rem;
            line-height: 4.1rem;
        }

        &__meta-country {
            margin-top: 0.4rem;
        }
    }
}
