.standings-modal {
    $this: &;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    background: $svns-black;
    padding: 7rem 1rem 3rem;
    display: flex;
    flex-flow: column nowrap;
    transform: scale(0.5);
    filter: blur(5rem);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, transform 0.5s, visibility 0.5s, filter 0.5s;

    &.isVisible {
        opacity: 1;
        transform: translateY(0);
        filter: blur(0);
        visibility: visible;
    }

    &-header {
        width: 100%;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        left: 0;
        border-bottom: 0.1rem solid $svns-white;
        color: $svns-white;
        text-align: right;
    }

    &-titles {
        position: relative;
    }

    &-close {
        cursor: pointer;
        padding: 2rem 5rem;
        text-transform: uppercase;
        display: inline-block;
        transition: padding 0.3s;
        font-size: 2rem;

        &::after,
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: 3rem;
            width: 0.2rem;
            height: 2rem;
            background: $svns-white;
            transform-origin: top;
            transition: transform 0.3s;
            transform: rotate(-45deg) translateY(-50%);
        }

        &::after {
            transform: rotate(45deg) translateY(-50%);
        }
        @include hover {
            padding-right: 5.5rem;

            &::after {
                transform: rotate(-45deg) translateY(-50%);
            }

            &::before {
                transform: rotate(45deg) translateY(-50%);
            }
        }
    }

    &-image-container {
        flex: 1;
        display: none;
    }

    &-image-mobile {
        display: flex;
        margin-top: 2rem;
    }

    &-inner {
        display: flex;
        width: 100%;
        gap: 2rem;
        scroll-behavior: smooth;
        overscroll-behavior: contain;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-content {
        flex: 1;
        height: auto;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        justify-content: space-between;
    }

    &-title {
        text-transform: uppercase;
        font-size: 4.2rem;
        color: $svns-white;
        line-height: 1;
    }

    &-body {
        position: relative;
        width: 100%;
        padding-bottom: 2rem;
        display: flex;
        flex-flow: column;

        & p,
        & ul li {
            color: $svns-white;
            @include font-dmsans-regular;
            margin-top: 2rem;
            font-size: 1.6rem;
        }
    }

    @media (min-width: $break-desktop) {
        flex-flow: row nowrap;
        padding: 8.5rem 2rem 2rem;

        &-image-container {
            flex: 1;
            display: flex;
        }

        &-image-mobile {
            display: none;
        }

        &-title {
            font-size: 8.4rem;
        }

        &-body {
            width: 100%;

            & p,
            & ul li {
                margin-top: 4rem;
                font-size: 1.8rem;
            }
        }
    }
}
