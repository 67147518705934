.cookie-article {
    background: $svns-white;
    padding: 2rem 0 4rem;
    max-width: 100rem;
    margin: auto;

    .article__body {
        p,
        ul,
        li {
            color: $svns-black;
            @include font-dmsans-regular;
            padding-left: 0;
            min-width: 100%;
        }

        ul {
            margin: 1rem auto;
        }

        a {
            color: $svns-blue;
        }

        strong {
            color: $grey-dark;
            text-transform: uppercase;
        }

        span {
            color: $grey-dark;
        }

        table {
            max-width: 100rem;

            td {
                padding: 0.8rem;
                min-width: 7rem;
            }

            td,
            p {
                word-break: break-all;
            }
        }
    }

    @media (max-width: $break-phablet) {
        .article__body {
            padding: 0;

            td,
            p {
                font-size: 12px !important;
            }
        }
    }
}
