// Brightcove controls
$bc-progress-bar-height: 0.5rem;
$bc-control-bar-height: 4rem;
$bc-control-bar-float-offset: 1rem;

/* stylelint-disable */
.bc-video-player {
    $this: &;
    position: relative;
    @include font-regular;

    .vjs-big-play-button {
        transition: color 0.3s ease;
    }

    .vjs-icon-placeholder {
        transition: color 0.3s ease;
    }

    @include hover {
        .vjs-big-play-button {
            background-color: $svns-yellow;
        }

        .vjs-icon-placeholder {
            color: $svns-black;
        }
    }

    .vjs-tech {
        position: relative;
    }

    .vjs-poster {
        background-size: cover; // fixes bug where black background shows 1px at edge of poster
    }

    .video-js {
        height: auto;
        width: 100%;

        // Icon Placeholder helper
        @mixin vjs-icon-placeholder($image, $size) {
            .vjs-icon-placeholder::before {
                background-image: url($image);
                background-position: center;
                background-repeat: no-repeat;
                background-size: $size;
                content: '';
            }
        }

        &.vjs-has-started {
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 50%;
                width: 100%;
                background-image: linear-gradient(
                    to top,
                    rgba(#000, 0.8),
                    rgba(#000, 0)
                );
                pointer-events: none; // ensure it doesn't obscure click listener on player itself
                transition-property: opacity;
                transition-duration: 0.2s;
            }

            &:not( .vjs-has-started ) #vjs-big-pause-button {
                display: none !important;
            }

            #vjs-back-button,
            #vjs-forward-button {
                transform: translateY(-50%);
                opacity: 0.6;
                visibility: visible;
                transition: opacity 0.25s, visibility 0.25s, transform 0.25s;

                @include hover {
                    opacity: 1;
                }
            }
        }

        &.vjs-playing.vjs-user-inactive.vjs-has-started.vjs-fullscreen::after {
            opacity: 0;
        }

        &.vjs-playing,
        &.vjs-has-started {
            & + #{$this}__duration {
                display: none;
            }
        }

        .vjs-progress-control:hover {
            height: 1em;
            top: -1em;
        }

        // RWC2021 Big Pause Button
        #vjs-big-pause-button {
            width: 11.2rem;
            height: 7.6rem;
            border-radius: 3.8rem;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: opacity 0.1s, transform 0.5s ease;
            cursor: pointer;
            opacity: 0;
            z-index: 0;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url('../i/svg-files/elements/icons/pause.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 3rem auto;
                cursor: pointer;
            }
        }

        .vjs-big-play-button {
            @include vjs-icon-placeholder(
                '../i/svg-files/elements/icons/play.svg',
                2rem auto
            );
            background-color: $svns-yellow;
            width: 10rem;
            height: 10rem;
            border-radius: 50%;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: transform 300ms ease;
            cursor: pointer;
            z-index: 1;

            .vjs-icon-placeholder::before {
                background-size: 10rem auto;
                transition: transform 300ms ease;
            }
        }

        &.vjs-paused.vjs-has-started {
            .vjs-big-play-button {
                background-color: transparent;
                @include vjs-icon-placeholder(
                    '../i/svg-files/elements/icons/play-white.svg',
                    10rem auto
                );
            }

            #vjs-back-button,
            #vjs-forward-button {
                display: none;
            }
        }

        &.vjs-has-started {
            #vjs-big-pause-button {
                background-color: transparent;

                &:before {
                    content: '';
                    background-image: url('../i/svg-files/elements/icons/pause.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 3.5rem auto;
                }
            }
        }

        &.vjs-ended.vjs-paused.vjs-has-started {
            .vjs-big-play-button {
                .vjs-icon-placeholder {
                    &::before {
                        content: '';
                        width: 100%;
                        height: calc(100% + 1rem);
                        background: url('../i/svg-files/elements/icons/bc-play-again.svg')
                            center/cover no-repeat;
                    }
                }
            }

            #vjs-back-button,
            #vjs-forward-button {
                display: none;
            }
        }

        @include hover {
            &.vjs-playing.vjs-has-started #vjs-big-pause-button {
                opacity: 1;
                transform: translate(-50%, -50%) scale(1.1);
                z-index: 2;
            }

            .vjs-big-play-button {
                transform: translate(-50%, -50%) scale(1.1);
                transition: transform 300ms ease;

                .vjs-icon-placeholder::before {
                    transform: scale(1.1);
                }
            }
        }

        &.vjs-paused.vjs-has-started {
            .vjs-big-play-button {
                background-color: transparent;
                display: block;

                .vjs-icon-placeholder::before {
                    background-image: url('../i/svg-files/elements/icons/play-white.svg');
                }
            }

            #vjs-back-button,
            #vjs-forward-button {
                display: none;
            }
        }

        &.vjs-ended.vjs-paused.vjs-has-started {
            #vjs-back-button,
            #vjs-forward-button {
                display: none;
            }
        }

        .vjs-dock-shelf,
        .vjs-dock-text {
            display: none;
        }

        // Controls Bar
        .vjs-control-bar {
            background-color: transparent; // Override base styling to make it transparent
            height: $bc-control-bar-height;
            width: auto;
            left: $bc-control-bar-float-offset;
            right: $bc-control-bar-float-offset;
            z-index: 1; // ensure control bar sits above background gradient when hovering
            transform: translateY(0);
        }

        &.not-hover {
            // Hide transport bar when not hovering (lower by 100% of height + progress bar height)
            .vjs-control-bar {
                transform: translateY(100%) translateY($bc-progress-bar-height) !important;
            }

            // hide forward/back buttons when not hovering
            #vjs-back-button,
            #vjs-forward-button {
                transform: translateY(-50%) translateY(0);
                opacity: 0;
                visibility: hidden;
            }

            #vjs-big-pause-button {
                display: none;
            }

            &.vjs-has-started::after {
                opacity: 0;
            }
        }

        &.vjs-user-active {
            &.not-hover {
                &.vjs-has-started::after {
                    transition-duration: 1.2s;
                    transition-timing-function: ease-out;
                }
            }
        }

        .vjs-picture-in-picture-control {
            margin-left: auto;
        }

        // Progress Bar
        .vjs-progress-holder {
            background-color: rgba($white, 0.5);
        }

        .vjs-load-progress,
        .vjs-load-progress > div {
            background: $white;
        }

        .vjs-play-progress {
            background-color: $svns-yellow;
        }

        .vjs-time-tooltip {
            border-radius: 0;
            background-color: $svns-yellow;
            color: $svns-black;
            font-size: 1.2rem;
            padding: 0.6rem 0.7rem;
        }

        .vjs-thumbnail-display {
            border: 3px solid $svns-yellow;
        }

        .vjs-progress-control:hover {
            .vjs-time-tooltip {
                font-size: 1.2rem;
                top: -3.1rem; // override distance of tooltip from progress bar
            }

            .vjs-thumbnail-display {
                bottom: 5.1rem;
            }
        }

        .vjs-volume-bar {
            background-color: $white;
            margin: 0 0.6rem 0 0.2rem;

            &.vjs-slider-horizontal {
                width: 100%;
            }
        }

        .vjs-volume-level {
            background-color: $svns-yellow;
        }

        // TEXT FOR TIME ELAPSED, DIVIDER AND TOTAL DURATION
        .vjs-current-time,
        .vjs-time-divider,
        .vjs-duration {
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            @include font-weight('bold');
        }

        // Time Control
        .vjs-time-control {
            padding: 0; // override padding which separates numbers too much
        }

        .vjs-time-divider {
            min-width: 1.5rem; // reduce width of divider element to keep numbers closer together
        }

        // Control Spacer (Brand)
        .vjs-custom-control-spacer {
            bottom: 0;
            height: 100%;
            pointer-events: none;
            position: absolute;
            width: 100%;
        }

        .vjs-button {
            & > .vjs-icon-placeholder::before {
                content: none;
            }

            &.vjs-play-control {
                width: 4rem;
                @include retina-bg-cover('../i/elements/bc-play', 'png');

                &.vjs-playing {
                    @include retina-bg-cover('../i/elements/bc-pause', 'png');
                }
            }

            &.vjs-mute-control {
                width: 4rem;

                &.vjs-vol-3 {
                    @include retina-bg-cover(
                        '../i/elements/bc-audio-full',
                        'png'
                    );
                }

                &.vjs-vol-2 {
                    @include retina-bg-cover(
                        '../i/elements/bc-audio-mid',
                        'png'
                    );
                }

                &.vjs-vol-1 {
                    @include retina-bg-cover(
                        '../i/elements/bc-audio-low',
                        'png'
                    );
                }

                &.vjs-vol-0 {
                    @include retina-bg-cover(
                        '../i/elements/bc-audio-mute',
                        'png'
                    );
                }
            }

            &.vjs-picture-in-picture-control {
                width: 5rem;
                @include retina-bg-cover('../i/elements/bc-pip-on', 'png');
            }

            &.vjs-fullscreen-control {
                width: 5rem;
                @include retina-bg-cover(
                    '../i/elements/bc-fullscreen-enter',
                    'png'
                );
            }
        }

        &.vjs-picture-in-picture {
            .vjs-button {
                &.vjs-picture-in-picture-control {
                    @include retina-bg-cover('../i/elements/bc-pip-off', 'png');
                }
            }
        }

        &.vjs-fullscreen {
            .vjs-button {
                &.vjs-fullscreen-control {
                    @include retina-bg-cover(
                        '../i/elements/bc-fullscreen-exit',
                        'png'
                    );
                }
            }
        }

        // Vertically center volume bar
        .vjs-volume-control.vjs-control.vjs-volume-horizontal {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .vjs-volume-panel.vjs-hover {
            .vjs-volume-control.vjs-volume-horizontal {
                width: 6rem;
                height: 100%;
            }
        }
    }

    #vjs-back-button,
    #vjs-forward-button {
        position: absolute;
        top: 50%;
        width: 4.5rem;
        height: 5.2rem;
        z-index: 1;
        cursor: pointer;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.25s, visibility 0.25s, transform 0.25s;

        @include hover {
            opacity: 1;
        }
    }

    &.vjs-paused .vjs-big-play-button {
        display: block;
    }

    button.vjs-subs-caps-button {
        background-image: url('../i/svg-files/elements/icons/bc-closedcaptions.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    #vjs-back-button,
    #vjs-forward-button {
        position: absolute;
        top: 50%;
        width: 4.5rem;
        height: 5.2rem;
        z-index: 1;
        cursor: pointer;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.25s, visibility 0.25s, transform 0.25s;

        @include hover {
            opacity: 1;
        }
    }

    #vjs-back-button {
        left: 25%;
        background-image: url('../i/svg-files/elements/icons/bc-rewind.svg');
    }

    #vjs-forward-button {
        right: 25%;
        background-image: url('../i/svg-files/elements/icons/bc-fastforward.svg');
    }

    // Set gradient overlay to 100% height so white forward/back buttons are clearly visible
    .video-js.vjs-has-started::after {
        height: 100% !important;
    }

    // Duration tag element optionally added as part of the videoPlayer macro
    &__duration {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        padding: 0.3rem 1.2rem;
        color: $svns-black;
        background-color: $svns-black;
        font-size: 1.2rem;
        line-height: 1.4;

        .card__meta-label {
            display: none;
        }
    }

    @media (max-width: $break-tablet) {
        .video-js {
            &.vjs-playing.vjs-has-started #vjs-big-pause-button {
                z-index: 1;
                opacity: 0.5;
            }

            &.vjs-has-started.vjs-playing.vjs-user-inactive {
                .vjs-control-bar:not(.vjs-focus-within):not(
                        .vjs-control-bar-visible
                    ) {
                    transform: translateY(6em);
                }

                #vjs-big-pause-button,
                #vjs-back-button,
                #vjs-forward-button {
                    opacity: 0;
                }
            }

            .vjs-progress-control {
                height: 0.6rem;
                top: -0.6rem;

                @include hover {
                    height: 0.6rem;
                    top: -0.6rem;
                }
            }

            .vjs-play-control {
                display: none;
            }

            .vjs-big-play-button,
            #vjs-big-pause-button {
                transform: translate(-50%, -50%) scale(0.7);
            }

            &.vjs-has-started {
                #vjs-back-button,
                #vjs-forward-button {
                    transform: translateY(-50%) scale(0.7) !important;
                }
            }
        }
    }

    @media (max-width: $break-mobile) {
        .video-js {
            // Control Spacer (removes Brand)
            .vjs-custom-control-spacer {
                position: static;
                background: none;

                &::before,
                &::after {
                    content: '';
                }
            }
        }
    }
}
