$arrowBlack: '../i/svg-files/elements/icons/arrow-right-black.svg';

.hospitality-header {
    $this: &;
    padding: 2rem 0;
    width: 100%;
    margin: 0 auto;

    &__bg {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 200rem;
        height: 200rem;
        stroke: none;
        @include eventColors-secondary(fill);
        z-index: 0;
    }

    &__title {
        font-size: 4.2rem;
        text-transform: uppercase;
        line-height: 1;
        padding: 2rem 0;
        color: $svns-black;
    }

    &__container {
        display: flex;
        flex-flow: nowrap column;
        padding: 0 2rem;
        background: transparent;
        position: relative;
        z-index: 1;
    }

    &_ctas {
        display: flex;
        flex-flow: row nowrap;
        position: relative;
    }

    &_link {
        text-align: left;
        text-transform: uppercase;
        position: relative;
        padding: 0.5rem 0 0.5rem 2rem;
        max-width: 20rem;
        font-size: 1.4rem;
        color: $svns-black;
        text-decoration: none;
        flex: 0 0 auto;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(-180deg);
            width: 1.4rem;
            height: 1.2rem;
            margin-left: -1.5em;
            background: url($arrowBlack) center/cover no-repeat;
            transition: 0.8s ease-in-out background-position;
        }
        @include hover {
            &::before {
                animation: scrollArrow 0.8s infinite 0.3s linear;
            }
        }
    }

    &_list {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        padding: 0.3rem 0;
        scroll-behavior: smooth;
        overscroll-behavior: contain;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        &--item {
            flex: 0 0 auto;
            text-align: center;
            text-transform: uppercase;
            border-radius: 10rem;
            margin: 0 0 0 .4rem;
            padding: 1.2rem 2rem;
            color: $svns-white;
            background: $svns-black;
            font-size: 1.4rem;
            cursor: pointer;

            &.isActive {
                background: $svns-white;
                color: $svns-black;
            }

            &:last-of-type {
                margin-right: .4rem;
            }
        }
    }

    @media (min-width: $break-tablet) {
        padding: 3rem 0 0;

        &__container {
            padding: 2rem;
        }

        &__title {
            font-size: 8.2rem;
        }

        &_ctas {
            display: none;
        }
    }
}
