.loader {
    position: absolute;
    top: 15rem;
    width: 15rem;
    height: 2rem;
    transform: translateX(-50%);
    left: 50%;
    z-index: 1;

    &__progress {
        background: $svns-white;
        height: 100%;
        width: 0%;
        animation: loaderExpand 2s infinite linear;
    }

    @media (max-width: $break-tablet) {
        width: 10rem;
    }
}
