.footer-app-nav {
    $this: &;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    &--title {
        color: $svns-white;
        text-transform: uppercase;
        font-size: 2rem;
        text-align: center;
    }

    &__list {
        display: flex;
        justify-content: center;
        padding: 1rem 0;
        gap: 2rem;

        &--img {
            width: 14rem;
            height: 5rem;
            stroke: none;
        }
    }

    @media (min-width: $break-tablet) {
        padding-bottom: 0;

        &--title {
            font-size: 2.4rem;
        }

        &__list {
            justify-content: flex-end;

            &--img {
                transition: transform 0.3s;

                &:hover {
                    transform: translate(0, 0.2rem);
                }
            }
        }
    }
}
