$arrowWhite: '../i/svg-files/elements/icons/arrow-right-white.svg';

.hospitality-info {
    $this: &;
    position: relative;
    z-index: 2;
    margin: $layout-margin-mob;
    margin-top: -1rem;
    display: none;

    &.isVisible {
        display: block;
    }

    &-container {
        display: flex;
        flex-flow: column nowrap;
        overflow: visible;

        &.toLeft {
            #{$this}-content {
                order: 2;
            }
        }
    }

    &-content {
        flex: 0 0 50%;
        padding: 2rem;
        order: 1;
        margin-top: 0;

        &__summary {
            padding-top: 0;
            padding-bottom: $margin;
            position: relative;
            overflow: hidden;

            .hospitality-tape {
                display: none;
            }

            &.not-available {
                border-bottom: 0.1rem solid $svns-white;

                .hospitality-tape {
                    display: block;
                }
                #{$this}-cta--buy {
                    pointer-events: none;
                    opacity: 0.4;
                }
                #{$this}-body {
                    padding-right: 5rem;
                }
            }
        }
    }

    &-main--title {
        color: $svns-white;
        line-height: 1;
        font-size: 8.2rem;
        text-transform: uppercase;
        display: none;
    }

    &-cta--faq {
        position: relative;
        padding-left: 3rem;
        display: inline-flex;
        margin-top: $margin;
        text-transform: uppercase;
        text-decoration: none;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            width: 1.4rem;
            height: 1.6rem;
            margin-left: 0.4rem;
            background: url($arrowWhite) center/cover no-repeat;
        }

        &::after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -0.2rem;
            transform: translateX(-50%);
            width: 0;
            height: 0.1rem;
            margin-left: 0.4rem;
            background: $svns-white;
            transition: width 0.3s;
        }
        @include hover {
            &::before {
                animation: scrollArrow 0.8s infinite linear;
            }

            &::after {
                width: 100%;
            }
        }
    }

    &-cta--buy {
        text-align: center;
        text-transform: uppercase;
        border-radius: 10rem;
        padding: 1.4rem 2rem;
        font-size: 1.4rem;
        border: 0.1rem solid $svns-white;
        background: $svns-white;
        color: $svns-black;
        text-decoration: none;
        position: relative;
        transition: background 0.3s, color 0.3s;
        display: block;
        margin-top: $margin;

        &::after {
            content: '';
            position: relative;
            display: inline-block;
            top: 0;
            left: 0.5rem;
            width: 1.4rem;
            height: 1rem;
            margin-left: 0.4rem;
            background: url($arrowBlack) center/cover no-repeat;
            transition: 0.8s ease-in-out background-position;
        }
        @include hover {
            background: $svns-black;
            color: $svns-white;

            &::after {
                animation: scrollArrow 0.8s infinite 0.3s linear;
                background: url($arrowWhite) center/cover no-repeat;
            }
        }
    }

    &-title {
        color: $svns-white;
        line-height: 1;
        font-size: 4.8rem;
        text-transform: uppercase;
    }

    &-price {
        margin-top: $margin;
        text-align: right;
        font-size: 3.2rem;
        line-height: 1;
        text-transform: uppercase;
        color: $svns-white;

        &--small {
            font-size: 1.8rem;
            position: relative;
            padding: 0 0.3rem;

            &.top {
                bottom: 1rem;
            }
        }
    }

    &-summary,
    &-body {
        position: relative;
        color: $svns-white;
        margin-top: $margin;
    }

    &-summary,
    &-body p,
    &-body ul,
    &-body div {
        color: $svns-white;
        @include font-dmsans-regular;
        font-size: 1.8rem;
    }

    &-body {
        & ul {
            margin-top: $margin;
            display: flex;
            flex-flow: nowrap column;

            & li {
                position: relative;
                padding: 1rem 0 1rem 2rem;

                &::before {
                    content: '';
                    position: absolute;
                    width: 0.8rem;
                    height: 0.8rem;
                    background: $svns-white;
                    top: 2rem;
                    left: 0;
                }
            }
        }
    }
    @media (min-width: $break-desktop) {
        margin: $layout-margin;
        display: block;

        &-main--title {
            display: block;
        }

        &-cta--buy {
            font-size: 2.4rem;

            &::after {
                width: 1.8rem;
                height: 1.6rem;
                left: 1rem;
            }
        }

        &-container {
            flex-flow: row nowrap;
        }

        &-content {
            order: 0;
            border: none;
        }
    }
}
