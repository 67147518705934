$arrowBlack: '../i/svg-files/elements/icons/arrow-right-black.svg';
$arrowWhite: '../i/svg-files/elements/icons/arrow-right-white.svg';
$margin: 3rem;

.promo-grid-enhanced {
    $this: &;
    position: relative;
    z-index: 2;
    margin: $layout-margin-mob;

    &--container {
        position: relative;
    }

    &--images {
        position: relative;
    }

    &--bg {
        width: 100rem;
        height: 100rem;
        top: -25rem;
        stroke: none;
        position: absolute;
        z-index: 0;
        @include eventColors(background);
        @include eventColors-secondary(fill);
        transform: translateY(0);
        transition: opacity 0.8s, transform 0.8s;

        &.isHidden {
            opacity: 0;
            transform: translateY(-10rem);
        }
    }

    &--content {
        position: relative;
        display: flex;
        z-index: 3;
        flex-flow: column nowrap;
        padding-bottom: 5rem;
    }

    &--nav,
    &--list {
        flex: 1;
    }

    &--nav {
        display: flex;
        justify-content: space-between;
        flex-flow: column nowrap;
        padding: 2rem;
        flex: 0 0 46%;
    }

    &--nav__controls,
    &--pagination {
        position: relative;
        display: flex;
        width: 100%;
    }

    &--pagination {
        justify-content: space-between;
        align-items: baseline;
        line-height: 1;
        font-size: 3rem;
        text-transform: uppercase;

        &-index {
            font-size: 195%;
        }
    }

    &--nav__controls {
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        max-width: calc(100% - 4rem);
        bottom: 1rem;
    }

    &--nav__button {
        border: none;
        background-color: transparent;
        font-size: 4rem;
        text-indent: -999rem;
        transition: 0.3s ease opacity, 0.3s ease background-color;
        color: $svns-black;
        position: relative;
        cursor: pointer;

        &.no-hover-state {
            pointer-events: none;
        }

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 3.5rem;
            height: 3rem;
            background: url($arrowBlack) center/cover no-repeat;
            transition: 0.8s ease-in-out background-position;
        }

        &--previous {
            opacity: 0.4;
            padding-left: 3rem;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
                width: 3.5rem;
                height: 3rem;
                margin-left: 0;
                background: url($arrowBlack) center/cover no-repeat;
                transition: 0.8s ease-in-out background-position;
            }

            &::after {
                display: none;
            }
        }

        &--next {
            padding-right: 3rem;
        }

        &:not(.no-hover-state) {
            @include hover {
                &::before {
                    animation: scrollArrow 0.8s infinite linear;
                }

                &::after {
                    animation: scrollArrow 0.8s infinite linear;
                }
            }
        }
    }

    &--carousel {
        flex: 1;
        width: 100%;
        overflow: hidden;
    }

    &--cards {
        position: relative;
        display: flex;
        padding: 0 1.5rem 2rem;
        gap: 1rem;
        transition: transform 0.8s;
        border: none;
        flex-flow: row nowrap;
        min-width: 1000rem;
        width: 100%;

        &__card {
            flex: 1 0 auto;
            max-width: calc(100vw - 4rem);
            background: $svns-black;
            color: $svns-white;
            text-transform: uppercase;
            padding: 2rem;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            transform: scale(0.9);
            transition: transform 0.5s;
            pointer-events: none;

            & > * {
                opacity: 0.2;
                transition: opacity 0.4s;
            }

            &.isActive {
                transform: scale(1);
                opacity: 1;
                pointer-events: all;

                & > * {
                    opacity: 1;
                }

                @include hover {
                    transform: scale(0.99) translateY(0.5rem);
                }
            }
        }
    }

    &--title-container {
        width: 100%;
    }

    &--title {
        display: flex;
        flex-flow: column nowrap;
        font-size: 3.2rem;
        line-height: 1;
        color: $svns-white;

        &__location,
        &__city,
        &__date {
            display: block;
            margin-bottom: 2rem;
        }

        &__location {
            font-size: 1.4rem;
        }

        &__city {
            text-align: left;
        }

        &__date {
            text-align: right;
        }
    }

    &--ctas {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        margin-top: $margin;
        padding: 0;
        gap: 1rem;
    }

    &__cta {
        text-align: center;
        text-transform: uppercase;
        border-radius: 10rem;
        padding: 1.2rem 2rem;
        font-size: 1.6rem;
        border: 0.1rem solid $svns-white;
        background: $svns-black;
        color: $svns-white;
        text-decoration: none;
        flex: 1;
        max-width: 100%;
        position: relative;
        transition: background 0.3s, color 0.3s;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 1.4rem;
            height: 1.2rem;
            margin-left: 0.8rem;
            background: url($arrowWhite) center/cover no-repeat;
            transition: 0.8s ease-in-out background-position;
        }
        @include hover {
            background: $svns-white;
            color: $svns-black;

            &::after {
                animation: scrollArrow 0.8s infinite 0.3s linear;
                background: url($arrowBlack) center/cover no-repeat;
            }
        }
    }

    @media (min-width: $break-desktop) {
        margin: $layout-margin;

        &--bg {
            width: 200rem;
            height: 200rem;
        }

        &--content {
            flex-flow: row nowrap;
            padding: 0;
        }

        &--nav__controls {
            left: 0%;
            transform: translateX(0);
            max-width: none;
            bottom: 0;
            position: relative;
        }

        &--pagination {
            max-width: 45rem;
            font-size: 8.5rem;
        }

        &--nav__button {
            text-indent: 0;
            font-size: 8rem;

            &::after {
                width: 6rem;
                height: 5.5rem;
            }

            &--previous {
                padding-left: 8rem;

                &::before {
                    width: 6rem;
                    height: 5.5rem;
                }
            }

            &--next {
                padding-right: 8rem;
            }

            &:not(.no-hover-state) {
                @include hover {
                    &::before {
                        animation: scrollArrow 0.8s infinite linear;
                    }

                    &::after {
                        animation: scrollArrow 0.8s infinite linear;
                    }
                }
            }
        }

        &--cards {
            padding: 2rem;
            flex-flow: row nowrap;
            min-width: 1000rem;
            transition: transform 0.8s;

            &__card {
                min-width: 58rem;
                max-width: 58rem;
                min-height: 60rem;
            }
        }

        &--title {
            font-size: 6.5rem;

            &__location,
            &__city,
            &__date {
                margin-bottom: 4rem;
            }

            &__location {
                font-size: 3.2rem;
            }
        }

        &__cta {
            font-size: 2.4rem;
            padding: 1.6rem 2rem;

            &::after {
                width: 2rem;
                height: 1.8rem;
            }
        }
    }
}
