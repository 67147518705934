.content-hero {
    $this: &;
    position: relative;
    overflow: hidden;
    @include eventColors( background );

    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 250rem;
        height: 250rem;
        stroke: none;
        @include eventColors-secondary(fill);
        opacity: 0;
        animation: opacityFadeIn .8s forwards;
        animation-timing-function: ease-in-out;
        left: 115rem;
        top: -70rem;
    }

    .pill-shaped-button {
        background: $svns-black;
        border: none;
        margin-bottom: -12rem;
        animation: slideUp .2s forwards 1.4s;
        animation-timing-function: ease-out;
        height: auto;
        padding: 1.6rem 0;
        transition: background .3s, border .3s;

        &__text {
            color: $svns-white;
            font-size: 1.6rem;
            line-height: 1;
            transition: color .3s;
        }

        @include hover {
            background: $svns-white;

            .pill-shaped-button__container {
                min-width: unset !important;
                border: none;
            }

            .pill-shaped-button__text {
                color: $svns-black;
                @include font-weight( 'bold' );
            }
        }
    }

    &__container {
        position: relative;
        z-index: 1;    
        display: flex;
        max-height: 64rem;
    }

    &__info {
        position: relative;
        width: 43%;
        min-width: 43rem;
        min-height: 64rem;
        z-index: 1;
        padding: 2.4rem;

        display: flex;
        flex-direction: column;
    }

    &__text-container {
        flex: 1;
    }

    &__title {
        color: $black;
        font-size: 8.4rem;
        line-height: 8.4rem;
        text-transform: uppercase;
        width: 100%;
        margin-left: -100vw;
        animation: slideInFromLeft .8s forwards .6s;
        animation-timing-function: ease-in-out;
    }

    &__summary {
        color: $black;
        font-size: 1.8rem;
        line-height: 2.7rem;
        margin: 2.4rem 0;
        width: 100%;
        margin-left: -100vw;
        animation: slideInFromLeft .8s forwards .8s;
        animation-timing-function: ease-in-out;
    }

    &__cta {
        @include button;

        &--mob {
            position: absolute;
            bottom: 0;
            z-index: 1;
            transform: translate(-50%);
            left: 50%;
            margin-bottom: 2.4rem;
        }
    }

    &__image-container {
        flex: 1;
        position: relative; 
        overflow: hidden;
        opacity: 0;
        animation: opacityFadeIn 2s forwards 1.2s;
        animation-timing-function: ease-out;

        &.full-bleed {
            margin-top: 7rem;
        }
        
        .bc-video-player {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            border-radius: 0;

            .video-js {
                min-height: 64rem;
                min-width: 57vw;
                
                #vjs-big-pause-button,
                .vjs-big-play-button {
                    height: 4.8rem;
                    width: 4.8rem;
                    background-color: $svns-black !important;
                    transform: scale(.5) !important;
                    padding: 3.6rem;
                    top: unset;
                    left: unset;
                    right: 1.6rem;
                    bottom: 1.6rem;
                    opacity: 1 !important;

                    .vjs-icon-placeholder::before {
                        background-image: url(../i/svg-files/elements/icons/play-white.svg);
                    }
                }

                .vjs-big-play-button .vjs-icon-placeholder::before {
                    background-image: url(../i/svg-files/elements/icons/play-white.svg);
                }
                
                .vjs-tech {
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    left: 50%;
                    min-height: 64rem;
                    min-width: 57vw;
                    width: auto;
                    height: auto;
                }

                &.not-hover #vjs-big-pause-button {
                    display: block;
                    opacity: 1 !important;
                    z-index: 2;
                }

                &.vjs-paused #vjs-big-pause-button {
                    display: none;
                }

                .vjs-control-bar,
                .vjs-dock-text,
                #vjs-back-button,
                #vjs-forward-button {
                    display: none;
                }
                
                &.vjs-has-started::after {
                    content: none;
                }
            }
        }
    }

    &__picture-image {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        min-width: 100%;
    }

    &__image {
        max-width: none;
        min-width: 105%;
    }

    @media ( min-width: $break-wide ) {

        &__image-container {
    
            &.full-bleed {
    
                #{$this}__picture-image {
                    max-height: 100%;
                    transform: none;
                    left: 0;
                    top: 0;
                    min-width: auto;
                }
            }
        }
    }

    @media ( max-width: $break-tablet ) {

        &__bg {
            height: 200rem;
            width: 200rem;
            transform: translateX(-50%) rotate(42deg);   
            left: 38rem;
            top: -36rem;
        }

        &__container {
            flex-direction: column;
            max-height: none;
        }

        &__text {
            font-size: 1.4rem;
            line-height: 1;
        }

        &__info {
            max-width: 100%;
            min-width: auto;
            min-height: auto;    
            padding: 0;
            margin-bottom: 1.6rem;
            width: 100%;
        }

        .pill-shaped-button { 
            padding: 1.5rem 0;

            &__text {
                font-size: 1.4rem;
                line-height: 1;
            }
        }

        &__text-container {
            flex: none;
        }

        &__container {
            padding: 1.6rem;
            min-height: 40rem;
        }

        &__title {
            animation: slideInFromLeft .4s forwards .4s;
            width: 100%;
            font-size: 4rem;
            line-height: 4rem;
        }

        &__summary {
            font-size: 1.6rem;
            line-height: 2rem;
            padding-right: 2rem;
            animation: slideInFromLeft .4s forwards .6s;
            width: 100%;
        }

        &__image-container {
            margin-top: 1.6rem;    
            padding-top: 75%;
            margin-top: 0;    
            animation: opacityFadeIn 2s forwards 1.6s;   
            
            &.full-bleed {
                margin: 0 0 -1.6rem;
                padding-top: 0;
                
                #{$this}__picture-image {
                    position: relative;
                    transform: none;
                    left: 0;
                }
                
                #{$this}__image {
                    position: relative;
                    transform: translateX(-50%);
                    left: 50%;
                }
            }
            
            .bc-video-player {
    
                .video-js {
                    min-height: calc(75vw - 3.2rem);
                    min-width: calc(100vw - 3.2rem);
                    
                    .vjs-tech {
                        min-height: auto;
                        width: 130vw;
                        height: calc(75vw - 3.2rem);
                    }
                
                    #vjs-big-pause-button,
                    .vjs-big-play-button {
                        right: 1.6rem;
                        bottom: 1.6rem;
                    }
                }
            }
        }
    }

    @media ( max-width: $break-phablet ) {

        &__info {
            max-width: 100%;
        }

        &__summary {
            margin: 1.6rem 0 1.6rem -100vw;
        }
    }
}

