@mixin itemActive() {
    .filters {
        &__primary {
            background: $svns-white;

            .pill-shaped-button {
                &__text {
                    color: $svns-black;
                }

                &__carat {
                    background-image: url('../i/svg-files/elements/icons/carat-black.svg');
                    transform: rotate(180deg);
                    margin-top: 0.2rem;
                    color: $svns-black;
                }
            }
        }

        &__secondary {
            max-height: 40rem;
            animation: opacityFadeIn 0.4s forwards 0.2s;
            animation-timing-function: ease-in-out;
        }
    }
}

.filters {
    $this: &;
    cursor: pointer;
    display: flex;
    padding-top: 1.6rem;

    //Rules to be switched once video stab is rendered.
    //margin-bottom: -1.6rem;
    //width: 100%;
    width: calc(100% + 1.6rem);
    margin: 0 -0.8rem -1.6rem;

    /* @media (min-width: $break-tablet) {

        &:not(:hover) {

            #{$this}__item-container {

                &.isActive {
                    @include itemActive;
                }
            }
        }
    } */

    &__item-container {
        flex: 1;
        border-top-left-radius: 3rem;
        border-top-right-radius: 3rem;
        padding: 0.8rem;

        @include hover {
            @include itemActive;
        }

        &.isActive {
            @include itemActive;
        }

        &.events {
            /* Use then when video tab is renderd on prod
            #{$this}__secondary {
                width: calc( 300% + 4.4rem );
                margin-left: calc( -100% - ( 4.4rem / 2 ));
            } */

            #{$this}__secondary {
                width: calc(200% + 2.4rem);
                margin-left: calc(-100% - 2rem);
            }
        }

        &.type {
            // Hiding until video page is built
            display: none;

            #{$this}__secondary {
                width: calc(300% + 4.4rem);
                margin-left: calc(-200% - 4rem);
                justify-content: flex-end;
            }
        }
    }

    &__secondary {
        display: flex;
        flex-wrap: wrap;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        order: 4;
        transition: 0.8s ease-in-out max-height;

        .pill-shaped-button {
            width: auto;
            margin: 1.6rem 0.4rem 0;

            @include hover {
                background: $svns-white;

                .pill-shaped-button__text {
                    color: $svns-black;
                }
            }

            &.isSelected {
                background: $svns-white;

                .pill-shaped-button__text {
                    color: $svns-black;
                }
            }
        }
    }

    .pill-shaped-button {
        white-space: nowrap;
        height: 7rem;
        padding: 0 2.4rem;
        transition: 0.3s background-color ease;

        &__text {
            font-size: 2.4rem;
            line-height: 2.4rem;
            margin-right: 0;
        }
    }

    @media (max-width: $break-tablet) {
        &__item-container {
            &.events {
                #{$this}__secondary {
                    //Rules to be switched once video stab is rendered.
                    //width: calc( 300% + 4.4rem );
                    //margin-left: calc( -100% - 2.2rem );
                    width: calc(200% + 2.4rem);
                    margin-left: calc(-100% - 2rem);
                }
            }

            &.type {
                #{$this}__secondary {
                    width: calc(300% + 4.4rem);
                    margin-left: calc(-200% - 4rem);
                }
            }
        }

        &__secondary {
            .pill-shaped-button {
                margin: 0.8rem 0.4rem 0;
            }
        }

        .pill-shaped-button {
            height: 4rem;
            padding: 0 1.2rem;

            &__text {
                font-size: 1.4rem;
                line-height: 1.4rem;
            }

            &__arrow-right {
                height: 1.1rem;
                width: 1.1rem;
            }

            &__carat {
                width: 1.4rem;
                height: 1.2rem;
                margin-left: 0.6rem;
            }
        }
    }
}
