/// @group components

.social-share {
    $this: &;
    position: relative;

    &__label {
        text-decoration: none;
        color: $white;
        margin-right: 2rem;

        .icon {
            display: none;
        }
    }

    &__copy-message {
        bottom: -2.2rem;
        font-size: 1.1rem;
        left: 50%;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transform: translateX(-50%);
        transition: opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1);
        white-space: nowrap;
    }

    &__text {
        margin-right: 1rem;
        text-transform: uppercase;
    }

    &__list {
        display: flex;
        justify-content: left;
        margin-top: 1.6rem;
        flex-wrap: wrap;

        &-close {
            display: none;
        }
    }

    &__list-item {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 2.4rem;
        justify-content: center;
        position: relative;
        width: 2.4rem;
        margin: 0 2rem 1rem 0;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            transform: scale(0);
            width: 100%;
            height: 100%;
            border-radius: 20rem;
            background: rgb($svns-white, 0);
            transition: transform 0.3s, background 0.3s;
        }

        &:hover {
            .icon {
                transform: scale(1);
                fill: $svns-primary-black;
                color: $svns-primary-black;
            }

            &::before {
                transform: scale(1.6);
                background: rgb($svns-white, 1);
            }
        }

        &:focus {
            outline: none;
        }

        &.is-active {
            .social-share__copy-message {
                border-radius: 0.5rem;
                opacity: 1;
                padding: 0 0.5rem;
            }
        }

        .icon {
            fill: $svns-white;
            color: $svns-white;
            stroke: none;
            height: 2.4rem;
            pointer-events: none;
            width: 2.4rem;
        }
    }

    @media (max-width: $break-tablet) {
        &__list {
            display: flex;
            justify-content: space-around;
            margin-top: 0;
            padding: 1rem;
        }

        &__list-item {
            .icon {
                // stylelint-disable-next-line declaration-no-important
                fill: $svns-black !important;
                color: $svns-black;
            }
            margin: 0;
        }

        &__label {
            .social-label {
                font-size: 1.4rem;
                line-height: 1.4rem;
            }

            .icon {
                fill: $svns-white;
                stroke: none;
                height: 1.6rem;
                pointer-events: none;
                width: 1.2rem;
                display: inline-block;
                margin-bottom: -0.2rem;
            }
        }

        &__share {
            position: relative;
        }

        .social-share-list {
            width: 25rem;
            position: absolute;
            background: $svns-white;
            border-radius: 2rem;
            right: 0;
            bottom: -5.5rem;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.6s, visibility 0.6s;
            z-index: 1;

            &.active {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
