.articleWidget {
    .accordion {
        .accordion-list {
            margin-top: 2.8rem;
        }

        @media (max-width: $break-tablet) {
            margin: 0 1.6rem !important;

            .accordion-list {
                margin-top: 1rem;
            }
        }
    }
}
