$arrowBlack: '../i/svg-files/elements/icons/arrow-right-black.svg';
$arrowWhite: '../i/svg-files/elements/icons/arrow-right-white.svg';

.teams-hero {
    $this: &;
    position: relative;
    z-index: 0;
    margin: 0;

    &--container {
        margin-top: 0;
        display: flex;
        flex-flow: column nowrap;
        overflow: visible;
        gap: 1rem;
        position: relative;
        z-index: 2;
        padding: 0.8rem 1.6rem;
    }

    &--image {
        position: fixed;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    &--sun {
        position: absolute;
        top: -22rem;
        left: -18rem;
        width: 120rem;
        height: 120rem;
        z-index: 0;
        stroke: none;
        fill: rgb($svns-black, .1);
        background: rgb($svns-white, .2);
        @include teamsColors(background);
    }

    &--link {
        text-align: left;
        text-transform: uppercase;
        position: relative;
        padding: 0.5rem 0 0.5rem 2rem;
        max-width: 20rem;
        font-size: 1.4rem;
        text-decoration: none;
        flex: 0 0 auto;
        color: $svns-white;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(-180deg);
            width: 1.4rem;
            height: 1.2rem;
            margin-left: -1.5em;
            background: url($arrowWhite) center/cover no-repeat;
            transition: 0.8s ease-in-out background-position;
        }
        @include hover {
            &::before {
                animation: scrollArrow 0.8s infinite 0.3s linear;
            }
        }
    }

    &--title {
        font-size: 4.2rem;
        line-height: 1;
        text-transform: uppercase;
        text-align: right;
        color: $svns-white;
    }

    &--short {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        &__title {
            font-size: 4.2rem;
            line-height: 1;
            text-transform: uppercase;
            color: $svns-white;
        }
    }

    &--flag {
        width: 4.2rem;
        height: 4.2rem;
        border: 0.1rem solid $svns-white;
    }

    &--additional {
        font-size: 2rem;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 0em;
        text-align: right;
        color: $svns-white;
        text-transform: uppercase;
    }

    &__social-links-container {
        display: flex;
        gap: 2.4rem;
        color: $svns-white;
        align-items: center;
    }

    &__social-links-title {
        text-transform: uppercase;
        font-size: 3.2rem;
        font-weight: 400;
    }

    &__social-links {
        display: flex;
        gap: 2.4rem;
        align-items: center;
    }

    &__social-link-icon {
        width: 3.2rem;
        height: 3.2rem;
        fill: $svns-white;
        transition: all ease-in-out 0.2s;

        @include hover {
            transform: scale(1.2);
        }
    }

    // Desktop breakpoints outwards
    @media (min-width: $break-desktop) {
        &--container {
            padding: 2.4rem;
        }

        &--sun {
            width: 250rem;
            height: 250rem;
            top: -75rem;
            left: 0;
            min-width: calc(100vw + 4rem);
            min-height: 100vw;
        }

        &--link {
            font-size: 1.6rem;

            &::before {
                width: 1.8rem;
                height: 1.6rem;
            }
        }

        &--title {
            font-size: 16rem;
        }

        &--short {
            &__title {
                font-size: 16rem;
            }
        }

        &--flag {
            width: 11rem;
            height: 11rem;
            border-width: 0.4rem;
        }

        &--additional {
            font-size: 3.2rem;
        }
    }

    // Tablet breakpoints inwards
    @media (max-width: $break-desktop) {
        &__social-links-container {
            gap: 1.6rem;
        }
    
        &__social-links-title {
            font-size: 2rem;
        }
    
        &__social-links {
            gap: 1.6rem;
        }
    
        &__social-link-icon {
            width: 2rem;
            height: 2rem;
        }
    }
}
