.landing-page-hero {
    background-color: $svns-blue;
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../i/svg-files/elements/backgrounds/landing-page-sun.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        animation: opacityFadeIn 1.2s forwards 0.1s;
        animation-timing-function: ease;
    }

    &__container {
        position: relative;
        padding: 4rem 4rem 1.2rem;
        z-index: 1;
    }

    &__header {
        position: relative;
    }

    &__logo {
        width: 50rem;
        height: 12.6rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../i/svg-files/elements/backgrounds/svns-logo.svg');
        margin-left: 100vw;
        animation: slideInFromRight 0.7s forwards 0.4s;
        animation-timing-function: ease-out;
    }

    &__info {
        position: relative;
        max-width: 41rem;
        margin-top: 17.6rem;
        z-index: 1;
        margin-left: -100vw;
        animation: slideInFromLeft 0.5s forwards 0.6s;
        animation-timing-function: ease-in-out;
    }

    &__title {
        color: $black;
        font-size: 7rem;
        line-height: 7rem;
        text-transform: uppercase;
        white-space: nowrap;
    }

    &__summary {
        color: $black;
        font-size: 1.8rem;
        line-height: 2.3rem;
        margin: 0.8rem 0;
        text-transform: uppercase;
    }

    &__cta {
        @include button;

        &--mob {
            position: absolute;
            bottom: 0;
            z-index: 1;
            transform: translate(-50%);
            left: 50%;
            margin-bottom: 2.4rem;
        }
    }

    &__image {
        position: absolute;
        right: 1.2rem;
        bottom: 0;
        max-width: calc(100% - 50rem);
        opacity: 0;
        animation: opacityFadeIn 2s forwards 0.8s;
        animation-timing-function: ease;
    }

    @media (max-width: $break-desktop) {
        &__container {
            padding: 3rem 3rem 1.2rem;
        }

        &__info {
            max-width: 45%;
        }

        &__logo {
            width: 29rem;
            height: 7.2rem;
        }

        &__info {
            margin-top: 8.4rem;
        }

        &__title {
            font-size: 4.5rem;
            line-height: 4.1rem;
        }

        &__summary {
            font-size: 1.6rem;
            line-height: 2rem;
        }

        &__image {
            max-width: 50%;
        }
    }

    @media (max-width: $break-tablet) {
        &::after {
            background-image: url('../i/svg-files/elements/backgrounds/landing-page-sun-mob.svg');
        }

        &__info {
            max-width: 85%;
        }

        &__image {
            position: relative;
            transform: translateX(-50%);
            left: 50%;
            max-width: 80%;
            margin-bottom: -0.6rem;
        }

        &__container {
            padding: 0;
        }

        &__content {
            padding: 2.5rem 4rem 0;
        }

        &__info {
            max-width: 70%;
        }

        &__summary {
            font-size: 1.6rem;
            line-height: 2rem;
            padding-right: 2rem;
        }
    }

    @media (max-width: $break-phablet) {
        &__image {
            max-width: 100%;
        }

        &__info {
            max-width: 100%;
        }

        &__summary {
            margin: 1.6rem 0;
        }
    }

    @media (max-width: $break-mobile-large) {
        &__content {
            padding: 2.5rem 1.6rem 0;
        }

        &__image {
            max-width: 120%;
        }
    }

    @media (max-width: $break-mobile) {
        &__image {
            max-width: 100%;
        }
    }
}
