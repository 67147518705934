.event-nav {
    width: calc(100% - .8rem);
    z-index: 4;

    .fixtures-nav_list {
        transition: margin .4s ease;

        &--link {
            cursor: pointer;
        }

        &--status {
            min-height: 2.4rem;
        }
    }

    @media (min-width: $break-desktop) {
        width: calc(100% - 1.6rem);
    }
}
