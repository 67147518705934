.pools {
    $this: &;
    position: relative;
    z-index: 2;
    margin: $layout-margin-mob;

    &__tabs {
        padding: 1.6rem;

        .fixtures-tabs {
            margin: 0;
        }
    }

    &__explanation {
        padding: 1.6rem;
        font-size: 1.2rem;
        line-height: 1;
        text-transform: uppercase;

        &-text {
            color: $svns-white;
        }
    }

    &__container {
        display: flex;
        flex-flow: nowrap column;
        justify-content: flex-start;
        padding: 1.6rem;
        margin-top: 4rem;
    }

    &__main-title {
        font-size: 3.2rem;
        line-height: 1;
        text-transform: uppercase;
        color: $svns-white;
    }

    &__title {
        color: $svns-white;
        text-transform: uppercase;
        font-size: 2.4rem;
        line-height: 1;
    }

    &__table {
        margin-top: 2rem;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        background: $svns-black;

        &--row {
            transition: background 0.5s, transform;
            
            @include hover {
                cursor: pointer;
                @include teamsColors(background);
            }
        }

        &--cell {
            text-transform: uppercase;
            font-weight: 400;
            text-align: center;
            color: $svns-white;
            font-size: 1.4rem;
            border-top: 0.1rem solid $svns-white;
            border-bottom: 0.1rem solid $svns-white;
            padding: 1.6rem 0;

            &-hide {
                display: none;
            }

            &.isLeft {
                text-align: left;
            }

            & .isFirstLetter {
                visibility: hidden;
                max-width: 1rem;
                padding: 0 1.4rem;

                &::first-letter {
                    visibility: visible;
                }
            }

            &-pos {
                width: 3rem;
            }

            &-team {
                width: 7rem;
            }

            &-points {
                width: 6rem;
                font-size: 2.8rem;
            }

            &-head {
                font-size: 1.2rem;
                text-align: left;
                #{$this}__table--cell-content {
                    text-align: center;
                }

                .isLeft {
                    text-align: left;
                }
            }

            &.isBorder {
                border-right: 0.1rem solid $svns-white;
            }

            &-content {
                display: block;
                line-height: 1;

                &.link {
                    display: inline-flex;
                    align-items: baseline;
                    gap: 0.5rem;
                    font-size: 2.5rem;
                    text-decoration: none;
                    line-height: 1;
                    transition: padding 0.3s;
                    position: relative;
                    width: auto;
                    text-align: left;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0;
                        height: 0.1rem;
                        background: $svns-white;
                        transition: width 0.3s;
                    }
                    @include hover {
                        padding-left: 0.3rem;

                        &::after {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &__team-pos {
        font-size: 2.8rem;
        line-height: 1;
        text-align: left;
        position: relative;
        display: inline-flex;
        align-items: flex-start;
        width: 100%;

        &.isLeft {
            text-align: left;
        }
    }

    &__team--flag {
        width: 2rem;
        height: 2rem;
        border: 0.1rem solid $svns-white;
    }

    &__team-name {
        font-size: 2.8rem;
        line-height: 1;
        text-align: left;
        position: relative;
    }

    @media (min-width: $break-desktop) {
        &__container {
            padding: 2.4rem;
        }

        &__main-title {
            font-size: 6.5rem;
        }

        &__title {
            font-size: 4.8rem;
        }

        &__explanation {
            padding: 2.4rem;
            font-size: 1.4rem;
        }

        &__table {
            &--cell {
                font-size: 3.2rem;
                padding: 2.4rem 0.5rem;

                &-points {
                    display: none;
                }

                &-head {
                    font-size: 1.8rem;
                }

                &-big {
                    font-size: 6.5rem;
                }

                &-hide {
                    display: table-cell;
                }

                &-pos {
                    width: 6rem;
                }

                &-team {
                    width: 20rem;
                }

                & .isFirstLetter {
                    visibility: visible;
                    max-width: 100%;
                    padding: 0;

                    &::first-letter {
                        visibility: visible;
                    }
                }

                &.isBorder {
                    border-right: none;
                }
            }
        }

        &__team-pos {
            font-size: 6.5rem;
        }

        &__team--flag {
            width: 4.6rem;
            height: 4.6rem;
        }

        &__team-name {
            font-size: 6.5rem;
        }
    }
}
