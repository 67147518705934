$arrowBlack: '../i/svg-files/elements/icons/arrow-right-black.svg';
$arrowWhite: '../i/svg-files/elements/icons/arrow-right-white.svg';

.video__rail-related {
    $this: &;
    position: relative;
    display: flex;
    padding-bottom: 1.6rem;

    &--info-container {
        margin-left: .8rem;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
    }

    &--card {
        min-width: 15rem;
        max-width: 15rem;
    }

    &__image {
        min-width: 45%;
    }

    &--gallery-title {
        @include truncate-multiline(3, 1.6rem);
    }

    &--info {
        padding-bottom: 2rem;
    }
    
    &--title {
        color: $svns-white;
        font-size: 2.4rem;
        line-height: 1;
        text-transform: uppercase;
        display: block;
        width: 100%;
    }

    &--body {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    &--gallery {
        display: flex;
        flex-flow: column;
    }

    &--item {
        display: flex;
        flex: 1;
        position: relative;
        transition: opacity .3s, transform .3s, padding .3s;
        box-shadow: 0 .1rem 0 0 $svns-white;
        padding: 2.4rem 0;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: background .3s;
            background: rgb($svns-white,0);
            z-index: -1;
        }

        @include hover {
            #{$this}__image {
                transform: scale(1.02);
                filter: brightness(120%);
            }
            #{$this}--duration {
                transform: scale(.63);
            }
        }

        &.isActive {
            pointer-events: none;
            &::after {
                background: rgb($svns-white,.1);
                z-index: auto;
            }
            #{$this}__image {
                transform: scale(1.02);
                opacity: .6;
            }
            #{$this}--duration {
                transform: scale(.63);
            }
        }
    }

    &--card {
        position: relative;
        display: flex;
        flex-flow: row;
        overflow: hidden;
        transition: box-shadow 1s;
        width: 100%;
        gap: 0 1rem;
    }

    &__picture-image {
        display: flex;
        overflow: hidden;
        flex: 0 0 15rem;
        position: relative;
    }

    &__image {
        width: 15rem;
        height: 10rem;
        transition: all .5s;
    }

    &--gallery-title {
        color: $svns-white;
        font-size: 1.6rem;
        line-height: 1;
        text-transform: uppercase;
        margin: 0;
    }

    &--tag {
        color: $svns-white;
        text-transform: uppercase;
        margin-right: 1rem;
        line-height: 1;
        font-size: 1.2rem;
        flex: 0 0 auto;
        padding: 0;
        border-radius: 3rem;
    }

    &--exclusive {
        margin-top: 2rem;
        display: flex;
        width: 100%;
        scroll-behavior: smooth;
        overscroll-behavior: contain;
        overflow: auto;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &--duration {
        position: absolute;
        top: calc(10rem - 5rem);
        left: 0;
        width: 15rem;
        height: 5rem;
        padding: .5rem .8rem .5rem 6rem;
        color: $white;
        background-color: $svns-white;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        transition: transform .3s;
        transform: scale(0.5);
        transform-origin: bottom left;

        &::after {
            content: '';
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 1.5rem;
            transform: translate(0, -50%);
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 20px solid black;
        }

        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 5rem;
            height: 100%;
            background: #F5F505;
        }

        &-time {
            display: inline-block;
            vertical-align: middle;
            color: $svns-black;
            font-size: 2.4rem;
        }
    }

    @media (min-width: $break-desktop) {
        flex-flow: row wrap;
        padding: 0 2.4rem;

        &--title {
            font-size: 3.2rem;
            padding-bottom: 2.4rem;
        }

        &--controls {
            display: flex;
        }

        &--carousel {
            scroll-behavior: unset;
            overflow: hidden;
        }

        &--tag {
            font-size: 1.2rem;
        }
        
        &--gallery-title {
            font-size: 1.6rem;
        }
        
        &__picture-image {
            flex: 0 0 15rem;
        }
    
        &__image {
            width: 15rem;
            height: 10rem;
        }
    }
}
