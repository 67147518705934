////
/// @group typography
////

// stylelint-disable font-weight-notation, declaration-no-important
// Font Mixins

/// Value for font-weight: regular
/// @type number

$regular: 400;

@font-face {
    font-family: mabryMono;
    src: url('../fonts/MabryMono-Regular-Pro.eot') format('eot');
    src: url('../fonts/MabryMono-Regular-Pro.woff2') format('woff2'),
        url('../fonts/MabryMono-Regular-Pro.woff') format('woff'),
        url('../fonts/MabryMono-Regular-Pro.ttf') format('truetype');
    font-weight: $regular;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: mabryMonoBold;
    src: url('../fonts/MabryMono-Bold-Pro.eot') format('eot');
    src: url('../fonts/MabryMono-Bold-Pro.woff2') format('woff2'),
        url('../fonts/MabryMono-Bold-Pro.woff') format('woff'),
        url('../fonts/MabryMono-Bold-Pro.ttf') format('truetype');
    font-weight: $regular;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: dmSans;
    src: url('../fonts/DMSans-Regular.woff2') format('woff2'),
        url('../fonts/DMSans-Regular.ttf') format('truetype');
    font-weight: $regular;
    font-style: normal;
    font-display: swap;
}
