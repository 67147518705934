.web-view-body {

    .promo-grid .widget-header,
    .main-footer,
    #onetrust-consent-sdk {
        display: none !important; 
    }

    // FIXTURES PAGE
    .fixtures-hero--title {
        display: none !important;
    }


    .main-header {

        .main-header-container,
        .sidebar-nav,
        .main-nav:not( .main-nav--small ) {
            display: none !important;
        }
    }

    .promo-grid { 

        &__container {
            padding: 0 1.6rem;
        }

        &__content {
            margin-top: 0;
        }
    }
}

.web-view-only {

    &:not( .web-view-body ) {

        .promo-grid {
            display: none !important;
        }
    }
}