.footer-social-nav {
    $this: &;
    display: flex;
    padding: 1rem;
    gap: 1rem;
    flex-direction: row;
    justify-content: space-around;
    max-width: 40rem;
    margin: 0 auto;

    &--item {
        flex: 0 0 auto;
    }

    &--link {
        padding: 1rem;
        transition: background 0.3s;
        display: flex;
    }

    &--img {
        width: 2.5rem;
        height: 2.5rem;
        stroke: none;
        fill: $svns-white;
        transition: transform 0.3s, fill 0.6s;
    }

    @media (min-width: $break-tablet) {
        justify-content: flex-end;
        max-width: none;
        margin: 0;

        &--title {
            font-size: 2.4rem;
        }

        &__list {
            justify-content: flex-end;
        }

        &--link {
            position: relative;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                transform: scale(0);
                width: 100%;
                height: 100%;
                border-radius: 20rem;
                background: rgb($svns-white, 0);
                transition: transform 0.3s, background 0.3s;
            }

            &:hover {
                #{$this}--img {
                    transform: scale(1.2);
                    fill: $svns-primary-black;
                    color: $svns-primary-black;
                }

                &::before {
                    transform: scale(1);
                    background: rgb($svns-white, 1);
                }
            }
        }
    }
}
