.fixtures-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    z-index: 1;
}

.fixtures-content {
    flex: 1;
    position: relative;
    display: flex;

    .isLoading {
        position: absolute;
        top: 23rem;
        background: $svns-white;
        width: 0;
        max-width: calc( 95% - 2rem);
        height: .5rem;
        transform: translateX(-50%);
        left: 50%;
        z-index: 1;
        opacity: .5;
        animation: loaderExpand 1.5s infinite linear;

        @media (min-width: $break-desktop) {
            top: 18rem;
        }
    }
}

.fixtures-content--inner {
    flex: 1;
    background: $svns-black;
    margin: 0.4rem;

    .no-fixtures:last-of-type {
        display: none;
    }
}

@media (min-width: $break-tablet) {
    .fixtures-container {
        flex-flow: column nowrap;
    }

    .fixtures-content--inner {
        margin: 0.8rem;
    }
}
