.articleWidget {
    .single-promo {
        margin: 0;
        max-width: 100vw;

        &__summary,
        &__category {
            color: $svns-black;
        }
    }
}
