.registration-page {
    &__bg {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 200rem;
        height: 200rem;
        stroke: none;
        opacity: 0;
        animation: opacityFadeIn 1.2s forwards 0.1s;
        animation-timing-function: ease-in-out;
        background-color: $cape-town;
    }

    &.cookie-list {
        fill: $cape-town-secondary;
        opacity: 0;
        animation: opacityFadeIn 1.2s forwards 0.1s;
        animation-timing-function: ease-in-out;

        &::after {
            background-image: none;
        }
    }

    .promo-grid {
        margin: 0;
        z-index: 1;
    }

    .registration-modal {
        &__title {
            position: relative;
            top: 0;
            max-height: none;
        }

        &__summary {
            margin: 2rem 0;
        }
    }

    @media (max-width: $break-phablet) {
        &.cookie-list {
            padding: 3rem 0.8rem 1.2rem;
        }

        .cookie-list__content {
            padding: 0;
        }

        .registration-modal {
            &__title,
            &__summary {
                margin: 1rem 0.4rem 0;
            }
        }
    }
}
