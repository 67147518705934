.fixture-timeline {
    $this: &;
    display: flex;
    flex-flow: column-reverse nowrap;
    width: 100%;
    margin-top: 5rem;

    &--item {
        width: calc(50% + 2rem);
        padding: 1.2rem 0 .8rem 0;
        border-bottom: 0.1rem solid $svns-white;
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        gap: 0 1rem;

        &.isLeft {
            align-self: flex-start;
        }

        &.isRight {
            align-self: flex-end;
            text-align: right;
            justify-content: flex-end;

            #{$this}--time {
                right: auto;
                left: 0;
            }

            .isSubbedOn,
            .isSubbedOff {
                left: 1.5rem;
            }
        }
    }

    &--container {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        gap: 0;
        position: relative;
    }

    .isSubbedOn,
    .isSubbedOff {
        width: 2.2rem;
        height: 1.1rem;
        position: relative;
        display: inline-block;
        top: .5rem;
        margin-left: .4rem;

        &::after,
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: .2rem;
            background: $cape-town;
        }

        &::before {
            transform: rotate(-45deg);
            left: -0.6rem;
        }
    }

    .isSubbedOn {

        &::before {
            background: $cape-town;
            transform: rotate(-45deg);
        }

        &::after {
            background: $cape-town;
            transform: rotate(45deg);
        }
    }

    .isSubbedOff {

        &::after {
            background: $dubai;
            transform: rotate(-45deg);
        }

        &::before {
            background: $dubai;
            transform: rotate(45deg);
        }
    }

    &--type {
        font-size: 3.2rem;
        line-height: 1;
    }

    &--name {
        font-size: 1.2rem;
        position: relative;
        min-height: 2.4rem;
    }

    &--label {
        font-size: 1rem;
    }

    &--time {
        position: absolute;
        width: 4rem;
        text-align: center;
        top: 1.4rem;
        right: 0;
        font-size: 2.4rem;
    }

    &--polling-events {
        max-width: 100%;
        display: flex;
        flex-direction: column-reverse;;
    }

    &__new-event {
        max-height: 0;
        overflow: hidden;

        &.isLeft {
            animation: eventFromLeft 1.4s forwards .8s ease-in-out;
            left: -100vw;

            &.no-animation {
                left: 0;
                animation: none;
                max-height: 20rem;
            }
        }

        &.isRight {
            animation: eventFromRight 1.4s forwards .8s;
            right: 100vw;

            &.no-animation {
                right: 0;
                animation: none;
                max-height: 20rem;
            }
        }
    }

    @media (min-width: $break-desktop) {
        &--container {
            gap: 0.5rem 0;
            justify-content: space-between;
        }

        &--item {
            padding: 2.4rem 0;
            gap: 0 2rem;
            flex-flow: row nowrap;
            align-self: flex-start;

            &.isLeft {
                align-self: flex-start;
            }

            &.isRight {
                align-self: flex-end;
                text-align: right;
                justify-content: flex-end;
                #{$this}--type {
                    order: 2;
                }
            }
        }

        .isSubbedOn,
        .isSubbedOff {
            top: 0;
        }

        &--type {
            font-size: 6.5rem;
            line-height: 0.8;
        }

        &--name {
            font-size: 2.4rem;
            position: relative;
        }

        &--label {
            font-size: 1.8rem;
        }        
        
        &--time {
            top: 2.5rem;
        }
    }
}
