.google-ad {
    display: flex;
    justify-content: center;
    margin: 3.6rem 0;
    overflow-x: hidden; // prevent larger images causing overflow on iOS

    &__item {
        background-color: $grey;
        height: auto;
        max-width: 100%;
    }

    .sidebar-layout & {
        margin-right: 2rem;
    }

    .sidebar-layout--invert-row & {
        margin-left: 1rem;
        margin-right: 0;
    }
}

@media (max-width: $break-tablet) {
    .google-ad {
        .sidebar-layout & {
            margin-right: 0;
        }

        .sidebar-layout--invert-row & {
            margin-left: 0;
        }
    }
}

@media (min-width: $break-tablet) and (max-width: $break-wide) {
    .articleWidget {
        margin-top: 0;
        margin-bottom: 0;

        &.left {
            .google-ad--mpu {

                .google-ad__item {
                    margin-right: 6rem;
                }
            }
        }

        &.right {
            .google-ad--mpu {

                .google-ad__item {
                    margin-left: 6rem;
                }
            }
        }
    }
}
