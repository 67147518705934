$margin: 3rem;
$arrowBlack: '../i/svg-files/elements/icons/arrow-right-black.svg';
$arrowWhite: '../i/svg-files/elements/icons/arrow-right-white.svg';

.hospitality-cards {
    $this: &;
    position: relative;
    z-index: 2;
    margin: $layout-margin-mob;

    &-container {
        display: flex;
        flex-flow: nowrap column;
        justify-content: flex-start;
    }

    &__item {
        background: $svns-black;
        position: relative;
        color: $svns-white;
        flex: 1;
        padding: 2rem;
        padding-top: 7rem;
        display: none;

        &.isVisible {
            display: block;
        }
        @include hover {
            #{$this}-image__image {
                transform: scale(1.1);
            }
        }
    }

    &-content {
        margin-top: $margin;
        @include font-dmsans-regular;
        font-size: 1.8rem;

        & p {
            color: $svns-white;
        }
    }

    &-tape {
        position: absolute;
        top: 0;
        left: 2rem;
        display: flex;
        width: calc(100% - 4rem);
        overflow: hidden;
        padding: 2rem;
        flex-flow: row wrap;

        &__list {
            display: flex;
            animation: scroll 100s linear infinite;
            min-width: 200rem;
            width: 100%;

            &--item {
                flex: 0 0 auto;
                text-transform: uppercase;
                margin: 0 2rem 2rem;
            }

            &--item.not-available {
                color: $svns-white;
            }

            &--item.tickets-available {
                @include eventColors(color);
            }

            &--item.limited-availability {
                @include eventColors-secondary(color);
            }

            &:hover {
                animation-play-state: paused;
            }
        }
    }

    &-title {
        text-transform: uppercase;
        font-size: 4.2rem;
        line-height: 1;
    }

    &-price {
        margin-top: $margin;
        text-align: right;
        font-size: 3.2rem;
        line-height: 1;
        text-transform: uppercase;

        &--small {
            font-size: 1.8rem;
            position: relative;
            padding: 0 0.8rem;

            &.top {
                bottom: 1rem;
            }
        }
    }

    &-cta {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        margin-top: $margin;
        padding: 0;

        &--more,
        &--buy {
            text-align: center;
            text-transform: uppercase;
            border-radius: 10rem;
            padding: 1.2rem 2rem;
            font-size: 1.4rem;
            border: 0.1rem solid $svns-white;
            background: $svns-white;
            color: $svns-black;
            text-decoration: none;
            flex: 1;
            max-width: 100%;
            position: relative;
            transition: background 0.3s, color 0.3s;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            &::after {
                content: '';
                position: relative;
                display: inline-block;
                width: 1.4rem;
                height: 1.2rem;
                margin-left: 0.4rem;
                background: url($arrowBlack) center/cover no-repeat;
                transition: 0.8s ease-in-out background-position;
            }
            @include hover {
                background: $svns-black;
                color: $svns-white;

                &::after {
                    animation: scrollArrow 0.8s infinite 0.3s linear;
                    background: url($arrowWhite) center/cover no-repeat;
                }
            }
        }

        &--more {
            background: $svns-black;
            color: $svns-white;
            display: none;

            &::after {
                height: 1.4rem;
                top: 0.6rem;
                transform: rotate(90deg) translateX(-50%);
                background: url($arrowWhite) center/cover no-repeat;
            }
            @include hover {
                background: $svns-white;
                color: $svns-black;

                &::after {
                    animation: scrollArrow 0.8s infinite 0.3s linear;
                    background: url($arrowBlack) center/cover no-repeat;
                }
            }
        }

        &.not-available {
            #{$this}-cta--buy {
                pointer-events: none;
                opacity: 0.4;
            }
        }
    }

    &-image--container {
        margin-top: $margin;
        width: 100%;
        display: none;
        overflow: hidden;

        #{$this}-image__picture-image {
            display: flex;
        }

        #{$this}-image__image {
            width: 100%;
            transition: transform 0.3s;
        }
    }

    @media (min-width: $break-desktop) {
        margin: $layout-margin;

        &-container {
            flex-flow: wrap row;
        }

        &-title {
            font-size: 4.8rem;
        }

        &__item {
            flex: 0 0 33.3333%;
            display: block;
            margin-bottom: 0.8rem;
        }

        &__item:nth-child(2),
        &__item:nth-child(5) {
            flex: 0 0 calc(33.3333% - 1.6rem);
            margin: 0 0.8rem 0.8rem;
        }

        &-cta {
            &--more,
            &--buy {
                display: flex;
                font-size: 1.4rem;
                max-width: 48%;
            }
        }

        &-image--container {
            display: block;
        }
    }
    @media (min-width: $break-wide) {
        &-cta {
            &--more,
            &--buy {
                font-size: 1.6rem;
            }
        }
    }
}
