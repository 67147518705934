.landing-page-registration {
    background-color: $svns-white;

    &__container {
        position: relative;
        padding: 4rem 4rem 2.4rem;
    }

    &__header {
        margin-bottom: 8rem;
    }

    &__title {
        color: $black;
        font-size: 1.8rem;
        line-height: 2.3rem;
        text-transform: uppercase;
        margin-bottom: 3.2rem;
    }

    &__summary {
        font-size: 6.5rem;
        line-height: 6.4rem;
        color: $black;
        max-width: 62rem;
        text-transform: uppercase;
    }

    &__mandatory {
        color: $black;
        font-size: 1.2rem;
        line-height: 1.6rem;
        text-align: right;
        text-transform: uppercase;
    }

    iframe {
        max-width: 72rem;
    }

    @media (max-width: $break-wide) {
        &__summary {
            font-size: 4rem;
            line-height: 4.1rem;
        }
    }

    @media (max-width: $break-phablet) {
        &__container {
            padding: 3.2rem 1.6rem;
        }

        &__title {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }
}
