.pre-footer {
    margin-top: 4rem;

    // stylelint-disable-next-line
    .logo-carousel {
        background: $svns-black;

        // stylelint-disable-next-line
        &.isTransparent {
            background: none;
            padding: 0 0 2rem;
        }
    }

    .logo-carousel-title {
        color: $svns-black;
    }

    @media (min-width: $break-tablet) {
        .logo-carousel {
            padding: 2rem 0 1rem;

            &.isTransparent {
                padding: 0 0 3rem;
            }
        }
    }
}

.main-footer {
    width: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    background: $svns-primary-black;
    padding: 2rem 0 0;

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 1rem;
        height: auto;
    }

    &-container {
        max-width: 144rem;
        width: 100%;
        margin: 0 auto;
        display: flex;
        padding: 0;
        flex-direction: column;
        justify-content: space-between;

        &--mid {
            display: grid;
            gap: 1rem;
            justify-content: normal;
            grid-template-areas: 'app' 'logo' 'social';
        }
    }

    &__top {
        border-bottom: 0.1rem solid $svns-white;
        padding-bottom: 1rem;
    }

    &__left,
    &__right {
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        order: 1;
        min-height: 7rem;
    }

    &__left {
        grid-area: logo;
        width: 100%;
    }

    &__right {
        grid-area: app;
        width: 100%;
        align-self: start;
    }

    &__right--social {
        grid-area: social;
        width: 100%;
        align-self: end;
    }

    &__bottom {
        padding: 0;
        border-bottom: none;
        display: flex;
    }

    &--copyright {
        color: $svns-white;
        font-size: 1.4rem;
        text-transform: uppercase;
        border-top: 0.1rem solid $svns-white;
        padding: 0.4rem 1rem 1.4rem;

        & > span {
            font-size: 3.2rem;
            line-height: 1;
            position: relative;
            bottom: -1.1rem;
        }
    }

    @media (min-width: $break-tablet) {
        &-container {
            flex-direction: row;

            &--mid {
                gap: 2rem 0;
                grid-template-areas: 'logo app' 'logo social';
            }
        }

        &__left,
        &__right {
            display: flex;
            padding-top: 3rem;
            align-items: flex-end;
            justify-content: flex-end;
        }

        &__bottom {
            padding: 0;
            border-top: 0.1rem solid $svns-white;
        }

        &--copyright {
            border: none;
            padding: 0;
        }
    }
}
