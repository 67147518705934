/* Breakpoints */
$break-mobile: 400px;
$break-mobile-large: 500px;
$break-phablet: 640px;
$break-tablet: 840px;
$break-desktop: 1025px;
$break-wide: 1300px;
$break-mobile-min: 401px;
$break-mobile-large-min: 501px;
$break-phablet-min: 641px;
$break-tablet-min: 841px;
$break-desktop-min: 1025px;
