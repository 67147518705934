/* Typography */

body {
    @include font-regular;
    @include font-weight('regular');
    color: $svns-black;
    font-size: 1.6rem;
    line-height: 2.4rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (max-width: $break-tablet) {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }
}

.d1 {
    font-size: 6.4rem;
    @include font-weight('bold');
    line-height: 7rem;

    @media (max-width: $break-tablet) {
        font-size: 4rem;
        line-height: 4.4rem;
    }
}

.d2 {
    font-size: 4.8rem;
    @include font-weight('bold');
    line-height: 5.2rem;

    @media (max-width: $break-tablet) {
        font-size: 3.2rem;
        line-height: 3.6rem;
    }
}

h1 {
    font-size: 4rem;
    @include font-weight('bold');
    line-height: 4.4rem;

    @media (max-width: $break-tablet) {
        font-size: 2.8rem;
        line-height: 3.2rem;
    }
}

h2 {
    font-size: 3.2rem;
    @include font-weight('regular');
    line-height: 3.6rem;
    color: $grey-dark;

    @media (max-width: $break-tablet) {
        font-size: 2.4rem;
        line-height: 2.8rem;
    }
}

h3 {
    font-size: 2.4rem;
    @include font-weight('regular');
    line-height: 2.8rem;

    @media (max-width: $break-tablet) {
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
}

h4 {
    font-size: 1.8rem;
    @include font-weight('regular');
    line-height: 2.2rem;

    @media (max-width: $break-tablet) {
        font-size: 1.4rem;
        line-height: 2rem;
    }
}

.paragraph,
p {
    font-size: 1.6rem;
    @include font-weight('light');
    line-height: 2.4rem;
    font-variation-settings: 'wght' 220, 'dspl' 100;
    color: $grey-dark;

    @media (max-width: $break-tablet) {
        font-size: 1.4rem;
        line-height: 2.1rem;
    }
}

.label {
    font-size: 1.2rem;
    @include font-weight('regular');
    line-height: 1.6rem;

    @media (max-width: $break-tablet) {
        font-size: 1rem;
        line-height: 1.4rem;
    }
}

strong,
.bold {
    @include font-weight('bold');
}
