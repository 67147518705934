$accordionClass: '.accordion';

.accordion-enhanced {
    $this: &;
    position: relative;
    z-index: 2;
    margin: $layout-margin-mob;

    &-container {
        display: flex;
        flex-flow: row wrap;
        position: relative;
        padding: 2rem;
    }

    .wrapper {
        padding: 0;
        overflow: visible;
    }

    &--mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 96%;
        margin: 0 auto;
        background: $svns-black;
        color: $svns-white;
        border: 0.1rem solid $svns-white;
        border-radius: 3rem;
        padding: 1.2rem 2rem;

        &-text {
            font-size: 1.4rem;
            text-transform: uppercase;
            display: flex;
            width: 100%;
            max-width: 98%;
            justify-content: space-between;
            align-items: center;
            position: relative;
        }

        &-arrow {
            stroke: none;
            fill: $svns-white;
            transition: transform 0.3s;
        }
        #{$this}-nav--length {
            color: $svns-white;
            position: relative;
            top: 0;
            transform: translateY(0);
        }

        &.isActive {
            #{$this}--mobile-arrow {
                transform-origin: center;
                transform: rotate3d(1, 0, 0, 180deg);
            }
        }
    }

    &-nav {
        position: absolute;
        top: 7rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 11;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        border: 0.1rem solid $svns-black;
        width: 90%;
        border-radius: 1.4rem;
        overflow: hidden;
        background: $svns-white;
        opacity: 0;
        height: 0;
        transition: opacity 0.5s;
        visibility: hidden;

        &--item {
            display: flex;
            align-items: flex-start;
            flex: 0 0 100%;
            text-transform: uppercase;
            font-size: 1.4rem;
            line-height: 1;
            color: $svns-black;
            border: none;
            padding: 1.5rem 3.5rem 1.5rem 1rem;
            border-bottom: 0.1rem solid $svns-black;
            position: relative;
            cursor: pointer;
            transition: color 0.3s, background 0.3s;
            // stylelint-disable-next-line selector-max-specificity

            &:nth-last-child(-n + 1) {
                border: none;
            }

            &.isActive {
                padding-left: 1.5rem;

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 0.4rem;
                    height: 0.4rem;
                    border: 0.1rem solid $svns-black;
                    border-top-color: transparent;
                    border-left-color: transparent;
                    transform: rotate(-45deg) translateY(-50%);
                }
            }
        }

        &--length {
            font-size: 1.2rem;
            color: $svns-black;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 2rem;
        }

        &.isVisible {
            opacity: 1;
            height: auto;
            visibility: visible;
            padding: 1rem;
        }
    }

    &-wrapper {
        flex: 0 0 100%;
        margin-top: 1rem;
        #{$accordionClass} {
            opacity: 0;
            height: 0;
            overflow: hidden;
            margin: 0;

            &.isVisible {
                opacity: 1;
                height: auto;
                overflow: visible;
            }
        }
    }

    @media (min-width: $break-tablet) {
        margin: $layout-margin;

        &-container {
            display: flex;
            flex-flow: row wrap;
            position: relative;
        }

        &--mobile {
            display: none;
        }

        &-nav {
            flex: 0 0 35%;
            position: relative;
            right: auto;
            left: 0;
            top: 0;
            transform: translate(0);
            background: none;
            border: none;
            gap: 1rem;
            opacity: 1;
            height: auto;
            visibility: visible;

            &--item {
                display: flex;
                align-items: flex-start;
                flex: 0 0 auto;
                text-transform: uppercase;
                font-size: 1.8rem;
                line-height: 1;
                background: $svns-black;
                color: $svns-white;
                border: 0.1rem solid $svns-white;
                border-radius: 5rem;
                padding: 1.2rem 3rem;
                position: relative;
                cursor: pointer;
                transition: color 0.3s, background 0.3s;
                // stylelint-disable-next-line selector-max-specificity

                &:nth-last-child(-n + 1) {
                    border: 0.1rem solid $svns-white;
                }

                &:hover,
                &.isActive {
                    color: $svns-black;
                    padding: 1.2rem 3rem;
                    background: $svns-white;

                    &::after {
                        display: none;
                    }
                    #{$this}-nav--length {
                        color: $svns-black;
                    }
                }
            }

            &--length {
                font-size: 1.2rem;
                color: $svns-white;
                position: relative;
                top: 0;
                left: 0.5rem;
                transform: translate(0);
            }
        }

        &-wrapper {
            flex: 0 0 65%;
            margin: 0;
        }
    }
}
