.fixture-modal {
    $this: &;
    color: $svns-white;
    text-transform: uppercase;
    position: relative;
    top: -4.1rem;
    padding-bottom: 5rem;
    z-index: 12;
    background: $svns-black;
    width: 100%;
    animation: opacityFadeIn 0.5s forwards;
    display: none;
    max-width: calc(100vw - .8rem);
    overflow: hidden;

    &.isVisible {
        display: block;
    }

    &__container {
        padding: 1.6rem;
        scroll-behavior: smooth;
        overflow: auto;
        position: relative;
        top: 5rem;
        height: calc(100% - 7.5rem);
        z-index: 2;
        max-width: 100%;
    }

    &-header {
        width: 100%;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        left: 0;
        background: $svns-black;
        border-bottom: 0.1rem solid $svns-white;
        color: $svns-white;
        text-align: right;
        z-index: 3;
    }

    &-close {
        cursor: pointer;
        padding: 1.5rem 5rem;
        text-transform: uppercase;
        display: inline-block;
        transition: padding 0.3s;
        font-size: 1.4rem;

        &::after,
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: 3rem;
            width: 0.2rem;
            height: 2rem;
            background: $svns-white;
            transform-origin: top;
            transition: transform 0.3s;
            transform: rotate(-45deg) translateY(-50%);
        }

        &::after {
            transform: rotate(45deg) translateY(-50%);
        }
        @include hover {
            padding-right: 5.5rem;

            &::after {
                transform: rotate(-45deg) translateY(-50%);
            }

            &::before {
                transform: rotate(45deg) translateY(-50%);
            }
        }
    }

    .red-card,
    .yellow-card {
        width: 1.8rem;
        height: 1.8rem;
        background: #fa0a37;
        margin: 0 .3rem;
        display: inline-block;
    }

    .yellow-card {
        background: #f5f505;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        transition: .8s ease max-height;
        overflow: hidden;
        max-height: 0;

        &.full-height {
            max-height: 30rem;
        }
    }

    &__summary {
        position: relative;
        padding-bottom: 3rem;
    }

    &__score-type,
    &__referee {
        flex: 0 0 10%;
        font-size: 1.2rem;
    }

    &__list {
        flex: 0 0 45%;
        font-size: 1.2rem;
        padding-top: 3rem;

        &:last-of-type {

            .is-cards {
                justify-content: flex-end;
            }
        }

        &--item {
            display: flex;
            gap: .5rem;


            &:not( .is-cards ) {
                flex-direction: column;
            }
        }
    }

    &__score-type,
    &__referee {
        text-align: center;
        font-weight: 700;
        padding-top: 3rem;
    }

    &__list:nth-child(3) {
        text-align: right;
    }

    &__gender,
    &__status,
    &__phase {
        flex: 0 0 33.333%;
    }

    &__status {
        text-align: center;

        &.liveMatch {
            @include eventColors-secondary(color);
        }
    }

    &__ht-score {
        font-size: 1.2rem;
        text-align: center;
        line-height: 1.2em;

        &.liveMatch {
            @include eventColors-secondary(color);
        }
    }

    &__phase {
        text-align: right;
    }

    &__teams {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 3.8rem;
        margin-top: 0.4rem;
    }

    &__team {
        align-self: flex-start;
        line-height: 1;
        align-items: center;
        display: flex;

        &:nth-child(3) {
            align-self: flex-end;
        }
    }

    &__abbrev {
        margin: 0 0.3rem;
    }

    &__flag-container {
        position: relative;
        width: 2.8rem;
        height: 2.8rem;
        border: .1rem solid $svns-white;
        overflow: hidden;
    }

    &__flag {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    &__flag-fallback {
        height: 100%;
        width: 100%;
        fill: $svns-white;
    }

    &__score-container {
        display: flex;
        align-items: center;
        transition: transform 0.5s;
        line-height: 1;
        flex-flow: column;

        & span {
            display: none;
        }

        &.liveMatch {
            @include eventColors-secondary(color);
        }
    }        
    
    &__challenger-label {
        float: right;
        font-size: 1.2rem;
        line-height: 1;
        margin-top: .8rem;
    }

    @media (min-width: $break-desktop) {
        top: -5.4rem;
        max-width: calc(100vw - 1.6rem);

        &__container {
            padding: 2.4rem;
            top: 7.5rem;
        }

        &-close {
            padding: 2.5rem 5rem;
            font-size: 2.4rem;
        }

        &__fixture {
            padding: 2.4rem 0 0;
            animation: opacityFadeIn 0.5s forwards;
            cursor: pointer;
            transition: padding 0.5s;
            @include hover {
                padding: 2.4rem 0.6rem 0;
                #{$this}__score-container {
                    transform: translateY(-0.3rem);
                }
            }
        }

        &__teams {
            font-size: 8.4rem;
            line-height: 1.2;
        }

        &__team {
            align-self: center;
            line-height: 1;
            align-items: center;
            display: flex;

            &:nth-child(3) {
                align-self: center;
            }
        }

        &__referee,
        &__score-type,
        &__list {
            font-size: 1.4rem;
        }

        &__abbrev {
            margin: 0 1.6rem;
        }

        &__score-container {
            line-height: 1;
            flex-flow: row;

            & span {
                display: inline-block;
            }
        }

        &__flag-container {
            width: 6.4rem;
            height: 6.4rem;
        }            

        &__challenger-label {
            font-size: 1.4rem;
            margin-top: 0;
        }
    }

    @media (min-width: $break-tablet) and (max-width: $break-desktop) {
        top: -5.4rem;
    }
}
