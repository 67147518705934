.fixtures-nav--small {
    $this: &;
    width: 100%;
    z-index: 12;
    position: relative;
    display: flex;
    position: sticky;
    top: 0;

    // &.isSticky {
    //     position: fixed;
    //     top: 0;
    //     right: 0;
    //     z-index: 12;
    //     width: 100%;
    // }

    &_list {
        border: none;
        width: 100%;
        flex-flow: row nowrap;
        display: flex;

        &--item {
            border: none;
            flex: 1;
            padding: 0;

            &_link {
                text-decoration: none;
                display: block;
                position: relative;
                padding: 1rem 1.5rem;
                text-transform: uppercase;
                font-size: 1.4rem;
                transition: background 0.3s, color 0.3s;
                @include eventColors(background);
                color: $svns-black;
                white-space: nowrap;

                @include hover {
                    background: $svns-white;
                    color: $svns-black;
                }

                &.isActive {
                    color: $svns-white;
                    background: $svns-black;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
    @media (min-width: $break-tablet) {

        &_list {
            &--item {
                &_link {
                    font-size: 1.6rem;
                    padding: 1.5rem;
                }
            }
        }
    }
}
