$twoColsMobile: 1, 4, 7, 10, 13, 16;
$treeColsMobile: 2, 3, 5, 6, 8, 9, 11, 12, 14, 15;
$twoCols: 1, 2, 6, 7, 11, 12, 16;
$treeCols: 3, 4, 5, 8, 9, 10, 13, 14, 15;

.content-promo-rail {
    $this: &;
    position: relative;
    margin: $layout-margin;

    &-container {
        padding: 2rem;
    }

    &--inner {
        display: flex;
        flex-flow: column nowrap;
        flex: 1;
    }

    &-title {
        color: $svns-black;
        font-size: 4.2rem;
        line-height: 1;
        text-transform: uppercase;
        padding: 3rem 0;

        &.isWhiteTitleText {
            color: $svns-white;
        }
    }

    &--list {
        flex: 0 0 100%;
        display: flex;
        flex-flow: row wrap;
        color: $svns-black;
        gap: 2rem 1rem;
        justify-content: center;

        &-item {
            flex: 0 0 100%;
            padding: 0 0 2rem;
            display: flex;
            flex-flow: wrap row;
            background: $svns-black;
            align-content: space-between;

            &.col-3 {
                flex: 0 0 calc(50% - 0.5rem);
            }

            &.col-alternate {
                @each $twoCol in $twoColsMobile {
                    &:nth-child(#{$twoCol}) {
                        flex: 0 0 calc(100% - 0.5rem);
                    }
                }
                @each $treeCol in $treeColsMobile {
                    &:nth-child(#{$treeCol}) {
                        flex: 0 0 calc(50% - 0.5rem);
                        #{$this}--title {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }

    &--header {
        position: relative;
        width: 100%;
    }

    &--image-link {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    &__picture-image {
        position: relative;
        height: auto;
        display: flex;
        width: 100%;
    }

    &__image {
        width: 100%;
        height: auto;
        transition: transform 0.3s, opacity 0.3s;
        transform-origin: center;
    }

    &--content {
        padding: 0;
        color: $svns-white;
        display: flex;
        flex-flow: column wrap;

        #{$this}--tags {
            margin-top: 1.6rem;
        }

        #{$this}--tag {
            color: $svns-white;
            padding: 0;
            margin: 0;
            display: inline-block;
            font-size: 1.2rem;
            width: auto;
        }

        #{$this}--title {
            font-size: 2rem;
            text-transform: uppercase;
            line-height: 1.2;
            padding: 0;
            margin-top: 1.6rem;
        }

        #{$this}--description {
            display: none;
            @include font-dmsans-regular;
        }
    }

    &--footer {
        display: flex;
        justify-content: flex-end;
        flex: 1 0 100%;
        margin-top: 1.6rem;
    }

    &--link {
        text-transform: uppercase;
        text-decoration: none;
        text-align: right;
        font-size: 1.4rem;
        line-height: 1;
        margin: 0;
        margin-right: 2rem;
        color: $svns-white;
        position: relative;
        width: auto;

        &::after {
            content: '';
            position: absolute;
            width: 2rem;
            height: 1.4rem;
            right: -2.2rem;
            top: 0;
            background: url('../i/svg-files/elements/icons/arrow-right.svg')
                center/contain no-repeat;
        }
    }

    @media (min-width: $break-tablet) {
        margin: $layout-margin-mob;

        &-title {
            font-size: 8.4rem;
            padding: 6rem 0;
        }

        &--list {
            gap: 3rem 2rem;

            &-item {
                flex: 0 0 calc(50% - 1rem);

                &.col-3 {
                    flex: 0 0 calc(33.33333% - 2rem);
                }

                &.col-alternate {
                    @each $twoCol in $twoCols {
                        &:nth-child(#{$twoCol}) {
                            flex: 0 0 calc(50% - 2rem);
                            #{$this}--title {
                                font-size: 4.8rem;
                            }
                        }
                    }

                    @each $treeCol in $treeCols {
                        &:nth-child(#{$treeCol}) {
                            flex: 0 0 calc(33.33333% - 2rem);
                            #{$this}--title {
                                font-size: 3.2rem;
                            }
                        }
                    }
                }

                &:hover {
                    #{$this}__picture-image img {
                        transform: translateY(0.3rem) scale(0.99);
                        opacity: 0.7;
                    }
                }
            }
        }

        &--content {
            #{$this}--tags {
                margin-top: 2.4rem;
            }

            #{$this}--tag {
                color: $svns-white;
                padding: 0;
                font-size: 1.8rem;
            }

            #{$this}--title {
                font-size: 4.8rem;
                margin-top: 2.4rem;
                padding: 0;
                line-height: 1;
            }

            #{$this}--description {
                font-size: 1.8rem;
                line-height: 1.8;
                display: block;
                margin-top: 2.4rem;
            }
        }

        &--footer {
            margin-top: 2.4rem;
        }

        &--link {
            font-size: 2.4rem;
            line-height: 1.2;
            margin: 0 3.2rem 0 0;
            transition: transform 0.3s;

            &::after {
                width: 2rem;
                height: 1.6rem;
                right: -2.5rem;
                top: 0.5rem;
                transition: 0.8s ease-in-out background-position;
            }
            @include hover {
                transform: translateX(0.5rem);

                &::after {
                    animation: scrollArrow 0.8s infinite linear;
                }
            }
        }
    }
}
