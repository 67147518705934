/*
 * burger menu
 * state[ mobileNormal, mobileOpen, desktopNormal, desktopOpen ]
 * usage: burger button open on mobile?  =>  @include burgerNav(mobileOpen)
 */

@mixin burgerNav($state) {
    .burger-nav {
        $this: &;
        @if $state == mobileNormal {
            // mobile normal
            // position: absolute;
            // top: 0;
            // right: 0;
            flex-shrink: 0;
            margin-left: auto;
            min-height: 8rem;
            width: 6.5rem;
            height: 100%;
            background: $svns-primary-black;
            border-left: 0.1rem solid $svns-white;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: 2rem;
            gap: 0;
            color: $svns-white;
            transition: background 0.3s, color 0.3s;
            cursor: pointer;

            @media (max-width: $break-tablet) {
                min-height: 6rem;
            }

            &--text {
                position: relative;
                left: 0;
                text-transform: uppercase;
                line-height: 1;
                flex: 0 0 5rem;
                display: none;
                font-size: 1.6rem;
                transition: opacity 0.8s, transform 0.5s;

                &.isClosed {
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    transform: translateX(2rem);
                    left: -0.5rem;
                }
            }

            &-bars {
                flex: 0 0 1.8rem;
                height: 1.4rem;
                width: 1.8rem;
                position: relative;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                transform-origin: center;

                &--bar {
                    width: 100%;
                    height: 0.2rem;
                    background: $svns-white;
                    transition: transform 0.3s, width 0.3s, opacity 0.3s;
                    transform-origin: center;
                }
            }
        } @else if $state == mobileOpen {
            // mobile open
            background: $svns-white;
            color: $svns-primary-black;

            &-bars {
                height: 1.8rem;

                &--bar {
                    background: $svns-primary-black;
                    transform: rotate(45deg) translate(0.5rem, 0.4rem);

                    &:nth-child(2) {
                        transform: translateX(-6.5rem);
                        width: 0;
                        opacity: 0.5;
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg) translate(0.4rem, -0.4rem);
                    }
                }
            }
        } @else if $state == desktopNormal {
            // desktop normal
            width: 13rem;

            &--text {
                display: block;
            }

            &-bars {
                overflow: visible;
            }

            &:hover {
                background: $svns-white;
                color: $svns-primary-black;

                span {
                    background: $svns-primary-black;
                }
            }
        } @else if $state == desktopOpen {
            // desktop open

            &--text {
                position: absolute;
                opacity: 0;
                visibility: hidden;
                transform: translateX(-2rem);
            }

            &--text.isClosed {
                opacity: 1;
                position: relative;
                visibility: visible;
                transform: translateX(0);
            }
        } @else {
            @error 'Unknown state #{$state}.';
        }
    }
}
