$bottom-margin: 5rem;
/* stylelint-disable */
html {
    scroll-behavior: smooth;
}

.video-hero {
    $this: &;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        background: linear-gradient(
            360deg,
            $svns-black 0%,
            rgba(25, 0, 10, 0) 100%
        );
        opacity: 0.7;
        transform: rotate(-180deg);
        z-index: 2;
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        background: rgb($svns-black, 0.7);
        z-index: 1;
    }

    &__container {
        position: relative;
        padding-top: 100vh;
        overflow: visible;
    }

    &__content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity 0.6s;
        pointer-events: none;
        overflow: hidden;
        width: 100vw;

        @media (min-width: $wrapper-width) {
            left: calc((0% - (100vw - $wrapper-width) / 2));
        }
    }

    .bc-video-player {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        min-width: 120rem;
        border-radius: 0;
        .video-js {
            min-height: 100vh;
            min-width: 100vw;
        }

        .video-js .vjs-control-bar,
        .video-js .vjs-dock-text,
        .video-js #vjs-big-pause-button,
        .video-js .vjs-big-play-button {
            display: none;
        }
        .video-js .vjs-tech {
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            min-height: 100vh;
            min-width: 100vw;
            width: auto;
            height: auto;
        }

        @media (max-width: $break-tablet) {
            width: 150%;
        }
    }

    &__play-button {
        position: absolute;
        height: 21.8rem;
        width: 21.8rem;
        background-color: $svns-yellow;
        bottom: 2.4rem;
        right: 2.4rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.6s background-color ease;
        z-index: 2;

        @include hover {
            background-color: $svns-white;
        }
    }

    &__play-btn-container {
        position: absolute;
        overflow: hidden;
    }

    &__play-icn {
        width: 0;
        height: 0;
        border-top: 3rem solid transparent;
        border-bottom: 3rem solid transparent;
        border-left: 5rem solid $svns-black;
        overflow: hidden;

        &:before {
            content: '';
            border-top: 3rem solid transparent;
            border-bottom: 3rem solid transparent;
            border-left: 10rem solid $svns-black;
            height: 0rem;
            width: 10rem;
            position: absolute;
            top: 0rem;
            right: -10rem;
            transform: rotate(15deg);
        }

        &:after {
            content: '';
            border-top: 3rem solid transparent;
            border-bottom: 3rem solid transparent;
            border-left: 10rem solid $svns-black;
            height: 0rem;
            width: 10rem;
            position: absolute;
            top: -0.1rem;
            right: -10rem;
            transform: rotate(345deg);
        }
    }

    &__watch-film {
        height: 100%;
        padding: 1.6rem;
        fill: $svns-black;
        stroke: none;
        width: 21.8rem;
        animation: rotate100 infinite linear 12s reverse;
        transition: 0.4s ease opacity 0.8s;
    }

    &__title {
        margin: 0 2.4rem;
        margin-top: 10.4rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }

    &__info {
        position: absolute;
        left: 0;
        bottom: 0;
        max-width: 70rem;
        padding: 2.4rem;
        z-index: 2;
    }

    &__subtitle {
        font-size: 6.5rem;
        line-height: 1;
        color: $svns-white;
        text-transform: uppercase;
    }

    &__scroll {
        margin-top: 3rem;
        text-transform: uppercase;
        color: $svns-white;
        font-size: 2rem;
        line-height: 1.2;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            width: 3.2rem;
            height: 2.2rem;
            margin-left: 0.4rem;
            background-image: url('../i/svg-files/elements/icons/arrow-right.svg');
            background-size: 65% 100%;
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: 0.8rem;
            transition: 0.8s ease-in-out background-position;
            transform: rotate(90deg) scale(0.7);
        }

        @include hover {
            &::after {
                animation: scrollArrow 0.8s infinite linear;
            }
        }
    }

    &__link {
        text-decoration: none;
    }

    @media (max-width: $break-tablet) {
        &__play-btn-container {
            transform: scale(0.72);
        }

        &__play-button {
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            width: 15.4rem;
            height: 15.4rem;
            right: unset;
            bottom: unset;
        }

        &__play-icn {
            height: 4rem;
            width: 4rem;
        }

        &__watch-film {
            padding: 1rem;
        }

        .bc-video-player {
            .video-js .vjs-tech {
                width: 150%;
            }
        }
    }

    @media (max-width: $break-phablet) {
        &__title {
            margin: 0 1.6rem;
            margin-top: 7.2em;
        }

        &__info {
            max-width: 40rem;
        }

        &__subtitle {
            font-size: 3.2rem;
        }
    }
}

.video-modal-is-active {
    .video-hero__play-button {
        animation: 0.8s buttonExpand forwards;
        .video-hero__watch-film {
            animation: 2s watchFilmRotate forwards;
        }
        .video-hero__play-icn {
            &:before {
                animation: 0.2s arrowTransformTop 1s forwards ease-in-out;
            }
            &:after {
                animation: 0.2s arrowTransformBottom 1s forwards ease-in-out;
            }
        }
    }
}
