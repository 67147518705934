.live-music {
    $this: &;

    &-tape {
        position: absolute;
        bottom: 5rem;
        right: -10rem;
        display: flex;
        width: 30rem;
        transform: rotate(-45deg);
        overflow: hidden;
        flex-flow: row nowrap;
        @include eventColors(background);

        &__list {
            display: flex;
            animation: scroll 100s linear infinite;
            min-width: 200rem;
            padding: 1rem;
            width: 100%;

            &--item {
                flex: 0 0 auto;
                text-transform: uppercase;
                margin: 0 1rem;
                color: $svns-black;
            }

            &:hover {
                animation-play-state: paused;
            }
        }
    }

    @media (min-width: $break-desktop) {
        &-tape {
            width: 50rem;
        }
    }
}
