.no-fixtures {
    display: flex;
    justify-content: center;    
    margin: 0 1rem 2rem;
    padding: 3.5rem 0 .5rem;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        color: $svns-white;
        text-transform: uppercase;
        font-size: 2.4rem;
        line-height: 2.4rem;
        margin-bottom: 2rem;
        text-align: center;
    }

    &__summary {
        color: $svns-white;
        margin-bottom: 2rem;
        text-transform: uppercase;
        text-align: center;
        font-size: 1.2rem;
        line-height: 1.2rem;
    }

    .pill-shaped-button__link {
        width: 100%;
    }

    @media (min-width: $break-tablet) {
        padding-top: 5rem;

        &__title {
            font-size: 4rem;
            line-height: 4rem;
            margin-bottom: 4rem;
        }

        &__summary {
            font-size: 1.4rem;
            line-height: 1.4rem;
            margin-bottom: 3rem;
        }
    }
}