.standings-tape {
    position: absolute;
    bottom: 0;
    left: auto;
    width: 10rem;
    height: calc(100% - 10rem);
    transform-origin: center center;
    overflow: hidden;
    flex-flow: row nowrap;
    background: $svns-black;
    border: 0.1rem solid $svns-white;
    z-index: 4;

    &__list {
        display: flex;
        animation: scrollY 60s linear infinite;
        flex-flow: column wrap;
        width: 100%;
        position: relative;
        height: 825rem;
        padding: 0;

        &--item {
            margin: 0;
            height: 55rem;
            width: 100%;
            writing-mode: vertical-rl;
            text-orientation: mixed;
            line-height: 3;
            text-align: center;
            display: flex;
        }

        &:hover {
            animation-play-state: paused;
        }
    }

    &--link {
        flex: 0 0 auto;
        display: flex;
        gap: 3rem;
        align-items: center;
        text-transform: uppercase;
        font-size: 6.5rem;
        text-decoration: none;
        @include eventColors(color);
    }

    &--logo {
        width: 4.8rem;
        height: 4.8rem;
        stroke: none;
        @include eventColors(fill);
    }

    @media (min-width: $break-tablet) {
        display: none;

        &.isVisible {
            display: flex;
        }
    }
}
