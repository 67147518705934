.fixture-tape {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    width: 100%;
    max-width: fit-content;
    overflow: hidden;
    flex-flow: row wrap;

    &.is-polling {
        animation: 0.8s ease-in-out opacityFadeIn forwards;
    }

    &__container {
        position: relative;
        margin: 2rem 0;
        width: calc(100% + 3.2rem);
        left: -1.6rem;
        padding: 2.4rem 0;
        display: flex;
        @include eventColors(background);

        &--new-event {
            opacity: 0;
            animation: opacityFadeIn 1.2s forwards;
        }
    }

    &__list {
        display: flex;
        animation: scroll 100s linear infinite;
        min-width: 200rem;
        width: 100%;

        &--item {
            flex: 0 0 auto;
            text-transform: uppercase;
            margin: 0 2rem;
            color: $svns-black;
            font-size: 1.4rem;
        }

        &:hover {
            animation-play-state: paused;
        }
    }
    @media (min-width: $break-desktop) {
        &__container {
            width: calc(100% + 4.8rem);
            left: -2.4rem;
            padding: 2.4rem 0;
        }
    }
}

.knockout-tape {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: flex;
    width: 100%;
    max-width: fit-content;
    overflow: hidden;
    flex-flow: row wrap;

    &__container {
        position: relative;
        top: .1rem;
        z-index: 2;
        width: 100%;
        left: 0;
        padding: 2.4rem 0;
        display: flex;
        @include eventColors(background);
    }

    &__list {
        display: flex;
        animation: scroll 100s linear infinite;
        min-width: 200rem;
        width: 100%;
        &--item {
            flex: 0 0 auto;
            text-transform: uppercase;
            margin: 0 2rem;
            color: $svns-black;
            font-size: 1.4rem;
        }
        &:hover {
            animation-play-state: paused;
        }
    }
}
