.fixture-cta {
    padding: 1.6rem;

    &__btn-container:nth-of-type(2) {
        margin-top: .8rem;
    }

    @media (min-width: $break-desktop) {
        display: flex;

        &__btn-container {
            flex: 1;
    
            &:nth-of-type(2) {
                margin: 0 0 0 1rem;
            }
        }
    }
}