.fixtures-hero {
    $this: &;
    display: flex;
    position: relative;
    width: 100%;
    padding: 2rem 1.6rem;

    &--title {
        color: $svns-white;
        font-size: 4.2rem;
        text-transform: uppercase;
        line-height: 1;
    }
    @media (min-width: $break-desktop) {
        padding: 4rem 2.4rem;

        &--title {
            font-size: 8.4rem;
        }
    }
}
