.promo-grid {
    $this: &;
    position: relative;
    margin: $layout-margin;

    &__container {
        padding: 4.8rem 2.4rem;
        overflow: hidden;
    }

    &__content {
        margin-top: 7.2rem;
    }

    &__event {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 2.4rem 0;
        cursor: pointer;

        &:not(:last-of-type) {
            border-bottom: 1px solid $svns-white;
        }

        #{$this}__logo {
            @include eventColors(fill);
        }

        @include hover {
            #{$this}__link {
                display: block;
            }
        }
    }

    &__link {
        display: none;
    }

    &__event-container {
        position: relative;
        display: flex;
        min-width: 32rem;
        min-height: 9rem;
        width: 100%;
    }

    &__logo {
        flex-shrink: 0;
        height: 9.6rem;
        width: 9.6rem;
        margin-right: 2.4rem;
        stroke: none;
    }

    &__event-name {
        display: flex;
        flex-direction: column;
        max-height: 9.6rem;
    }

    &__title,
    &__location {
        font-size: 4.8rem;
        line-height: 1em;
        color: $svns-white;
        text-transform: uppercase;
        margin: -0.4rem 0 0.4rem;
    }

    &__location {
        font-size: 2.4rem;
    }

    &__abbreviation {
        font-size: 8.4rem;
        line-height: 1;
        color: $svns-white;
        text-transform: uppercase;
        margin-left: -0.4rem;
    }

    &__date {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        color: $svns-white;
        text-transform: uppercase;
        font-size: 4.8rem;
        line-height: 1.2;
        max-height: 1em;
    }

    &__cta {
        position: absolute;
        bottom: 0;
        right: 0;
        text-transform: uppercase;
        text-decoration: none;
        text-align: right;
        font-size: 4.8rem;
        transform: translate(0, 22%);
        color: $svns-white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        &::after {
            content: '';
            width: 3.2rem;
            height: 5.6rem;
            margin-left: 1.6rem;
            background-image: url('../i/svg-files/elements/icons/arrow-right.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__ticker {
        display: flex;
        position: absolute;
        height: calc(100% + 0.2rem);
        width: calc(100% + 6%);
        top: -0.1rem;
        left: -3%;
        z-index: 1;
        background-color: $svns-white;
        @include eventColors(background-color);
    }

    &__ticker-content {
        display: flex;
        align-items: center;
        padding: 1rem 0;
        animation: scrollText 8s infinite linear forwards;
    }

    &__hover-text {
        font-size: 6.4rem;
        line-height: 6.4rem;
        margin: 0 10rem;
        color: $black;
        text-transform: uppercase;
        white-space: nowrap;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 3.2rem;
            height: 100%;
            margin-left: 1.6rem;
            background-image: url('../i/svg-files/elements/icons/arrow-right-black.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    @media (max-width: $break-wide) {
        &__event {
            padding: 1.6rem 0;
        }

        &__event-container {
            min-width: 25rem;
            min-height: 7.5rem;
        }

        &__event-name {
            max-height: 7.5rem;
        }

        &__logo {
            height: 7.5rem;
            width: 7.5rem;
            margin-right: 1.6rem;
        }

        &__abbreviation {
            font-size: 6.5rem;
            margin-left: -0.2rem;
        }

        &__title {
            font-size: 3rem;
            max-height: 3rem;
        }

        &__location {
            font-size: 2rem;
            max-height: 2rem;
        }

        &__date {
            font-size: 4rem;
        }

        &__cta {
            font-size: 4rem;
            transform: translate(0, 15%);

            &::after {
                width: 2.2rem;
                height: 3.8rem;
            }
        }
    }

    @media (max-width: $break-desktop) {
        &__date {
            font-size: 3.2rem;
        }

        &__cta {
            font-size: 3.2rem;
            transform: translate(0, 20%);

            &::after {
                width: 2.2rem;
                height: 3.8rem;
                margin-left: 1.2rem;
            }
        }
    }

    @media (max-width: $break-tablet) {
        margin: $layout-margin-mob;

        &__content {
            margin-top: 3.2rem;
        }

        &__date {
            font-size: 2rem;
        }

        &__event {
            justify-content: flex-start;
        }

        &__cta {
            font-size: 2rem;
            max-height: none;
            position: absolute;
            top: 0;
            bottom: -.5rem;
            left: 0;
            right: 0;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin: 0 2rem;
            transform: none;

            &::after {
                width: 1.2rem;
                height: 2.4rem;
                margin-right: -2rem;
            }
        }
    }

    @media (max-width: $break-phablet) {
        &__container {
            padding: 3.2rem 1.6rem;
        }

        &__event-container {
            align-items: flex-start;
            min-width: auto;
        }

        &__event-name {
            height: 7.5rem;
            justify-content: space-between;
        }

        &__abbreviation {
            font-size: 3.2rem;
            margin: -0.4rem 0 0 -0.2rem;
        }

        &__title {
            font-size: 2rem;
            max-height: 2rem;
            margin: 0;
            order: 2;
        }

        &__location {
            font-size: 1.4rem;
            max-height: 1.4rem;
            margin: 0;
            order: 2;
        }

        &__date {
            font-size: 1.4rem;
            text-align: left;
            order: 3;
            position: relative;
        }

        &__cta {
            font-size: 1.4rem;
        }
    }
}