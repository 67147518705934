.standings {
    $this: &;
    position: relative;
    z-index: 2;
    margin: $layout-margin-mob;

    &--container {
        display: flex;
        flex-flow: nowrap column;
        justify-content: flex-start;
        padding: 1rem;
    }

    &--event__link {
        width: 100%;
        display: block;
        padding: 1rem 0;
        transition: transform 0.3s;
        @include hover {
            transform: scale(1.2);
        }
    }

    &--event__logo {
        width: 3rem;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        stroke: none;
        @include eventColors(fill);
    }

    &--team-name {
        transition: opacity 0.3s;
        opacity: 1;
    }

    &--header {
        display: flex;
        justify-content: space-between;
        padding: 0;

        &__list {
            display: flex;
            flex-flow: row nowrap;
            flex: 1;
            gap: 1rem;

            &--item {
                text-transform: uppercase;
                display: flex;
                font-size: 1.4rem;
                color: $svns-white;

                &::before {
                    content: '';
                    position: relative;
                    top: 0.4rem;
                    width: 1.3rem;
                    height: 1.3rem;
                    margin-right: 0.3rem;
                }

                &.gold {
                    &::before {
                        background: $gold;
                    }
                }

                &.silver {
                    &::before {
                        background: $silver;
                    }
                }

                &.bronze {
                    &::before {
                        background: $bronze;
                    }
                }
            }
        }

        &-info {
            flex: 0 0 auto;
            color: $svns-white;
            text-transform: uppercase;
            display: flex;
            gap: 1rem;
            justify-content: flex-end;
            text-indent: -999rem;
            transition: padding 0.3s;
            cursor: pointer;

            &__icon {
                stroke: none;
                fill: $svns-white;
                transition: transform 0.3s;
            }
            @include hover {
                padding-right: 0.4rem;
                #{$this}--header-info__icon {
                    transform: scale(1.2);
                }
            }
        }
    }

    &--table__container {
        position: relative;
    }

    &--table__inner {
        margin-left: 19.4rem;
        scroll-behavior: auto;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        &.isHidden {
            margin-left: 10rem;

            .isSticky {
                width: 3.5rem;
                transition: width 0.3s, left 0.3s;

                &.isSecond {
                    left: 3.5rem;
                    width: 4rem;
                    #{$this}--team-name {
                        opacity: 0;
                    }
                }

                &.isThird {
                    left: 7.5rem;
                    width: 5.5rem;
                    border-right: 0.1rem solid $svns-white;
                }
            }
        }
    }

    &--table {
        margin-top: 2rem;
        width: 70rem;
        border-collapse: collapse;
        border-spacing: 0;
        background: $svns-black;

        &-row {
            border-bottom: solid 0.1rem $svns-white;

            &:nth-of-type(8) {
                border-bottom: none;

                .isSticky {
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -.2rem;
                        right: -.1rem;
                        left: 0;
                        height: 0.4rem;
                        background: $svns-white;
                    }
                }
            }
        }

        &-cell {
            height: 4.6rem;
            text-align: center;
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 400;
            color: $svns-white;

            &__event {
                position: relative;
            }

            #{$this}__event-points {
                position: relative;
                z-index: 1;
            }

            #{$this}-cell--logo {
                position: absolute;
                top: 0;
                left: 0;
                width: 15rem;
                height: 15rem;
                stroke: none;
                opacity: 0.1;
                z-index: 0;
            }

            & .isSticky {
                line-height: 1;
                position: absolute;
                z-index: 2;
                width: 4rem;
                height: 4.6rem;
                left: 0;
                top: auto;
                vertical-align: top;
                margin-top: -2.25rem;
                background: $svns-black;
                display: flex;
                align-items: center;
                border-bottom: 0.1rem solid $svns-white;
                transition: none;

                &.isSecond {
                    left: 4rem;
                    width: 10rem;
                }

                &.isThird {
                    left: 14rem;
                    width: 6rem;
                    border-right: 0.1rem solid $svns-white;
                }
            }

            &--points,
            &--team,
            &--pos {
                width: 0 !important;
            }


            &--points,
            &--total {
                font-size: 2.5rem;
            }

            &.isLast {
                display: none;
            }
        }

        & .gold,
        & .silver,
        & .bronze {
            color: $svns-black;
            position: relative;
            overflow: hidden;
        }

        & .gold {
            background-color: $gold;
        }

        & .silver {
            background-color: $silver;
        }

        & .bronze {
            background-color: $bronze;
        }
    }

    &--team {
        &--flag {
            width: 2rem;
            border: 0.1rem solid $white;
            transition: transform 0.3s;
        }

        &-link {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 2.5rem;
            text-decoration: none;
            line-height: 1;
            transition: padding 0.3s;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 0.1rem;
                background: $svns-white;
                transition: width 0.3s;
            }
            @include hover {
                padding-left: 0.3rem;

                &::after {
                    width: 100%;
                }
            }
        }

        &-position {
            font-size: 2.5rem;
            line-height: 1;
        }
    }

    &--relegation-line {
        td {
            height: 0;

            &::before {
                content: '';
                position: relative;
                display: block;
                margin-top: -.3rem;
                margin-bottom: -.1rem;
                height: .4rem;
                background-image: linear-gradient(90deg, $svns-black 25%, $svns-white 25%, $svns-white 50%, $svns-black 50%, $svns-black 75%, $svns-white 75%, $svns-white 100%);
                background-size: 8rem 8rem;
            }
        }
    }

    @media (min-width: $break-tablet) {
        margin: $layout-margin;

        &--relegation-line {
            td {
                &::before {
                    background-image: linear-gradient(90deg, $svns-white 25%, $svns-black 25%, $svns-black 50%, $svns-white 50%, $svns-white 75%, $svns-black 75%, $svns-black 100%);
                }
            }
        }

        &--header {
            display: flex;
            justify-content: space-between;
            padding: 0;

            &__list {
                display: flex;
                flex-flow: row nowrap;
                flex: 1;
                gap: 1rem;
            }

            &-info {
                flex: 0 0 auto;
                color: $svns-white;
                text-transform: uppercase;
                display: flex;
                gap: 1rem;
                justify-content: flex-end;
                text-indent: -999rem;
                cursor: pointer;

                &__icon {
                    stroke: none;
                    fill: $svns-white;
                }
            }
        }

        &--header {
            padding: 0 2rem;

            &__list {
                &--item {
                    font-size: 1.8rem;
                }
            }

            &-info {
                flex: 0 0 auto;
                text-indent: 0;
            }
        }

        &--event__logo {
            width: 5rem;
        }

        &--table {
            width: 100%;

            th {
                font-size: 2.2rem;
            }

            &-row {
                &:nth-of-type(8) {
                    .isSticky {
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            &-cell {
                padding: 1.2rem 0;
                height: 8rem;
                font-size: 3.2rem;
                overflow: hidden;
                position: relative;

                #{$this}-cell--logo {
                    width: 30rem;
                    height: 30rem;
                }

                & .isSticky {
                    position: relative;
                    width: auto;
                    height: auto;
                    margin-top: 0;
                    display: flex;
                    border: none;
                    transition: none;

                    &.isSecond {
                        left: auto;
                        width: auto;
                    }

                    &.isThird {
                        display: none;
                    }
                }

                &--pos {
                    text-align: center;
                }

                &__pos {
                    width: 12rem;
                }

                &__team {
                    width: 28rem;
                }

                &--points,
                &__points {
                    display: none;
                }

                &--total {
                    font-size: 6.5rem;
                }

                &.isLast {
                    display: revert;
                }
            }
        }

        &--table__inner {
            margin-left: 0;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            &.isHidden {
                margin-left: 0;

                .isSticky {
                    width: auto;
                    transition: width 0.3s, left 0.3s;

                    &.isSecond {
                        left: auto;
                        width: auto;
                        #{$this}--team-name {
                            opacity: 0;
                        }
                    }

                    &.isThird {
                        left: 0;
                        width: 0;
                        border-right: none;
                    }
                }
            }
        }

        &--team {
            &--flag {
                width: 4.6rem;
            }

            &-link {
                flex-flow: row wrap;
                align-items: baseline;
                gap: 1rem;
                font-size: 6.5rem;
            }

            &-position {
                font-size: 6.5rem;
                line-height: 1;
            }
        }
    }
}
