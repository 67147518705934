@include arrow;

.main-nav-btn {
    $this: &;
    min-width: calc(100% - 18.5rem);
    padding-left: 1.5rem;
    min-height: 6rem;
    border-right: 0.1rem solid $svns-white;
    text-transform: uppercase;
    color: $svns-white;
    background: $svns-primary-black;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 100%;
        background: $svns-primary-black;
        opacity: 0;
        z-index: 1;
        transition: width 0.3s, opacity 0.3s;
    }

    .arrow {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 3rem;
        transition: all 0.3s;
        z-index: 2;

        &::after,
        &::before {
            background: $svns-white;
        }
    }

    &:hover {
        background: $svns-white;
        color: $svns-primary-black;
        // stylelint-disable-next-line selector-max-specificity

        .arrow {
            margin-top: 0.2rem;
            // stylelint-disable-next-line selector-max-specificity

            &::after,
            &::before {
                background: $svns-primary-black;
            }
        }

        &::after {
            width: 100%;
            opacity: 0.1;
        }
    }

    &.isTransparent {
        background: none;
        // stylelint-disable-next-line selector-max-specificity

        &:hover {
            background: $svns-white;
            color: $svns-primary-black;
        }
    }

    &.isEvent {
        color: $svns-primary-black;
        display: flex;
        flex-direction: row;
        #{$this}--logo {
            fill: $svns-primary-black;
            width: 2.4rem;
            height: 2.4rem;
            z-index: 4;
            stroke: none;
        }
        // stylelint-disable-next-line selector-max-specificity

        .arrow {
            // stylelint-disable-next-line selector-max-specificity

            &::after,
            &::before {
                background: $svns-primary-black;
            }
        }

        #{$this}--abbreviation {
            font-size: 3rem;
            text-transform: uppercase;
            color: $svns-primary-black;
            margin-left: 0.3rem;
            font-weight: 700;
            z-index: 3;
        }
        #{$this}--code {
            width: 7rem;
            height: 3rem;
            stroke: none;
        }

        #{$this}--infos {
            z-index: 5;
            display: none;
        }
    }
    @include eventColors(background);

    @media (min-width: $break-tablet) {
        min-width: 40rem;
        min-height: 8rem;
        padding: 2rem;

        &.isEvent {
            #{$this}--logo {
                width: 3rem;
                height: 3rem;
                min-width: 3rem;
            }

            #{$this}--code {
                width: 9rem;
                height: 3rem;
            }

            #{$this}--infos {
                display: flex;
                flex-direction: column;
                color: $svns-primary-black;
                margin-left: 2rem;
                font-size: 1.4rem;
                line-height: 1.2;
                text-transform: uppercase;
            }
        }
    }
}
