.video__rail-modal {
    $this: &;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    background: $svns-black;
    padding: 7rem 1rem 0;
    display: none;
    flex-flow: column nowrap;
    transform: translateY(100%);
    filter: blur(5rem);
    transition: opacity .5s, transform .5s, visibility .5s, filter .5s;

    &.isVisible {
        transform: translateY(0);
        filter: blur(0);
        display: flex;
    }

    &-header {
        width: 100%;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        left: 0;
        border-bottom: .1rem solid $svns-white;
        color: $svns-white;
        text-align: right;
    }

    &-close {
        cursor: pointer;
        padding: 2rem 5rem;
        text-transform: uppercase;
        display: inline-block;
        transition: padding .3s;
        font-size: 2rem;

        &::after,
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: 3rem;
            width: .2rem;
            height: 2rem;
            background: $svns-white;
            transform-origin: top;
            transition: transform .3s;
            transform: rotate(-45deg) translateY(-50%);
        }

        &::after {
            transform: rotate(45deg) translateY(-50%);
        }
        @include hover {
            padding-right: 5.5rem;

            &::after {
                transform: rotate(-45deg) translateY(-50%);
            }

            &::before {
                transform: rotate(45deg) translateY(-50%);
            }
        }
    }

    &-inner {
        display: flex;
        flex-flow: column;
        width: 100%;
        height: 100%;
        gap: 0;
        opacity: 0;

        &.isVisible {
            animation: opacityFadeIn 1s forwards .5s;
        }
    }

    &-content {
        flex: 0 0 auto;
        height: auto;
        min-height: 35vh;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
    }

    &-video {
        opacity: 0;
        animation: opacityFadeIn 1s forwards;
        position: relative;
    }

    &-list {
        flex: 1;
        display: flex;
        flex-flow: column;
        border-left: none;
        scroll-behavior: smooth;
        overflow: auto;
        max-height: 70vh;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-body {
        position: relative;
        width: 100%;
        padding-bottom: 2rem;
        display: flex;
        flex-flow: column;
    }

    &-title {
        color: $white;
        font-size: 3.2rem;
        margin: 3.2rem 0;
        text-transform: uppercase;
        line-height: 1.2;
    }

    &-date-social {
        display: flex;
        align-items: center;
        margin-bottom: 3.2rem;
    }

    &-date {
        color: $white;
        font-size: 1.8rem;
        display: flex;
        align-items: center;
        gap: 1.8rem;
    }

    &-date-render {
        text-transform: uppercase;

        .date__unit--day {
            display: none;
        }
    }

    &-social {
        margin-left: auto;

        .social-share {
            display: flex;
            align-items: center;
            margin-left: auto;

            &.article__share {
                margin: 0;

                .social-share__list {
                    margin-top: 0;

                    .social-share__list-item {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &-tags {
        text-transform: uppercase;
    }

    &-desc {
        @include font-dmsans-regular;
        color: $white;
        font-size: 1.6rem;
        padding-bottom: 3.2rem;
    }

    .social-share__copy-message {
        color: $svns-white;
        margin-top: .8rem;
    }

    @media (min-width: $break-desktop) {
        flex-flow: row nowrap;
        padding: 8.5rem 2rem 2rem;

        &-inner {
            flex-flow: row;
            gap: 2rem;
        }

        &-content {
            flex: 1;
            scroll-behavior: smooth;
            overscroll-behavior: contain;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        &-list {
            flex: 0 0 30%;
            max-height: 100vh;
            border-left: .1rem solid $svns-white;
        }
    }


    @media (max-width: $break-desktop) {
        position: absolute;    
        height: auto;

        &-list {
            max-height: none;
        }
    }
}
