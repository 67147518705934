$arrowWhite: '../i/svg-files/elements/icons/arrow-right-white.svg';

.hospitality-gallery {
    $this: &;
    padding: 2rem;
    flex: 1;

    @supports (position: sticky) {
        position: sticky;
        align-self: flex-start;
        top: 5rem;
        padding-bottom: 4rem;
    }

    &__photo-section {
        flex: 1;
        position: relative;
    }

    &__header {
        margin-bottom: 3.2rem;
    }

    &__header-title {
        color: $black;
        font-size: 1.8rem;
        line-height: 2.3rem;
        text-transform: uppercase;
        transition: transform 0.5s;
    }

    &__image-container {
        overflow: hidden;
        position: relative;
    }

    &__items-wrapper {
        display: flex;
        flex-wrap: nowrap;
        max-width: 100%;
        transition: ease-in-out 0.6s margin;
    }

    &__image {
        display: block;
        height: auto;
        width: 100%;
        object-fit: cover;
    }

    &__item {
        overflow: hidden;
        flex-direction: column;
        min-width: 100%;
    }

    &__picture-wrapper {
        position: relative;
    }

    &__picture-image {
        overflow: hidden;
        position: relative;
    }

    &__controls-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        bottom: -3rem;
        position: relative;
        transition: 0.8s ease-in-out margin;
    }

    &__nav-button {
        border: none;
        background-color: transparent;
        font-size: 5rem;
        transition: 0.3s ease opacity, 0.3s ease background-color;
        color: $svns-white;
        cursor: pointer;

        &.no-hover-state {
            pointer-events: none;
        }

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 4rem;
            height: 3.5rem;
            background: url($arrowWhite) center/cover no-repeat;
            transition: 0.8s ease-in-out background-position;
        }

        &--previous {
            opacity: 0.4;
            padding-left: 6rem;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
                width: 4rem;
                height: 3.5rem;
                margin-left: 0.4rem;
                background: url($arrowWhite) center/cover no-repeat;
                transition: 0.8s ease-in-out background-position;
            }

            &::after {
                display: none;
            }
        }

        &--next {
            padding-right: 6rem;
        }

        &:not(.no-hover-state) {
            @include hover {
                &::before {
                    animation: scrollArrow 0.8s infinite linear;
                }

                &::after {
                    animation: scrollArrow 0.8s infinite linear;
                }
            }
        }
    }

    @media (max-width: $break-desktop) {
        position: relative;
        top: 0;

        &__container {
            flex-direction: column;
            position: relative;
        }

        &__info {
            padding-right: 0;
        }

        &__photo-section {
            padding-left: 0;
        }
    }

    @media (max-width: $break-phablet) {
        &__container {
            padding: 3.2rem 1.6rem;
        }

        &__controls-container {
            position: absolute;
            bottom: auto;
            top: 0;
            width: 100%;
            height: 100%;
        }

        &__nav-button {
            text-indent: -999rem;
            width: 50%;
            height: 100%;

            &::after,
            &::before {
                width: 3rem;
                height: 2.5rem;
            }

            &::before {
                left: 1rem;
            }

            &::after {
                right: 1rem;
            }
        }

        &__image {
            max-height: 25rem;
        }

        &__header {
            margin-bottom: 2.4rem;
        }

        &__header-title {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }
}
