.ticket-header {
    $this: &;
    padding: 1rem 0;
    width: 100%;
    margin: 0 auto;

    &__bg {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 200rem;
        height: 200rem;
        stroke: none;
        @include eventColors-secondary(fill);
        z-index: 0;
    }

    &__title {
        font-size: 4.2rem;
        text-transform: uppercase;
        line-height: 1;
        padding: 2rem 0;
        color: $svns-black;
    }

    &__subtitle {
        display: block;
        font-size: 1.8rem;
        position: relative;
        color: $svns-black;

        .ticketmaster-logo {
            width: 12rem;
            height: 2rem;
            stroke: none;
            display: inline-block;
        }
    }

    &__container {
        display: flex;
        flex-flow: nowrap column;
        padding: 0 2rem;
        background: transparent;
        position: relative;
        z-index: 1;
    }

    &_ctas {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
    }

    &_link {
        text-align: center;
        text-transform: uppercase;
        border-radius: 10rem;
        padding: 1.55rem 2.2rem;
        font-size: 1.4rem;
        background: $svns-black;
        color: $svns-white;
        text-decoration: none;
        flex: 1;
        max-width: 49%;
    }

    &_list {
        max-width: 49%;
        display: flex;
        flex: 1;
        flex-flow: row nowrap;
        border: 0.1rem solid $svns-black;
        border-radius: 10rem;
        padding: 0.3rem;
        position: relative;

        &--item {
            flex: 1;
            text-align: center;
            text-transform: uppercase;
            border-radius: 10rem;
            padding: 1.2rem 0;
            font-size: 1.4rem;
            z-index: 2;
            transition: color 0.3s;
            cursor: pointer;

            &.isActive {
                color: $svns-white;
            }
        }
    }
    #{$this}_list--bg {
        position: absolute;
        top: 0.2rem;
        left: 0;
        transform: translateX(0.3rem);
        width: calc(50% - 0.3rem);
        height: calc(100% - 0.4rem);
        background: #19000a;
        border-radius: 4rem;
        z-index: 0;
        transition: transform 0.3s;
    }

    @media (min-width: $break-tablet) {
        padding: 0;

        &__container {
            padding: 2rem;
        }

        &__title {
            font-size: 8.2rem;
        }

        &__subtitle {
            font-size: 2.2rem;

            .ticketmaster-logo {
                width: 18rem;
                height: 2.5rem;
            }
        }

        &_ctas {
            display: none;
        }
    }
}
