.logo-carousel {
    $this: &;
    height: auto;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    opacity: 0;
    padding: 1.5rem 0;

    &.fade-in {
        animation: opacityFadeIn 1.5s forwards 0.5s;
    }

    &-title {
        color: $svns-white;
        line-height: 1;
        padding: 1.5rem;
        font-size: 2rem;
        text-transform: uppercase;
        text-align: left;
        max-width: $wrapper-width;
        margin: 0 auto;

        &.toRight {
            text-align: right;
        }

        &.toLeft {
            text-align: left;
        }
    }

    &-track,
    &-track.toRight,
    &-track.toLeft {
        animation: scroll 80s linear infinite;
        display: flex;
        width: 100%;
        min-width: 90rem;
    }

    &-track.toLeft {
        animation: scroll 80s linear reverse infinite;
    }

    &-slide {
        flex: 0 0 12.5%;

        &--link {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s;
            background: $svns-white;
            border-radius: 30rem;
            margin: 0 0.5rem;
            max-width: 15rem;
        }

        &--img {
            height: 4.5rem;
            width: 100%;
            transform: scale(0.65);
        }

        &.cms-images {

            #{$this}-slide__picture-image {
                position: relative;
                height: 4.2rem;
                width: 9.7rem;
            }

            #{$this}-slide__image {    
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate( -50%, -50% );
                padding: 2rem;
            }
        }

        &.small {

            #{$this}-slide--link {
                min-width: 6rem;
                margin: 0 0.8rem;
            }

            #{$this}-slide--img {
                height: 3.5rem;
                width: 100%;
            }

            &.cms-images {
    
                #{$this}-slide__picture-image {
                    height: 3.2rem;
                    width: 7.4rem;
                }
    
                #{$this}-slide__image {    
                    padding: 1rem;
                }
            }
        }
    }
    @media (min-width: $break-tablet) {

        &-title {
            font-size: 2.4rem;
        }

        &-track {
            min-width: 180rem;

            &:hover {
                animation-play-state: paused;
            }
        }

        &-slide {
            &--link {
                margin: 0 0.5rem;
                max-width: 22rem;
                min-width: 18rem;

                &:hover {
                    transform: scale(1.1);
                }
            }

            &--img {
                height: 7.5rem;
                width: 100%;
            }

            &.cms-images {
    
                #{$this}-slide__picture-image {
                    height: 8.2rem;
                    width: 19.4rem;
                }
    
                #{$this}-slide__image {    
                    padding: 4rem;
                }
            }

            &.small {
                flex: 0 0 6.25%;

                #{$this}-slide--link {
                    min-width: 8rem;
                    max-width: 15rem;
                }

                #{$this}-slide--img {
                    height: 4.5rem;
                    width: 100%;
                }

                &.cms-images {
        
                    #{$this}-slide__picture-image {
                        height: 5rem;
                        width: 11.7rem;
                    }
        
                    #{$this}-slide__image {    
                        padding: 2rem;
                    }
                }
            }
        }
    }
}
