$cookie-black: #19000a !important;
$toggle-border: #a7a4a4;
$link: #0000ff !important;

/* stylelint-disable */
#onetrust-pc-sdk,
#onetrust-banner-sdk {
    &.otPcCenter,
    &.otCenterRounded {
        left: unset !important;
        top: unset !important;
        right: 0 !important;
        bottom: 0 !important;
        z-index: 5 !important;
    }

    &.otCenterRounded {
        width: 70% !important;
        max-width: 750px !important;
    }

    a {
        color: $link;
    }

    #ot-pc-title,
    #ot-category-title,
    p {
        font-size: 1.8rem !important;
        line-height: 2.4rem !important;
        color: $cookie-black;
    }

    #ot-pc-title,
    #ot-category-title,
    .ot-cat-header,
    h2 {
        font-size: 1.8rem !important;
        line-height: 2.4rem !important;
        color: $cookie-black;
        text-transform: uppercase !important;
    }

    #ot-pc-desc,
    .ot-category-desc,
    .ot-always-active {
        font-size: 1.8rem !important;
        line-height: 2.4rem !important;
        color: $cookie-black;
    }

    .ot-acc-grpcntr {
        background: $white !important;
    }

    .save-preference-btn-handler,
    .ot-pc-refuse-all-handler,
    #accept-recommended-btn-handler,
    .onetrust-reject-all-handler,
    .onetrust-accept-all-handler,
    #onetrust-pc-btn-handler,
    #onetrust-reject-all-handler,
    #onetrust-accept-btn-handler {
        background-color: $cookie-black;
        border: 2px solid $cookie-black;
        border-radius: 5rem !important;
        color: $white !important;
        text-transform: uppercase !important;
        font-size: 1.6rem !important;
        transition: 0.3s background-color ease, 0.3s color ease !important;
        white-space: nowrap;
    }

    #onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob:before {
        background-color: $cookie-black !important;
        border-color: $cookie-black !important;
    }

    #onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
        background-color: $white;
        border: 1px solid #3860be;

        &:before {
            background-color: $cookie-black !important;
            border-color: $cookie-black !important;
        }
    }

    .ot-btn-container button,
    #accept-recommended-btn-handler,
    #onetrust-pc-btn-handler,
    #onetrust-accept-btn-handler,
    #onetrust-reject-all-handler {
        transition: 0.3s ease background-color, 0.3s ease color;

        @include hover {
            opacity: 1 !important;
            background-color: $white !important;
            color: $black !important;
        }
    }

    #onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
        background-color: $svns-white !important;
        border: 1px solid $toggle-border !important;
    }

    .ot-pc-footer-logo {
        background: $white !important;
    }

    .ot-tgl input:checked + .ot-switch .ot-switch-nob {
        background-color: $svns-white !important;
        border: 1px solid $black !important;

        &:before {
            background-color: black !important;
        }
    }

    @media (max-width: $break-wide) {
        .save-preference-btn-handler,
        .ot-pc-refuse-all-handler,
        #accept-recommended-btn-handler,
        .onetrust-reject-all-handler,
        .onetrust-accept-all-handler,
        #onetrust-pc-btn-handler,
        #onetrust-reject-all-handler,
        #onetrust-accept-btn-handler {
            font-size: 1.4rem !important;
        }

        .banner-actions-container {
            width: 100% !important;
            text-align: center !important;
            max-width: 100% !important;
        }

        #onetrust-pc-btn-handler {
            float: none !important;
        }
    }

    @media (max-width: $break-tablet) {
        &.otCenterRounded {
            width: 95% !important;
            max-width: 840px !important;
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            width: 100% !important;
            transform: none !important;
            max-height: 100% !important;
        }

        #ot-pc-desc,
        .ot-category-desc,
        .ot-always-active,
        #ot-pc-title,
        #ot-category-title,
        .ot-cat-header,
        p,
        h4 {
            font-size: 1.6rem !important;
            line-height: 2rem !important;
        }
    }
}
#ot-sdk-btn-floating.ot-floating-button {
    z-index: 4 !important;
}
.onetrust-pc-dark-filter {
    z-index: 3 !important;
}
