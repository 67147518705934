.fixture-lineup {
    $this: &;
    color: $svns-white;
    text-transform: uppercase;
    position: relative;
    display: flex;
    flex-flow: column nowrap;

    &__container {
        display: flex;
        flex-flow: nowrap;
        gap: 0 2.4rem;
    }

    &__right,
    &__left {
        flex: 1;

        &.isHidden {
            display: none;
        }
    }

    .isSubbedOn,
    .isSubbedOff {
        width: 2.2rem;
        height: 1.1rem;
        top: 0.4rem;
        position: relative;
        display: inline-block;

        &::after,
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 0.2rem;
        }

        &::before {
            left: -0.6rem;
        }
    }

    .isSubbedOn {

        &::before {
            background: $cape-town;
            transform: rotate(-45deg);
        }

        &::after {
            background: $cape-town;
            transform: rotate(45deg);
        }
    }

    .isSubbedOff {

        &::after {
            background: $dubai;
            transform: rotate(-45deg);
        }

        &::before {
            background: $dubai;
            transform: rotate(45deg);
        }
    }

    &__right {
        text-align: right;

        #{$this}__player {
            flex-direction: row-reverse;
        }

        #{$this}__player--icons {
            display: inline-block;
            width: 2.5rem;
            height: 2.5rem;
            stroke: none;
            position: relative;
        }
        #{$this}__player--sub {
            position: absolute;
            bottom: 2.4rem;
            right: auto;
            left: 9.5rem;
            display: flex;
            flex-direction: row-reverse;
        }
        #{$this}__player--scoring {
            justify-content: flex-end;
        }

        .isSubbedOn,
        .isSubbedOff {
            top: 1rem;
            left: 0.5rem;
        }
    }

    &__player {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 1.6rem 0;
        border-bottom: 0.1rem solid $svns-white;

        &--info {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
        }

        &--name {
            font-size: 1.6rem;
            position: relative;
        }

        &--label {
            font-size: 1.4rem;
        }

        &--scoring {
            display: flex;
            align-items: center;
        }

        &--icons {
            display: inline-block;
            width: 2.5rem;
            height: 2.5rem;
            stroke: none;
            position: relative;
        }

        &--sub {
            position: absolute;
            bottom: 1.6rem;
            right: 8rem;    
            
            &.isSubbedOff-lineups {
                    color: $dubai;
            }    
            
            &.isSubbedOn-lineups {
                    color: $cape-town;
            }
        }
    }

    &__image {
        width: 6.4rem;
        height: 6.4rem;
        display: flex;
        position: relative;
        overflow: hidden;
        border: 0.1rem solid $svns-white;

        .player__picture-image {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;

            &.no-image {
                background: url(../i/svg-files/themes/player-placeholder.svg)
                    bottom/cover no-repeat;
                background-size: 5.5rem 5.5rem;
                opacity: 0.6;
                filter: drop-shadow(0 0 2rem rgba($svns-black, 0.2));
            }
        }

        .player__image {
            width: 100%;
            height: auto;
            transform: scale(1);
            transform-origin: top;
            filter: drop-shadow(0 0 0.5rem rgba(25 0 10 / 20%));
            transition: transform 0.3s;
        }
    }

    &__sun {
        position: absolute;
        top: -6.5rem;
        left: -7.5rem;
        z-index: 1;
        width: 27rem;
        height: 27rem;
        stroke: none;
        fill: rgb($svns-black, 0.09);
        @include teamsColors(background);
    }

    @media (min-width: $break-desktop) {
        &__player {
            padding: 2.4rem 0;

            &--name {
                font-size: 2.4rem;
                position: relative;
            }

            &--label {
                font-size: 1.8rem;
            }

            &--sub {
                bottom: 2.4rem;
            }
        }

        .fixture-modal__toggle {
            display: none;
        }

        &__right,
        &__left {
            flex: 1;

            &.isHidden {
                display: block;
            }
        }

        &__image {
            width: 8rem;
            height: 8rem;

            .player__picture-image {
                &.no-image {
                    background-size: 7rem 7rem;
                }
            }
        }
    }
}
