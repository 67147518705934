.content-listing {
    $this: &;
    position: relative;
    margin: $layout-margin;

    &__container {
        padding: 4.8rem 2.4rem;
        overflow: visible;
    }

    &__content {
        position: relative;
        display: flex;
        padding-top: 4.8rem;
    }

    &__feature {
        flex: 1;
        padding: 0 1.2rem 0 0;

        #{$this} {
            &__item {
                width: 100%;
            }
        }
    }

    &__list {
        position: relative;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;

        #{$this} {
            &__link {
                padding: 0 0.6rem 3.2rem 1.2rem;
                width: 50%;

                &:nth-of-type(even) {
                    padding: 0 0 3.2rem 1.8rem;
                }
            }

            &__duration {
                font-size: 1.4rem;
                line-height: 1;
                padding: 0.9rem 1.6rem;
            }

            &__play {
                height: 3.2rem;
                width: 3.2rem;
            }
        }
    }

    &__link {
        text-decoration: none;
        text-underline-offset: 0.3rem;
        transition: 0.4s text-decoration ease-in-out;

        @include hover {
            #{$this}__meta-title {
                text-decoration: underline;
                text-decoration-thickness: 0.1rem;
            }
        }
    }

    &__picture-wrapper {
        position: relative;
        padding-top: 75%;

        &--100 {
            padding-top: 100%;
        }

        &--75 {
            padding-top: 75%;
        }

        &--56 {
            padding-top: 56.25%;
        }
    }

    &__watch {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $svns-video-yellow;
        padding: 1.1rem 1.6rem;
        font-size: 1.8rem;
        line-height: 1;
        color: $svns-primary-black;
        text-transform: uppercase;
    }

    &__duration-container {
        display: flex;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    &__duration {
        color: $svns-black;
        background: $svns-white;
        font-size: 2.4rem;
        line-height: 1.6rem;
        padding: 1.6rem;
    }

    &__play {
        position: relative;
        height: 4.8rem;
        width: 4.8rem;
        background: $svns-video-yellow;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: url('../i/svg-files/elements/icons/play.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__picture-image {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        transition: transform 0.5s;
    }

    &__image {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: 0.6s transform ease-in-out, 0.6s filter ease-in-out;
    }

    &__item {
        overflow: hidden;
        flex-direction: column;
        color: $svns-white;
        padding-bottom: 3.2rem;
    }

    &__meta-title {
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin: 1.6rem 0 0.8rem;
        text-transform: uppercase;
        @include truncate-multiline(3, 2.8rem);
    }

    &__meta-info {
        display: flex;
    }

    &__meta-subtitle,
    &__meta-date {
        position: relative;
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-transform: uppercase;

        &.infoItem:nth-of-type(2) {
            margin-left: 3rem;
    
            &:before {
                content: '';
                left: -1.6rem;
                top: .2rem;
                position: absolute;
                width: .2rem;
                height: 1.4rem;
                background-color: $svns-faded-white;
    
            }

        }
    }

    &:not(.is-rail) {
        @media (min-width: $break-phablet) {
            @supports (position: sticky) {
                #{$this}__feature {
                    position: sticky;
                    align-self: flex-start;
                    top: 2.4rem;
                }
            }
        }
    }

    @media (max-width: $break-tablet) {
        margin: $layout-margin-mob;

        &__watch {
            padding: 0.5rem 0.8rem;
            font-size: 1.4rem;
            line-height: 1;
        }

        &__meta-title {
            font-size: 1.6rem;
            line-height: 1.2;
            margin-top: 0.8rem;
        }

        &__meta-subtitle,
        &__meta-date {
            font-size: 1.2rem;
            line-height: 1.2;
        }
    }

    @media (max-width: $break-phablet) {
        &__container {
            padding: 3.2rem 1.6rem;
        }

        &__content {
            padding-top: 3.2rem;
            flex-direction: column;
        }

        &__item {
            padding-bottom: 0;
        }

        &__feature {
            padding: 0;

            #{$this} {
                &__meta-title {
                    margin: 1.6rem 0;
                    font-size: 2.4rem;
                    line-height: 2.8rem;
                }

                &__duration {
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    padding: 0.4rem 0.8rem;
                }

                &__play {
                    height: 2.4rem;
                    width: 2.4rem;
                }
            }
        }

        &__list {
            #{$this} {
                &__link {
                    &:nth-of-type(odd) {
                        padding: 0 0.8rem 3.2rem 0;
                    }

                    &:nth-of-type(even) {
                        padding: 0 0 3.2rem 0.8rem;
                    }
                }

                &__meta-title {
                    margin: 0.8rem 0;
                    font-size: 1.6rem;
                    line-height: 2rem;
                }

                &__duration {
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    padding: 0.4rem 0.8rem;
                }

                &__play {
                    height: 2.4rem;
                    width: 2.4rem;
                }
            }
        }
    }
}
