body {
    @include eventColors(background);

    &.cpt-svns {
        .main-nav--small::before {
            background-color: $cape-town;
        }
    }

    &.dxb-svns {
        .main-nav--small::before {
            background-color: $dubai;
        }
    }

    &.hkg-svns {
        .main-nav--small::before {
            background-color: $hong-kong;
        }
    }

    &.lax-svns {
        .main-nav--small::before {
            background-color: $los-angeles;
        }
    }

    &.mad-svns {
        .main-nav--small::before {
            background-color: $madrid;
        }
    }

    &.sgp-svns {
        .main-nav--small::before {
            background-color: $singapore;
        }
    }

    &.per-svns {
        .main-nav--small::before {
            background-color: $perth;
        }
    }

    &.van-svns {
        .main-nav--small::before {
            background-color: $vancouver;
        }
    }
}

.main-nav {
    $this: &;
    display: flex;
    position: fixed;
    width: 100%;
    top: var(--header-height);
    height: calc(100% - var(--header-height));
    left: 0;
    z-index: 11;
    filter: blur(0.5rem);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transform: translate(0, -100%);
    transform-style: preserve-3d;
    transform-origin: top;
    transition: all 0.5s ease-out;
    transition-delay: 0.1s;
    background: $svns-primary-black;

    &_list {
        border-bottom: 0.1rem solid $svns-white;
        max-height: 100%;
        display: flex;
        flex-flow: column wrap;
        width: 100%;

        &--item {
            flex: 1;
            color: $svns-white;
            border-bottom: 1px solid $svns-white;
            transition: background 0.5s;
            overflow: hidden;
            display: flex;
            align-items: center;

            // stylelint-disable-next-line selector-max-specificity

            &:nth-last-child(-n + 1) {
                border-bottom: none;
            }

            &:hover {
                #{$this}_list--link {
                    color: $svns-primary-black;
                }
                #{$this}_list--logo {
                    fill: $svns-primary-black;
                }
                @include eventColors(background);
            }
        }

        &--event-container {
            position: relative;
            text-transform: uppercase;
            font-size: 1.4rem;
            margin-left: 1rem;
            width: calc(100% - 6rem);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &--logo {
            width: 14%;
            height: 100%;
            fill: $svns-white;
            stroke: none;
            transition: fill 0.5s;
        }

        &--link {
            display: flex;
            flex-direction: row;
            text-decoration: none;
            padding: 1.5rem;
            flex: 0 0 100%;
            transition: color 0.5s;
            height: 100%;
            // height: calc(13.5vh - 1.5rem);
        }

        &--abbreviation {
            font-size: 3rem;
            line-height: 1;
            margin: 0;
            padding: 0;
        }

        &--date {
            position: absolute;
            bottom: 0.2rem;
            right: 0;
            line-height: 1;
        }
    }

    &--small {
        width: 100%;
        z-index: 10;
        pointer-events: all;
        visibility: visible;
        transform: translate(0, 0);
        opacity: 1;
        position: relative;
        top: 0;
        filter: blur(0);
        height: auto;

        &::before {
            content: '';
            width: 100%;
            height: $layout-margin;
            position: absolute;
            bottom: -0.8rem;
            z-index: 1;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            min-width: 5rem;
            background: linear-gradient(
                90deg,
                rgb($svns-black, 0) 0%,
                rgb($svns-black, 1) 100%
            );
        }

        #{$this}_list {
            border: none;
            width: 100%;
            flex-flow: row nowrap;
            padding-right: 5rem;
            max-width: 80rem;
            scroll-behavior: smooth;
            overscroll-behavior: contain;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            #{$this}_list--item {
                border: none;
                flex: 1 0 auto;
                padding: 0;

                #{$this}_list--item_link {
                    background-color: $svns-primary-black;
                    text-decoration: none;
                    display: block;
                    position: relative;
                    padding: 1.5rem;
                    text-transform: uppercase;
                    font-size: 1.4rem;
                    transition: background 0.3s, color 0.3s;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        left: 50%;
                        width: 0;
                        opacity: 0.1;
                        height: 5rem;
                        background: $svns-primary-black;
                        z-index: 1;
                        transition: width 0.3s, height 0.3s, opacity 0.3s;
                        transition-delay: 0.2s;
                    }
                }

                &.is-active a {
                    color: $svns-primary-black;
                    @include eventColors(background);

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    @media (max-width: $break-tablet) {
        &--small::before {
            height: $layout-margin-mob;
            bottom: -0.4rem;
        }

        &_list {
            &--logo {
                width: 14%;
                height: 100%;
                stroke: none;
                transition: fill 0.5s;
                @include eventColors(fill);
            }
        }
    }

    @media (min-width: $break-tablet) {
        // height: calc(100% - 8.1rem);
        // top: 8.1rem;

        &--small {
            height: auto;
            top: 0;

            &::after {
                display: none;
            }

            #{$this}_list {
                max-width: none;
                padding: 0;
            }

            #{$this}_list--item_link {
                &:hover {
                    color: $svns-primary-black;
                    @include eventColors(background);

                    &::after {
                        width: 100%;
                        // height: 15rem;
                    }
                }
            }
        }

        &-btn {
            min-width: 40rem;
            min-height: 8rem;
            padding: 2rem;

            &.isEvent {
                #{$this}_list--logo {
                    width: 3rem;
                    height: 3rem;
                    min-width: 3rem;
                }

                #{$this}--abbreviation {
                    margin-left: 1rem;

                    &--code {
                        width: 9rem;
                        height: 3rem;
                    }
                }

                #{$this}--infos {
                    display: flex;
                    flex-direction: column;
                    color: $svns-primary-black;
                    margin-left: 2rem;
                    font-size: 1.4rem;
                    line-height: 1.2;
                    text-transform: uppercase;
                }
            }
        }

        &_list {
            flex-flow: wrap;
            border-bottom: 0.1rem solid $svns-white;
            display: flex;
            width: 100%;

            &--event-container {
                margin-left: 2rem;
                width: calc(100% - 12rem);
                font-size: 2rem;
                padding: 0;
                justify-content: space-between;
            }

            &--abbreviation {
                font-size: 5rem;
            }

            &--logo {
                width: 20%;
                height: 100%;
                min-width: 15rem;
                fill: $svns-white;
            }

            &--item {
                flex: 0 0 50%;

                &:nth-child(odd) {
                    border-right: 0.1rem solid $svns-white;
                }

                &:hover {
                    #{$this}_list--link {
                        color: $svns-primary-black;
                    }
                    #{$this}_list--logo {
                        fill: $svns-primary-black;
                    }
                    @include eventColors(background);
                }
            }

            // &.col-8 {
            //     #{$this}_list--link {
            //         height: calc(25vh - 2.1rem);
            //         padding: 2rem;
            //     }
            //     #{$this}_list--item {
            //         // stylelint-disable-next-line declaration-last-child

            //         &:nth-last-child(-n + 1) {
            //             border-bottom: none;
            //         }
            //     }
            // }

            // &.col-9 {
            //     #{$this}_list--logo {
            //         width: 15%;
            //     }
            //     #{$this}_list--link {
            //         height: calc(20.5vh - 2.1rem);
            //         padding: 2rem;
            //     }
            //     #{$this}_list--item:last-child {
            //         border-right: 0.1rem solid $svns-white;
            //     }
            //     #{$this}_list--item:nth-last-child(-n + 1) {
            //         border-bottom: none;
            //     }
            // }

            // &.col-10 {
            //     #{$this}_list--logo {
            //         width: 15%;
            //     }
            //     #{$this}_list--link {
            //         height: calc(20.5vh - 2.1rem);
            //         padding: 2rem;
            //     }
            //     #{$this}_list--item:nth-last-child(-n + 2) {
            //         border-bottom: none;
            //     }
            // }

            // &.col-11 {
            //     #{$this}_list--logo {
            //         width: 12%;
            //     }
            //     #{$this}_list--link {
            //         height: calc(17.4vh - 2.1rem);
            //         padding: 2rem;
            //     }
            //     #{$this}_list--item:last-child {
            //         border-right: 0.1rem solid $svns-white;
            //     }
            //     #{$this}_list--item:nth-last-child(-n + 1) {
            //         border-bottom: none;
            //     }
            // }

            // &.col-12 {
            //     #{$this}_list--logo {
            //         width: 12%;
            //     }
            //     #{$this}_list--link {
            //         height: calc(17.4vh - 2.1rem);
            //         padding: 2rem;
            //     }
            //     #{$this}_list--item:nth-last-child(-n + 2) {
            //         border-bottom: none;
            //     }
            // }
        }
    }
}
